/* Color Plate */
.vs-color-plate {
  position: fixed;
  display: block;
  z-index: 91;
  padding: 20px;
  width: 240px;
  background: #fff;
  left: 0;
  text-align: left;
  top: 30%;
  transition: all 0.4s ease;
  box-shadow: -3px 0px 10px -2px rgba(0, 0, 0, 0.10);
  direction: ltr;
  transform: translateX(-100%);
  
  
  &.open {
    transform: translateX(0);
  }


  .color-plate-icon {
    position: absolute;
    right: -48px;
    width: 48px;
    height: 45px;
    line-height: 45px;
    font-size: 21px;
    border-radius: 0 5px 5px 0;
    text-align: center;
    color: $white-color;
    top: 0;
    cursor: pointer;
    border: none;
    padding: 0;
    box-shadow: -4px 0px 5px #00000036;
    background-color: $theme-color;
  }

  h4 {
    display: block;
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 500;
  }

  p {
    font-size: 12px;
    margin-bottom: 15px;
    line-height: 20px;
  }

  .reset-btn {
    border: none;
    padding: 0;
    height: 20px;
    font-size: 14px;
    text-transform: capitalize;
    color: $title-color;
    background-color: $smoke-color;
    width: 100%;
    height: 40px;

    &:hover {
      background-color: $title-color;
      color: $white-color;
    }
  }

  input[type="color"] {
    border: none;
    padding: 0;
  }

}



.default-color-btn {
  border: none;
  background-color: #000;
  color: $white-color;
  font-weight: 400;
  font-family: $body-font;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 0 0 10px 0;
  padding: 5px 0;
}
