:root {
  --theme-color: #7409f6;
  --sec-color: #000000;
  --title-color: #272e39;
  --body-color: #565656;
  --smoke-color: #f6f6f6;
  --secondary-color: #eef2fb;
  --border-color: #ececec;
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #e0e0e0;
  --title-font: "Inria Sans", sans-serif;
  --body-font: "Inria Sans", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1320px;
  --container-gutters: 30px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 80px;
  --ripple-ani-duration: 5s;
}

// Color Variation
$theme-color: var(--theme-color);
$title-color: var(--title-color);
$body-color: var(--body-color);
$secondary-color: var(--smoke-color);
$smoke-color: var(--smoke-color);
$white-color: var(--white-color);
$black-color: var(--black-color);
$yellow-color: var(--yellow-color);
$success-color: var(--success-color);
$error-color: var(--error-color);
$border-color: var(--border-color);

// Font Variation
$icon-font: var(--icon-font);

// Typography
$title-font: var(--title-font);
$body-font: var(--body-font);
$body-font-size: 16px;
$body-line-Height: 26px;
$body-font-weight: 400;
$p-line-Height: 1.63;

// Device Variation
$hd: 1921px; // Large Device Than 1920
$xl: 1500px; // Extra large Device
$ml: 1399px; // Medium Large Device
$lg: 1199px; // Large Device (Laptop)
$md: 991px; // Medium Device (Tablet)
$sm: 767px; // Small Device
$xs: 575px; // Extra Small Device

// Spacing Count with 5x
$space-count: 6;

// Section Space  For large Device
$space: var(--section-space);
$space-extra: calc(var(--section-space) - 30px);

// Section Space On small Device
$space-mobile: var(--section-space-mobile);
$space-mobile-extra: calc(var(--section-space-mobile) - 30px);

// BG Color Mapping
$bgcolorMap: ();
$bgcolorMap: map-merge(
  (
    "theme": $theme-color,
    "smoke": $smoke-color,
    "white": $white-color,
    "black": $black-color,
    "title": $title-color,
  ),
  $bgcolorMap
);

// Overlay Color Mapping
$overlaycolorMap: ();
$overlaycolorMap: map-merge(
  (
    "theme": $theme-color,
    "title": $title-color,
    "white": $white-color,
    "black": $black-color,
  ),
  $overlaycolorMap
);

// Text Color Mapping
$textColorsMap: ();
$textColorsMap: map-merge(
  (
    "theme": $theme-color,
    "title": $title-color,
    "body": $body-color,
    "white": $white-color,
    "yellow": $yellow-color,
    "success": $success-color,
    "error": $error-color,
  ),
  $textColorsMap
);

// Font Mapping
$fontsMap: ();
$fontsMap: map-merge(
  (
    "icon": $icon-font,
    "title": $title-font,
    "body": $body-font,
  ),
  $fontsMap
);
