.feature {
  &-layout1 {
    background-color: var(--theme-color);
    position: relative;
    z-index: 1;
    padding: 120px 0 240px;
    @include respond-below(md) {
      padding: 80px 0 200px;
    }
    .img-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      height: 100%;
      width: 100%;
      object-fit: cover;
      mix-blend-mode: color-dodge;
    }
    .overlay {
      background-color: var(--black-color);
      opacity: 0.45;
      z-index: -1;
    }
  }
  &-style1,
  &-style2 {
    background-color: var(--white-color);
    padding: 40px 30px 33px;
    border-radius: 10px;
    text-align: center;
    .feature {
      &-text {
        margin-bottom: 0;
      }
      &-icon {
        display: inline-flex;
        min-width: 80px;
        min-height: 80px;
        align-items: center;
        justify-content: center;
        margin-bottom: 22px;
        position: relative;
        z-index: 1;
        border: 2px solid var(--theme-color);
        border-radius: 50%;
        img {
          filter: none;
          @include smooth-transition;
        }
        &::before,
        &::after {
          position: absolute;
          content: "";
          z-index: -1;
        }
        &::before {
          display: none;
        }
        &::after {
          width: 80px;
          height: 80px;
          left: 50%;
          top: 50%;
          background-color: var(--theme-color);
          border-radius: 50%;
          opacity: 0;
          visibility: hidden;
          transform: translate(-50%, -50%) scale(0);
          @include smooth-transition;
        }
      }
    }
    &:hover {
      .feature {
        &-icon {
          img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(48deg)
              brightness(180%) contrast(100%);
            @include smooth-transition;
          }
          &::before {
            opacity: 0;
            visibility: hidden;
            transform: scale(1.5);
            @include smooth-transition;
            background-color: var(--theme-color);
          }
          &::after {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }
  }
  &-style2 {
    border: 2px solid var(--sec-color);
    position: relative;
    @include smooth-transition;
    overflow: hidden;
    z-index: 1;
    border-radius: 20px;
    .feature-icon {
      background-color: var(--theme-color);
      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(48deg)
          brightness(180%) contrast(100%);
      }
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--theme-color);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      @include smooth-transition;
    }
    .feature-title,
    .feature-text {
      @include smooth-transition;
    }
    &:hover {
      border-color: var(--theme-color);
      background-color: var(--theme-color);
      &::before {
        opacity: 1;
        visibility: visible;
      }
      .feature-icon {
        &::after {
          transform: translate(-50%, -50%) scale(1);
          transform-origin: center;
          background-color: var(--white-color);
        }
        img {
          filter: none;
        }
      }
      .feature-title,
      .feature-text {
        color: var(--white-color);
      }
    }
  }
}
