.widget {
  padding: var(--widget-padding-y, 30px) var(--widget-padding-x, 30px);
  background-color: transparent;
  position: relative;
  background-color: var(--smoke-color);
  margin-bottom: 30px;
  border-radius: 20px;

  select,
  input {
    height: 55px;
    border: none;
    background-color: #fff;
    padding-left: 20px;
    font-weight: 400;
    color: var(--white-color);
    &::placeholder {
      color: var(--white-color);
      font-size: 16px;
    }
  }
  .wp-block {
    &--submit {
      &__input {
        margin-bottom: 20px;
        background-color: transparent;
        border: 1px solid var(--white-color);
        color: var(--white-color);
        font-size: 16px;
        padding: 0 20px;
        @include smooth-transition;
        &::placeholder {
          color: var(--white-color);
          font-size: 14px;
        }
        &:focus::placeholder {
          opacity: 0;
          visibility: hidden;
          @include smooth-transition;
        }
      }
    }
  }
}
.widget {
  .search-form {
    position: relative;
    display: flex;
    background-color: var(--white-color);
    align-items: center;
    padding: 5px;
    border-radius: 30px;
    overflow: hidden;

    input {
      flex: 1;
      color: var(--title-color);
      height: 50px;
      &::placeholder {
        color: var(--title-color);
      }
    }

    button {
      border: none;
      background-color: $theme-color;
      color: $white-color;
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      font-size: 17px;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: $title-color;
        color: $white-color;
      }
    }
  }
}
.wp-block-tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  align-items: center;
  &__title {
    font-weight: 700;
  }
}
.wp-block-tag-cloud,
.tagcloud {
  a {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    margin-right: 0;
    margin-bottom: 0;
    color: var(--black-color);
    text-transform: capitalize;
    border-radius: 0px;
    font-family: var(--title-font);

    &:hover {
      background-color: transparent;
      color: var(--theme-color);
    }
  }
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;

  a {
    background-color: $white-color;
    color: $title-color;
  }
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }

  .media-img {
    margin-right: 15px;
    width: 100px;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
      border-radius: 10px;
      transition: all ease 0.4s;
    }
  }

  .post-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 8px 0;
    & > a {
      display: block;
    }
  }

  .recent-post-meta {
    a {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      color: var(--theme-color);
      font-family: var(--title-font);

      i {
        margin-right: 5px;
        color: $theme-color;
      }
    }
  }

  &:hover {
    .media-img {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;

  span[class*="wp-calendar-nav"] {
    position: absolute;
    top: 8px;
    left: 20px;
    font-size: 14px;
    color: $title-color;
    font-weight: 500;
    z-index: 1;

    a {
      color: inherit;
    }
  }

  span.wp-calendar-nav-next {
    left: auto;
    right: 20px;
  }

  caption {
    caption-side: top;
    text-align: center;
    color: $title-color;
    background-color: #f1dbbe;
  }

  th {
    font-size: 14px;
    padding: 5px 5px;
    border: none;
    text-align: center;
    border-right: 1px solid #fff;
    color: #01133c;
    font-weight: 500;

    a {
      color: inherit;
    }
  }

  td {
    font-size: 14px;
    padding: 5px;
    color: #01133c;
    border: 1px solid #ededed;
    text-align: center;
    background-color: transparent;
    transition: all ease 0.4s;
  }

  #today {
    color: $theme-color;
    background-color: $white-color;
    border-color: #ededed;
  }

  thead {
    background-color: #f6f6f6;
  }

  .wp-calendar-table {
    margin-bottom: 0;
  }

  .wp-calendar-nav {
    .pad {
      display: none;
    }
  }
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  .gallery-thumb {
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      transform: scale(1);
      transition: all ease 0.4s;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.widget_shopping_cart {
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mini_cart_item {
    position: relative;
    border-bottom: 1px solid $border-color;
    padding: 0 0 32px 110px;
    margin: 0 0 27px 0;
    min-height: 90px;
  }

  .remove {
    position: absolute;
    right: 0;
    top: 0;
    color: $title-color;
    line-height: 1;
    font-size: 18px;

    &:hover {
      color: $theme-color;
    }
  }

  .img {
    position: absolute;
    left: 0;
    top: 3px;
    width: 90px;
    height: 90px;
    display: inline-block;
    border: 1px solid $border-color;
  }

  .product-title {
    font-size: 14px;
    color: $title-color;
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
  }

  .amount {
    display: block;
    font-weight: 600;
    color: $title-color;
    font-size: 16px;
  }

  .quantity {
    display: inline-flex;
    margin-top: 12px;
  }

  .qut-btn {
    border: 1px solid $border-color;
    background-color: transparent;
    display: inline-block;
    background-color: transparent;
    width: 25px;
    height: 25px;
    padding: 0;
    font-size: 12px;
    z-index: 1;
    position: relative;

    &:hover {
      background-color: $title-color;
      border-color: transparent;
      color: $white-color;
    }
  }

  .qty-input {
    border: 1px solid $border-color;
    text-align: center;
    width: max-content;
    min-width: 40px;
    font-size: 12px;
    padding: 0;
    height: 25px;
    margin: 0 -1px;
  }

  .subtotal {
    float: right;
    text-align: right;
    font-size: 12px;
    margin-top: 19px;

    > span:not(.amount) {
      color: $body-color;
      font-weight: 300;
    }

    .amount {
      font-size: 12px;
      display: inline-block;
    }
  }

  .total {
    color: $title-color;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 25px;
    font-size: 18px;

    .amount {
      font-size: inherit;
      display: inline-block;
      color: $theme-color;
    }
  }

  .vs-btn {
    border: 1px solid $title-color;
    color: $title-color;
    margin-right: 10px;
    text-transform: capitalize;
    height: 50px;
    line-height: 47px;
    padding: 0 35px;
    background-color: $title-color;
    color: $white-color;
    font-weight: 400;

    &:hover {
      background-color: transparent;
      color: $title-color;
    }
  }

  .buttons {
    margin: 0;
  }
}

.sidebar-area {
  margin-bottom: -10px;
  position: sticky;
  top: 30px;
  margin-bottom: 30px;
  @include respond-below(md) {
    margin-bottom: 0;
  }

  ul.wp-block-latest-posts {
    margin-bottom: 0;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .newsletter-form {
    .newsletter-text {
      max-width: 70%;
      margin: -0.42em 0 15px 0;
    }
    .form-control {
      color: var(--body-color);
      border-radius: 50px;
      height: 60px;
      padding: 0 30px 0 30px;
      &::placeholder {
        color: var(--body-color);
      }
    }
    .vs-btn {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      height: 60px;
      margin-top: 20px;
    }
  }

  .widget .wp-block-search {
    margin-bottom: 0;
  }

  .wp-block-group__inner-container h2 {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 20px;
    margin-top: -0.07em;
  }

  ol.wp-block-latest-comments {
    padding: 0;
    margin: 0;

    li {
      line-height: 1.5;
      margin: 0 0 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  .widget_title {
    font-size: 24px;
    margin-bottom: 30px;
    img {
      display: block;
      padding-top: 10px;
    }
  }
  .wp-block-details {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    & > li {
      padding-bottom: 15px;
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 30px;
      }
    }
    .item-card {
      padding: 0;
      gap: 15px;
      .item-icon {
        font-size: 14px;
        min-width: 50px;
        height: 50px;
      }
      .item-title {
        font-size: 20px;
        color: var(--title-color);
      }
      .item-text {
        font-size: 14px;
        font-weight: 700;
        color: var(--body-color);
      }
    }
  }
}

.recent-product {
  display: flex;

  .product-title {
    font-size: 16px;
  }
}

.widget-workhours {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    background-color: $white-color;
    padding: 14.5px 20px;
    margin: 0 0 15px 0;
    font-family: $title-font;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      margin-right: 10px;
    }
  }
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#071a3e, 0.7);
  }

  &__title {
    color: $white-color;
    position: relative;
    z-index: 1;
    max-width: 200px;
    margin: -0.3em auto 25px auto;
  }
}

@include lg {
  .widget {
    --widget-padding-y: 25px;
    --widget-padding-x: 25px;
  }

  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .recent-post {
    .post-title {
      font-size: 14px;
      line-height: 22px;
    }

    .recent-post-meta a {
      font-size: 12px;
    }
  }

  .widget-workhours {
    li {
      padding: 14.5px 20px;
      font-size: 14px;
    }
  }
}

@include sm {
  .contact-widget {
    .icon {
      --icon-size: 30px;
      --icon-font-size: 12px;
      top: 5px;
    }

    .contact {
      padding: 0 0 0 45px;
      margin: 0 0 20px 0;
      border-bottom: none;
    }
  }

  .sidebar-gallery {
    gap: 10px;
  }
}

@include xs {
  .widget {
    padding: 30px 20px;
  }
}

.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.sidebar-gallery .gallery-thumb img {
  transition: all ease 0.4s;
  width: 100%;
  transform: scale(1);
}

.sidebar-gallery .gallery-thumb:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}

.sidebar-gallery .gallery-thumb .gal-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  width: var(--icon-size, 35px);
  height: var(--icon-size, 35px);
  margin: calc(var(--icon-size, 35px) / -2) 0 0
    calc(var(--icon-size, 35px) / -2);
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sidebar-gallery .gallery-thumb .gal-btn:hover {
  transform: scale(1);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.sidebar-gallery .gallery-thumb:hover:before {
  opacity: 0.8;
  visibility: visible;
}

.sidebar-gallery .gallery-thumb:hover .gal-btn {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.1s;
}

.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.12);
}

.widget {
  &--bg1 {
    background-color: var(--blackSand-color);
  }
}
.vs-widget-about {
  &__text {
    color: var(--white-color);
  }
}

.sidebar-info {
  display: flex;
  align-items: center;
  gap: 20px;
  .info-logo {
    min-width: 130px;
    height: 130px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--theme-color);
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 5px;
    @include respond-below(sm) {
      min-width: 50px;
      height: 50px;
    }
  }
  .sec-title {
    font-size: 24px;
    margin: -0.25em 0 8px 0;
  }
  .info-list {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 2px;
      li {
        display: flex;
        align-items: center;
        i {
          color: var(--theme-color);
          min-width: 25px;
        }
        a {
          color: var(--body-color);
          font-size: 14px;
        }
      }
    }
  }
}

.sidebar-brand {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .brand-logo {
    background-color: var(--white-color);
    height: 170px;
    border: 2px solid var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 5px;
    @include respond-below(sm) {
      height: 130px;
    }
  }
}
