.test-wrap1 {
  position: relative;
  padding: 70px 0;
  overflow: hidden;
  padding-left: 50%;
  margin-right: -50%;
  @include respond-below(xxlg) {
    padding-left: 40%;
    margin-right: -40%;
  }
  @include respond-below(lg) {
    padding-left: 10%;
    margin-right: -10%;
  }
  @include respond-below(md) {
    padding: 45px 0;
    padding-left: 15px;
    margin-right: 0;
    padding-right: 15px;
    padding-bottom: 80px;
  }
  .testi-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    img {
      height: 100%;
      border-radius: 30px;
    }
  }
  .vs-carousel {
    padding: 30px 0px;
  }
  .vs-btn.slidebtn1 {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    transform: translateY(0);
    position: static;
    border-radius: 30px;
    background-color: var(--white-color);
    border: 1px solid var(--theme-color);
    color: var(--title-color);
    font-size: 24px;
    padding: 17px 29px;
    &::before,
    &::after {
      background-color: var(--theme-color);
    }
    &:hover {
      border: 1px solid var(--theme-color);
      background-color: var(--theme-color);
      color: var(--white-color);
    }
  }
  .testi-slide-btns {
    position: absolute;
    bottom: 0;
    right: auto;
    left: 46%;
    display: flex;
    align-items: center;
    gap: 20px;
    @include respond-below(xlg) {
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    @include respond-below(md) {
      bottom: 20px;
    }
  }
}
.testi-style1,
.testi-style3 {
  background-color: var(--white-color);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.09);
  margin-bottom: 0px;
  position: relative;
  @include respond-below(md) {
    text-align: center;
  }
  @include respond-below(sm) {
    padding: 15px;
  }

  .testi-icon {
    position: absolute;
    right: 40px;
    top: 25.46px;
    @include respond-below(sm) {
      display: none;
    }
  }

  .testi-author {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    @include respond-below(md) {
      display: inline-flex;
      justify-content: center;
    }
  }

  .author-img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  .testi-name {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .testi-degi {
    color: $theme-color;
    font-size: 14px;
  }

  .testi-rating {
    font-weight: 600;
    font-family: var(--title-font);
    color: var(--title-color);
    margin-bottom: 18px;

    i {
      color: #feb519;
      margin-left: 5px;
      font-size: 14px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
  .testi-text {
    margin-bottom: 0;
  }
}
.testi-style1 {
  box-shadow: none;
}
.testi-style3 {
  box-shadow: none;
  margin-bottom: 30px;
}
.testi-author3 {
  display: flex;
  align-items: center;
  text-align: left;
  background: var(--white-color);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  padding: 30px 35px 30px 30px;
  position: relative;
  border: 1px solid var(--border-color);
  @include smooth-transition;
  @include respond-below(lg) {
    padding: 15px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 60px;
    background-color: var(--border-color);
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: 15px;
    @include smooth-transition;
  }
  .author-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  .testi-name {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .testi-degi {
    color: $theme-color;
    font-size: 14px;
  }
}

.testi-slider-tab {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
  @include respond-below(lg) {
    flex-wrap: wrap;
  }
  @include respond-below(md) {
    flex-wrap: nowrap;
  }
  @include respond-below(sm) {
    flex-wrap: wrap;
  }
  .slick-slide.slick-current.slick-active {
    .testi-author3 {
      border: 1px solid var(--theme-color);
      &::before {
        background-color: var(--theme-color);
        @include smooth-transition;
      }
    }
  }
}
.testi-thumb3 {
  position: absolute;
  width: 47%;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
  top: 0;
  bottom: 0;
  @include respond-below(md) {
    position: relative;
    width: 100%;
    height: 500px;
    border-radius: 0;
    top: 0;
    bottom: 0;
    margin-top: -80px;
    margin-bottom: 50px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #272e39 0%, rgba(39, 46, 57, 0.35) 100%);
    opacity: 0.6;
    border-radius: 0px 0px 0px 0px;
  }
}

.testi-slider-btn3 {
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  i {
    margin: 0;
  }
}

.test-wrap2 {
  padding: 0 15px;
}
