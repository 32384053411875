.brand-slide-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  button {
    position: relative;
    border: none;
    text-transform: uppercase;
    background-color: $theme-color;
    color: $white-color;
    width: 50%;
    padding: 12px 20px;

    .fa-long-arrow-left {
      margin-right: 10px;
    }

    .fa-long-arrow-right {
      margin-left: 10px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      height: 20px;
      width: 1px;
      background-color: rgba(#ffffff, 0.5);
      margin-top: -10px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:hover {
      background-color: $title-color;
      color: $white-color;
    }
  }
}

.vs-brand1 {
  img {
    transition: all ease 0.4s;
    filter: grayscale(100%) invert(0.8);
  }

  img:hover,
  &.slick-current img {
    filter: grayscale(0) invert(0);
  }
}

.brand-wrap1 {
  border-radius: 20px;
  padding: 80px 105px;
  background-color: $white-color;
  text-align: center;
  background-size: auto;
  background-position: center center;
}

.brand-wrap2,
.brand-wrap3 {
  background-color: var(--smoke-color);
  text-align: center;
  padding: 80px 100px;
  border-radius: 20px;
  margin-top: -110px;
  @include respond-below(md) {
    padding: 50px;
  }
}

.brand-wrap3 {
  z-index: 1;
  .vs-btn.slidebtn1 {
    z-index: 2;
  }
}

@include sm {
  .brand-wrap1 {
    padding: 60px 25px;
  }
}

.form-style4.form-newsletter {
  position: relative;
  margin-bottom: 30px;
  .vs-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.inner-layout {
  .overlay {
    background: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(15px);
    z-index: -1;
  }
}
