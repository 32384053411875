.widget_nav_menu,
.widget_meta,
.widget_pages,
.widget_archive,
.widget_categories {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  > ul {
    margin: 0 0 -38px 0;
  }

  a {
    display: block;
    background-color: transparent;
    color: $body-color;
    margin: 0 0 18px 0;
    padding: 0 0 17px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    border-bottom: 1px solid #dadfec;

    &:hover {
      color: $theme-color;
    }
  }

  li {
    display: block;
    position: relative;

    &:last-child {
      a {
        border-bottom: none;
      }
    }

    > span {
      text-align: center;
      position: absolute;
      right: 0;
      top: -4.5px;
      font-size: 16px;
      transition: all ease 0.4s;
    }

    &:hover {
      > span {
        color: $theme-color;
      }
    }
  }

  .children {
    margin-left: 10px;
  }
}

.widget_nav_menu,
.widget_meta,
.widget_pages {
  a {
    padding-right: 20px;
  }
}

.widget_nav_menu {
  .sub-menu {
    margin-left: 10px;
  }
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  a:not(:hover) {
    color: inherit;
  }
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

@include sm {
  .widget_nav_menu,
  .widget_meta,
  .widget_pages,
  .widget_archive,
  .widget_categories {
    a {
      font-size: 14px;
    }
  }
}

.wp-block-categories {
  padding-left: 0;
  li {
    a {
      background-color: var(--white-color);
      padding: 5px 5px 5px 30px;
      margin: 0 0 10px 0;
      border-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 30px;
      span {
        min-width: 50px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--theme-color);
        color: var(--white-color);
        border-radius: 50%;
        position: relative;
        @include smooth-transition;
        &::before {
          content: "\f061";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: var(--icon-font);
          opacity: 0;
          visibility: hidden;
          @include smooth-transition;
        }
      }
      &:hover {
        span {
          color: var(--theme-color);
          &::before {
            opacity: 1;
            visibility: visible;
            @include smooth-transition;
            color: var(--white-color);
          }
        }
      }
    }
  }
}
