.img-box1 {
  position: relative;
  .card-style1,
  .card-style2 {
    position: absolute;
    right: 0;
  }
  .img-1 {
    img {
      border-radius: 120px 20px 20px 20px;
    }
  }
  .img-2 {
    z-index: 1;
    margin: -360px 0 0 auto;
    max-width: 100%;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    border-radius: 20px 20px 120px 20px;
    overflow: hidden;
    padding: 20px 0 0 21px;
    background: var(--white-color);
    img {
      border-radius: 20px 20px 120px 20px;
    }
  }
}

.img-3 {
  img {
    border-radius: 20px;
  }
}

.card-style1,
.card-style2 {
  max-width: 195px;
  background: var(--theme-color);
  border-radius: 20px;
  text-align: center;
  padding: 30px 20px;
  @include respond-below(sm) {
    max-width: 150px;
    padding: 10px 10px;
  }
  .number {
    width: 120px;
    height: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
    border-radius: 50%;
    font-size: 64px;
    color: var(--white-color);
    font-weight: 700;
    margin-bottom: 10px;
    span {
      display: inline-block;
      line-height: 1;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
    color: var(--white-color);
  }
}

.card-style1 {
  .number {
    color: var(--title-color);
  }
}

.card-style2 {
  position: absolute;
  border-radius: 50%;
  min-width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  gap: 5px;
  padding: 24px;
  @include respond-below(md) {
    min-width: 150px;
    height: 150px;
    max-width: 150px;
  }
  .number {
    width: auto;
    height: auto;
    background-color: transparent;
    margin-bottom: 0;
    font-size: 48px;
    @include respond-below(md) {
      font-size: 36px;
    }
  }
  .title {
    line-height: 30px;
    font-size: 20px;
    @include respond-below(md) {
      font-size: 16px;
      line-height: 23px;
    }
  }
}

.exp-media {
  display: flex;
  margin: 0 0 15px 0;

  &__total {
    display: inline-block;
    text-align: center;
    background-color: $white-color;
    color: $theme-color;
    font-family: $title-font;
    font-weight: 700;
    font-size: 36px;
    width: 80px;
    height: 100px;
    line-height: 100px;
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(91, 118, 175, 0.15);
    margin: 0 20px 0 0;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    color: $theme-color;
    margin: -0.1em 0 10px 0;
  }
}

.list-style1,
.list-style3 {
  margin-bottom: 37px;
  ul {
    padding: 25px 0 0 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;
  }

  li {
    font-size: 16px;
    position: relative;
    color: $title-color;
    color: $black-color;
    margin: 0 0 12px 0;
    padding: 0 0 0 33px;
    i {
      font-weight: 600;
    }
    &:last-child {
      margin-bottom: 0;
    }

    & > i:first-child {
      position: absolute;
      left: 0;
      color: $theme-color;
      top: 6px;
    }
  }
}

.quote-media {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  border-top: 1px solid #e9ebf3;
  margin-top: 40px;
  padding-top: 40px;

  .media-icon {
    margin-right: 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 20px;
      width: 67px;
      height: 67px;
      background-color: $theme-color;
      opacity: 0.05;
      border-radius: 50%;
      z-index: -1;
    }
  }

  .media-title {
    font-weight: 400;
    font-size: 18px;
  }
}

.award-box {
  background-color: #f4f7fc;
  position: relative;
  padding: 60px 60px 57px 60px;
  display: flex;
  border-radius: 5px;

  > * {
    position: relative;
    z-index: 2;
  }

  &__shape {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    background-color: $theme-color;
    box-shadow: 0px 4px 20px rgba(14, 89, 242, 0.33);
    border-radius: 5px;
    z-index: 1;
  }

  &__icon {
    position: relative;
    color: $white-color;
    font-size: 42px;
    line-height: 1;
    margin: 0 20px 0 0;
  }

  &__number {
    line-height: 1;
    margin: 0 0 8px 0;
    font-size: 40px;
    font-weight: 700;
    font-family: $title-font;
    color: $white-color;
  }

  &__text {
    font-weight: 700;
    font-size: 16px;
    font-family: $title-font;
    text-transform: uppercase;
    margin: 0;
    color: $white-color;
    line-height: 1;
  }
}

.about-media {
  display: flex;

  &__icon {
    margin: 0 20px 0 0;
  }
}

.call-media {
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 10px;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  width: fit-content;
  max-width: 100%;
  margin: 0 0 40px 0;
  background-color: $white-color;

  &__icon {
    background-color: $secondary-color;
    box-shadow: 0px 7px 37px rgba(96, 125, 185, 0.11);
    border-radius: 5px;
    width: 95px;
    height: 95px;
    line-height: 95px;
    border-radius: 5px;
    text-align: center;
    margin: 0 20px 0 0;
  }

  &__label {
    text-transform: uppercase;
    font-family: $title-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    display: block;
    margin: 0 0 12px 0;
  }

  &__info {
    color: $title-color;
    font-family: $title-font;
    font-weight: 700;
    line-height: 1;
    display: block;
    margin: 0;

    a {
      color: inherit;

      &:hover {
        color: $theme-color;
      }
    }
  }
}

.media-order {
  display: flex;
  margin: 0 0 5px 0;

  &__number {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 700;
    font-family: $title-font;
    background-color: $theme-color;
    color: $white-color;
    text-align: center;
    display: inline-block;
    margin: 0 30px 0 0;
    border-radius: 5px;
  }

  &__title {
    margin: -0.25em 0 10px 0;
  }
}

.progress-box {
  margin: 0 0 25px 0;

  &__number,
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    font-family: $title-font;
    color: $title-color;
    display: inline-block;
    margin: 0 0 17px 0;
  }

  &__number {
    float: right;
    margin: 5px 0 0 0;
  }

  &__progress {
    box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
    border: 1px solid #d5e0f5;
    height: 16px;
    padding: 4px;
    border-radius: 5px;
    background-color: $white-color;
  }

  &__bar {
    height: 100%;
    background-color: $theme-color;
    border-radius: inherit;
    box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
    border-radius: 5px;
  }
}

.about-wrap1 {
  border-top: 1px solid #e8ebf6;
  position: relative;
}

.about-shape1 {
  font-size: 150px;
  font-weight: 400;
  font-family: $title-font;
  line-height: 1;
  display: inline-block;
  writing-mode: tb-rl;
  text-transform: uppercase;
  transform: rotate(180deg);
  position: absolute;
  right: 95px;
  bottom: 120px;
  color: rgba(14, 89, 242, 0.07);
}

.contact-media {
  display: flex;
  align-items: center;

  &__icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: $theme-color;
    color: $white-color;
    display: inline-block;
    text-align: center;
    font-size: 26px;
    border-radius: 7px;
    position: relative;
    z-index: 1;
  }

  &__label {
    text-transform: uppercase;
    font-family: $title-font;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    display: block;
    margin: 0 0 9px 0;
  }

  &__info {
    color: $title-color;
    font-family: $title-font;
    font-weight: 700;
    display: block;
    margin: 0 0 -0.25em 0;
    font-size: 15px;
    line-height: 24px;

    a {
      color: inherit;
    }
  }

  .media-body {
    background-color: $white-color;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 10px 50px;
    margin-left: -30px;
    border-radius: 5px;
  }
}

.about-call {
  display: flex;
  align-items: center;
  border-right: 2px solid $theme-color;
  padding: 0 40px 0 0;
  margin: 0 40px 0 0;

  &__icon {
    background-color: $white-color;
    color: $theme-color;
    text-align: center;
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin-right: 20px;
    font-size: 20px;
    border-radius: 50%;
  }

  &__label {
    font-size: 14px;
    color: $black-color;
    display: block;
  }

  &__number {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    font-family: $title-font;
    color: $theme-color;
  }
}

.about-box1 {
  .about-subtitle {
    font-weight: 500;
    line-height: 1;
    display: block;
    font-family: $title-font;
    color: $theme-color;
    display: flex;
    align-items: center;
    max-width: 280px;
    margin: 0 0 33px 0;

    &:after {
      content: "";
      position: relative;
      right: 0;
      height: 1px;
      width: 100%;
      flex: 1;
      background-color: $theme-color;
      display: inline-block;
      margin: 0 0 0 10px;
    }
  }

  .about-text {
    margin: 0 0 33px 0;
  }

  .about-title {
    margin-top: -0.3em;
  }
}

.skill-about {
  margin-bottom: 22px;

  .skill-percentage {
    font-size: 60px;
    font-weight: 700;
    color: $title-color;
    line-height: 1;
    margin-bottom: 6px;
  }

  .skill-title {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 27px;
  }

  .progress {
    border-radius: 0;
    height: 10px;
    background-color: #363940;
    margin-bottom: 20px;

    .progress-bar {
      background-color: $theme-color;
    }
  }
  .skill-text {
    margin-bottom: 0;
  }
}

.img-box3 {
  position: relative;
  display: flex;
  gap: 30px;
  .img1,
  .img2 {
    img {
      border-radius: 154px;
    }
  }
  .img2 {
    margin-top: 153px;
  }
}

@include xl {
  .about-shape1 {
    font-size: 80px;
    right: 50px;
    bottom: 70px;
  }

  .img-box2 {
    .img-1 {
      margin-left: 0;
    }

    .img-2 {
      position: relative;
      width: fit-content;
      margin: -100px 30px 0 auto;
      padding: 10px;
      border-radius: 10px;
    }
  }

  .img-box9 {
    margin-right: 0;
  }

  .about-box2 {
    max-width: 500px;
  }
}

@include ml {
  .img-box8 {
    .play-btn {
      left: 0;
      top: 0;
      position: absolute;

      &:after,
      &:before {
        display: none;
      }
    }

    .img-1 {
      img {
        width: 100%;
      }
    }
  }

  .about-shape3 {
    max-width: 29%;
  }

  .play-btn.style5 {
    i {
      --icon-size: 70px;
      --icon-font-size: 22px;
    }
  }

  .about-box3 {
    max-width: 100%;
    padding-left: 0;
  }

  .list-style4 {
    ul {
      padding: 30px 40px 36px 32px;

      li {
        font-size: 16px;
      }
    }
  }
  .about-shape2 {
    max-width: 100%;
    max-height: 100%;
  }

  .about-wrap2 {
    padding-bottom: $space;
  }
}

@include lg {
  .img-box3 {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }

  .img-box9 .img-2:before {
    display: none;
  }

  .img-box4 {
    .img-1 {
      margin: 0;
    }

    .img-2 {
      margin: -100px 0 0 auto;
      max-width: 70%;
    }
  }

  .about-box3 {
    .sec-title4 {
      margin-bottom: 30px;
    }
  }

  .list-style2 li {
    padding: 14px 20px;
    font-size: 14px;
  }

  .list-style4 {
    ul {
      background-color: transparent;
      padding: 0;
    }

    li {
      color: $title-color;
    }
  }
}

@include md {
  .about-box2 {
    padding: 40px 0 var(--section-space-mobile, 80px) 0;
  }

  .about-wrap2 {
    padding-bottom: $space-mobile;
  }

  .img-box1 {
    .img-1 {
      img {
        width: 100%;
      }
    }
  }

  .img-box5 {
    width: 100%;

    .img-1 {
      img {
        width: 100%;
      }
    }

    .img-bottom {
      justify-content: center;
    }
  }

  .skill-about {
    .skill-percentage {
      font-size: 48px;
    }
  }
}

@include sm {
  .img-box10 {
    padding-right: 0;
  }

  .img-box2,
  .img-box1 {
    .img-2 {
      margin: 20px 0 0 0;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .img-box5 {
    padding: 0;

    .shape1 {
      display: none;
    }
  }

  .img-box9 {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;

    img {
      width: 100%;
    }

    .img-1 {
      display: block;
      margin: 0 0 30px 0;
    }

    .img-3 {
      display: block;
      position: relative;
      left: 0;
      bottom: 0;
      margin: 30px 0 0 0;
    }
  }

  .list-style2 {
    ul {
      display: block;

      li:not(:last-child) {
        margin: 0 0 20px 0;
      }
    }
  }

  .list-style5 ul {
    display: block;
    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .quote-media .media-icon {
    margin-right: 20px;
  }

  .media-order {
    display: block;

    &__number {
      margin: 0 0 20px 0;
    }
  }

  .img-box3 {
    img {
      width: 100%;
    }

    .img-2 {
      margin: 30px 0 0 0;
    }
  }

  .call-media {
    &__icon {
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin: 0 10px 0 0;

      img {
        max-width: 25px;
      }
    }

    &__label {
      font-size: 12px;
    }
  }

  .award-box {
    padding: 30px;

    &__shape {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .img-box3 {
    .award-box {
      position: relative;
      bottom: 0;
      right: 0;
      margin: 30px 0 0 0;
      width: 100%;
      box-shadow: none;
    }
  }

  .exp-media {
    margin-top: 30px;
  }

  // .exp-media {
  //   display: block;

  //   &__total {
  //     margin: 0 0 20px 0;

  //   }
  // }

  .list-style1 {
    ul {
      display: block;
      margin: 0 0 30px 0;
      padding: 30px 0 0 0;
    }
  }

  .list-style4 {
    ul {
      li {
        font-size: 14px;
        padding-left: 35px;
        margin-bottom: 15px;

        i:first-child {
          width: 25px;
          height: 25px;
          line-height: 26px;
          font-size: 12px;
          top: -1px;
        }
      }
    }
  }
}

@include xs {
  .about-call {
    padding: 0;
    border-right: none;
    margin-top: 20px;
  }
}

.img-box2 {
  position: relative;
  .img-1 {
    img {
      border-radius: 200px 20px 20px 20px;
    }
  }
  .img-2 {
    img {
      border-radius: 20px 150px 20px 20px;
    }
  }
  .img-3 {
    text-align: right;
    img {
      border-radius: 20px 20px 20px 150px;
    }
  }
  .img-4 {
    img {
      border-radius: 20px 20px 200px 20px;
    }
  }
  .img-1,
  .img-2,
  .img-3,
  .img-4 {
    margin-bottom: 30px;
  }
  .img-1,
  .img-3 {
    min-height: 305px;
  }
}

.list-style1 {
  &.style2 {
    ul {
      padding-top: 0;
      li {
        i {
          font-weight: 600;
        }
      }
    }
  }
}
