.video {
  &-wrap1,
  &-wrap2,
  &-wrap3 {
    padding: 120px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: -120px;
    @include respond-below(lg) {
      padding: 50px;
    }
    @include respond-below(md) {
      padding: 50px 20px;
      text-align: center;
    }
    .play-btn {
      margin-bottom: 72px;
      @include respond-below(md) {
        margin-bottom: 25px;
      }
    }
    .overlay {
      background: linear-gradient(
        90deg,
        #272e39 0%,
        rgba(39, 46, 57, 0.35) 100%
      );
      opacity: 0.6;
      z-index: -1;
    }
  }
  &-wrap2 {
    margin-top: 0;
    min-height: 740px;
    padding: 0;
    border-radius: 0;
    padding-top: 120px;
    overflow: visible;
    @include md {
      min-height: 640px;
      padding-top: 0;
    }
    .overlay {
      background: linear-gradient(
        90deg,
        #272e39 0%,
        rgba(39, 46, 57, 0.35) 100%
      );
      opacity: 0.6;
    }
    .video-content {
      padding-top: 110px;
    }
    .play-btn.style4 {
      --icon-size: 80px;
      margin-bottom: 60px;
      color: var(--white-color);
      margin-left: 40px;
      @include respond-below(md) {
        margin-left: 0;
      }
      &::before,
      &::after {
        background-color: var(--white-color);
        color: var(--title-color);
        animation-name: ripple2;
      }
      &::after {
        animation-duration: 3s;
      }
      &::before {
        animation-duration: 8s;
      }
      i {
        background-color: var(--theme-color);
        color: var(--white-color);
      }
    }
    .form-style1 {
      margin-bottom: -100px;
    }
  }
  &-wrap3 {
    padding: 190px 0 260px;
    border-radius: 0;
    margin-top: 0;
    @include respond-below(lg) {
      padding: 110px 0 200px;
    }
  }
}
