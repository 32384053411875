/*
Template Name: Eventino
Template URL: https://html.vecuro.com/Eventino
Description: Eventino - IT Solution & Service HTML Template
Author: vecuro
Author URI: https://themeforest.net/user/vecuro_themes
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.10. Feature
    4.11. About
    4.12. Service
    4.13. Counter
    4.14. Team
    4.15. Process
    4.16. Accordion
    4.17. Simple Sections
    4.18. Testimonial
    4.19. Popup Side Menu
    4.20. Price Plan
    4.21. Forms Style
    4.22. Projects
    4.23. Contact
    4.24. Event
    4.25. CTA
    4.26. Video
    4.27. Gallery
    4.28. Newsletter
05. Spacing
06. Color Plate

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
/*------------------- 1.2. Function -------------------*/
/*------------------- 1.3. Variable-------------------*/
:root {
  --theme-color: #7409f6;
  --sec-color: #000000;
  --title-color: #272e39;
  --body-color: #565656;
  --smoke-color: #f6f6f6;
  --secondary-color: #eef2fb;
  --border-color: #ececec;
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #e0e0e0;
  --title-font: "Inria Sans", sans-serif;
  --body-font: "Inria Sans", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1320px;
  --container-gutters: 30px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 80px;
  --ripple-ani-duration: 5s;
}

/*------------------- 1.5. Reset -------------------*/
html,
body {
  scroll-behavior: auto !important;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  /* Small devices */
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 24px;
  }
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--border-color);
}

th {
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
}

td,
th {
  border: 1px solid var(--border-color);
  padding: 9px 12px;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
}
a:hover {
  color: var(--title-color);
}
a:active, a:focus, a:hover, a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  margin: 0 0 20px 0;
  color: var(--body-color);
  line-height: 1.63;
  /* Small devices */
}
@media (max-width: 767px) {
  p {
    line-height: 1.73;
  }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 15px 0;
}

.h1,
h1 {
  font-size: 64px;
}

.h2,
h2 {
  font-size: 48px;
}

.h3,
h3 {
  font-size: 36px;
}

.h4,
h4 {
  font-size: 30px;
}

.h5,
h5 {
  font-size: 24px;
}

.h6,
h6 {
  font-size: 20px;
}

/* Large devices */
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 36px;
  }
  .h3,
  h3 {
    font-size: 30px;
  }
  .h4,
  h4 {
    font-size: 24px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 36px;
  }
  .h2,
  h2 {
    font-size: 30px;
  }
  .h3,
  h3 {
    font-size: 26px;
  }
  .h4,
  h4 {
    font-size: 22px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
/*------------------- 1.6. Responsive -------------------*/
/*-----------------
    3.2 Responsive Media Queries
-----------------*/
/**
@include respond-above(sm) {
  .element {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-below(lg) {
  .element {
    font-weight: bold;
  }
}
@media (max-width: 767px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-between(sm, md) {
  .element {
    font-weight: bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .element {
    font-weight: bold;
  }
}
**/
/*------------------- 1.7. Wordpress Default -------------------*/
p.has-drop-cap {
  margin-bottom: 20px;
}

.page--item p:last-child .alignright {
  clear: right;
}

.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}

.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}

.bypostauthor,
.gallery-caption {
  display: block;
}

.page-links,
.clearfix {
  clear: both;
}

.page--item {
  margin-bottom: 30px;
}
.page--item p {
  line-height: 1.8;
}

.content-none-search {
  margin-top: 30px;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}

.wp-block-columns {
  margin-bottom: 1em;
}

figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}

figure.wp-block-gallery {
  margin-bottom: 14px;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: 0.5em 0;
}

.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}

.wp-block-cover {
  margin-bottom: 15px;
}

.wp-caption-text {
  text-align: center;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}
.wp-caption .wp-caption-text {
  margin: 0.5em 0;
  font-size: 14px;
}

.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}

.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}

.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}

.wp-block-latest-comments a {
  color: inherit;
}

.wp-block-button {
  margin-bottom: 10px;
}
.wp-block-button:last-child {
  margin-bottom: 0;
}
.wp-block-button .wp-block-button__link {
  color: #fff;
}
.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}
.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

ol.wp-block-latest-comments li {
  margin: 15px 0;
}

ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
ul.wp-block-latest-posts a {
  color: inherit;
}
ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}
ul.wp-block-latest-posts li {
  margin: 15px 0;
}

.wp-block-search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}
.wp-block-search .wp-block-search__button {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

ul.wp-block-rss a {
  color: inherit;
}

.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}

.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}

.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.logged-in .will-sticky .sticky-active.active,
.logged-in .preloader .vs-btn {
  top: 32px;
}
@media (max-width: 782px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 46px;
  }
}
@media (max-width: 600px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 0;
  }
}

.post-password-form .theme-input-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 20px;
}
.post-password-form .theme-input-group .theme-input-style {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}
.post-password-form .theme-input-group .submit-btn {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}
.post-password-form .theme-input-group .submit-btn:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}
.page-links > .page-links-title {
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text),
.page-links > a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links > a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text).current,
.page-links > a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}
.page-links span.screen-reader-text {
  display: none;
}

.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}
.blog-single.format-quote, .blog-single.format-link, .blog-single.tag-sticky-2, .blog-single.sticky {
  border-color: transparent;
  position: relative;
}
.blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
  background-color: var(--smoke-color);
  padding: 40px;
  border: none;
}
.blog-single.format-quote .blog-content:before, .blog-single.format-link .blog-content:before, .blog-single.tag-sticky-2 .blog-content:before, .blog-single.sticky .blog-content:before {
  display: none;
}
.blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
  content: "\f0c1";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 5rem;
  opacity: 0.3;
  right: 15px;
  line-height: 1;
  top: 15px;
  color: var(--theme-color);
  z-index: 1;
}
.blog-single.tag-sticky-2::before, .blog-single.sticky::before {
  content: "Featured";
  right: 0;
  top: 0;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--theme-color);
  font-family: var(--title-font);
  opacity: 1;
  text-transform: capitalize;
  padding: 10px 23px;
  font-weight: 400;
}
.blog-single.format-quote:before {
  content: "\f10e";
}
.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}

.blog-details .blog-single:before {
  display: none;
}
.blog-details .blog-single .blog-content {
  background-color: transparent;
  overflow: hidden;
}
.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}
.blog-details .blog-single.format-chat .blog-content > p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}
.blog-details .blog-single.tag-sticky-2, .blog-details .blog-single.sticky, .blog-details .blog-single.format-quote, .blog-details .blog-single.format-link {
  box-shadow: none;
  border: none;
  background-color: transparent;
}
.blog-details .blog-single.tag-sticky-2:before, .blog-details .blog-single.sticky:before, .blog-details .blog-single.format-quote:before, .blog-details .blog-single.format-link:before {
  display: none;
}

.vs-search {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border: 1px solid #f3f3f3;
}
.vs-search .search-grid-content {
  padding: 30px;
  /* Small devices */
}
@media (max-width: 767px) {
  .vs-search .search-grid-content {
    padding: 20px;
  }
}
.vs-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: -0.2em;
}
.vs-search .search-grid-title a {
  color: inherit;
}
.vs-search .search-grid-title a:hover {
  color: var(--theme-color);
}
.vs-search .search-grid-meta > * {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}
.vs-search .search-grid-meta > *:last-child {
  margin-right: 0;
}
.vs-search .search-grid-meta a,
.vs-search .search-grid-meta span {
  color: var(--body-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    padding: 30px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    padding: 30px 15px;
  }
}
@media (max-width: 768px) {
  .wp-block-search .wp-block-search__input {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .wp-block-latest-comments {
    padding-left: 10px;
  }
  .page--content.clearfix + .vs-comment-form {
    margin-top: 24px;
  }
}
/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@media only screen and (min-width: 1300px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}
@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1399px) {
  .container-style2 {
    max-width: 1920px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}
.container-style3 {
  --main-container: 1445px;
}

@media (min-width: 1500px) and (max-width: 1921px) {
  .container-style1 {
    max-width: 1576px;
    overflow: hidden;
    margin-right: 0;
    padding-right: 0;
  }
}
/*------------------- 2.2. Grid -------------------*/
.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 767px) {
  .row:not([class*=gx-]) {
    --bs-gutter-x: 30px;
  }
}
.gy-gx {
  --bs-gutter-y: var(--bs-gutter-x);
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.gx-15 {
  --bs-gutter-x: 15px;
}

@media (min-width: 1199px) {
  .gx-45 {
    --bs-gutter-x: 45px;
  }
  .gx-60 {
    --bs-gutter-x: 60px;
  }
  .gx-50 {
    --bs-gutter-x: 50px;
  }
  .gx-70 {
    --bs-gutter-x: 70px;
  }
  .gx-80 {
    --bs-gutter-x: 80px;
  }
}
@media (min-width: 1399px) {
  .gx-10 {
    --bs-gutter-x: 10px;
  }
  .gx-20 {
    --bs-gutter-x: 20px;
  }
  .gx-30 {
    --bs-gutter-x: 30px;
  }
  .gx-40 {
    --bs-gutter-x: 40px;
  }
}
/*------------------- 2.3. Input -------------------*/
select,
.form-control,
.form-select,
textarea,
input {
  height: 50px;
  padding: 0 30px 0 28px;
  padding-right: 45px;
  border: 1px solid #e0e0e0;
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 9999px;
  border-radius: 0;
  font-size: 14px;
  width: 100%;
}
select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white-color);
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}
select.form-control-lg,
.form-control.form-control-lg,
.form-select.form-control-lg,
textarea.form-control-lg,
input.form-control-lg {
  height: 60px;
}
select.form-control-sm,
.form-control.form-control-sm,
.form-select.form-control-sm,
textarea.form-control-sm,
input.form-control-sm {
  height: 40px;
  font-size: 12px;
}
select[readonly],
.form-control[readonly],
.form-select[readonly],
textarea[readonly],
input[readonly] {
  color: var(--title-color);
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-radius: 0;
}

input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=checkbox]:checked ~ label:before {
  content: "\f00c";
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
input[type=checkbox] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
input[type=checkbox] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=radio] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
input[type=radio] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0.5px;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  transition: all 0.2s ease;
}
input[type=radio]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}
@media (max-width: 991px) {
  .form-group {
    width: 100%;
  }
}
.form-group > i {
  position: absolute;
  right: calc(var(--bs-gutter-x) / 2 + 30px);
  top: 18px;
  font-size: 14px;
  color: #a0abb4;
}
.form-group.has-label > i {
  top: 45px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}
textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: var(--success-color);
  display: block;
}
.form-messages.error {
  color: var(--error-color);
  display: block;
}
.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

/*------------------- 2.4. Slick Slider -------------------*/
.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 38px 0 38px 0;
  line-height: 0;
  text-align: center;
  height: max-content;
}
.slick-dots li {
  display: inline-block;
  margin-right: 25px;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 8px;
  height: 8px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  background-color: var(--dots-bg, #d8d8d8);
  transition: all ease 0.4s;
  position: relative;
}
.slick-dots button:hover {
  border-color: var(--theme-color);
}
.slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}
.slick-dots .slick-active button {
  background-color: var(--theme-color);
}
.slick-dots .slick-active button::before {
  opacity: 1;
  visibility: visible;
}

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  border: 1px solid var(--smoke-color);
  left: var(--pos-x, -100px);
  width: var(--icon-size, 50px);
  height: var(--icon-size, 50px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 50px) / -2);
  z-index: 2;
  border-radius: 50%;
}
.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}
.slick-arrow.slick-next {
  right: var(--pos-x, -100px);
  left: auto;
}
.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.arrow-margin .slick-arrow {
  top: calc(50% - 30px);
}

.arrow-wrap .slick-arrow {
  opacity: 0;
  visibility: hidden;
}
.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.dot-style2 .slick-dots {
  margin-top: 10px;
  margin-bottom: 30px;
}
.dot-style2 .slick-dots button {
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background-color: var(--theme-color);
}
.dot-style2 .slick-dots button:before {
  display: none;
}
.dot-style2 .slick-dots button:hover {
  background-color: var(--title-color);
}
.dot-style2 .slick-dots li {
  margin: 0 5px;
}
.dot-style2 .slick-dots li.slick-active button {
  width: 40px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .slick-arrow {
    --arrow-horizontal: -20px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .slick-arrow {
    --arrow-horizontal: 40px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }
  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  }
  .slick-dots {
    margin: 8px 0 38px 0;
  }
}
.blog-slide-btns .vs-btn i {
  margin-left: 0;
}

/*------------------- 2.5. Mobile Menu -------------------*/
.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}
.vs-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: var(--theme-color);
}
.vs-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}
.vs-menu-wrapper .vs-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  z-index: 1;
  border-radius: 50%;
  background-color: var(--white-color);
  color: var(--theme-color);
}
.vs-menu-wrapper .vs-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.vs-menu-wrapper .vs-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  transition: all ease 1s;
  z-index: 1;
}
.vs-menu-wrapper.vs-body-visible {
  opacity: 1;
  visibility: visible;
}
.vs-menu-wrapper.vs-body-visible .vs-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}
.vs-mobile-menu .new-label {
  margin-left: 5px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.vs-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}
.vs-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}
.vs-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}
.vs-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
}
.vs-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  display: inline-block;
}
.vs-mobile-menu ul li.vs-active > a {
  color: var(--theme-color);
}
.vs-mobile-menu ul li.vs-active > a:before {
  transform: rotate(90deg);
}
.vs-mobile-menu ul li ul li {
  padding-left: 10px;
}
.vs-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}
.vs-mobile-menu ul .vs-item-has-children > a .vs-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}
.vs-mobile-menu ul .vs-item-has-children > a .vs-mean-expand:before {
  content: "\f067";
  font-family: var(--icon-font);
}
.vs-mobile-menu ul .vs-item-has-children.vs-active > a .vs-mean-expand:before {
  content: "\f068";
}
.vs-mobile-menu > ul {
  padding: 0 40px;
}
.vs-mobile-menu > ul > li:last-child {
  border-bottom: none;
}

.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 5px;
}
.vs-menu-toggle:hover {
  background-color: var(--sec-color);
  color: var(--white-color);
}
.vs-menu-toggle.style2 {
  background-color: var(--theme-color);
  border-radius: 50%;
  color: var(--white-color);
  width: 55px;
  height: 55px;
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }
  .vs-mobile-menu > ul {
    padding: 0 20px;
  }
}
/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--white-color);
}
.preloader .vs-btn {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 14px;
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.preloader-inner img {
  display: block;
  margin: 0 auto 10px auto;
}

.loader {
  animation: loaderspin 1.5s infinite;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
}
.loader:before, .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1.5s infinite;
  background-color: var(--title-color);
  box-shadow: 30px 0 0 var(--theme-color);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1.5s infinite;
  background-color: var(--theme-color);
  box-shadow: 30px 0 0 var(--title-color);
}

@keyframes loaderspin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color);
  }
  50% {
    box-shadow: 0 0 0 var(--theme-color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--theme-color);
    margin-bottom: 10px;
  }
}
@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--title-color);
  }
  50% {
    box-shadow: 0 0 0 var(--title-color);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--title-color);
    margin-top: 0;
  }
}
/*------------------- 3.2. Buttons -------------------*/
.vs-btn {
  position: relative;
  display: inline-block;
  border: none;
  text-transform: capitalize;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 22px 40px;
  z-index: 1;
  overflow: hidden;
  border-radius: 30px;
}
@media (max-width: 767px) {
  .vs-btn {
    padding: 20px 20px;
  }
}
.vs-btn::after, .vs-btn::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: var(--sec-color);
  transition: all ease 0.5s;
  z-index: -1;
  border-radius: inherit;
}
.vs-btn:before {
  transform: rotateX(90deg);
}
.vs-btn:after {
  transform: rotateY(90deg);
}
.vs-btn > i {
  margin-left: 8px;
}
.vs-btn.style2 {
  background-color: transparent;
  color: var(--white-color);
  border: 4px solid var(--white-color);
  border-radius: 30px;
  padding: 18px 30px;
}
.vs-btn.style3 {
  background-color: var(--white-color);
  color: var(--theme-color);
  border-radius: 60px;
  padding: 20px 45px;
}
@media (max-width: 767px) {
  .vs-btn.style3 {
    padding: 20px 30px;
  }
}
.vs-btn.style3::after, .vs-btn.style3::before {
  background-color: var(--theme-color);
  border-radius: 30px;
}
.vs-btn.style3:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}
.vs-btn.style4 {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.vs-btn.style4::after, .vs-btn.style4::before {
  background-color: var(--sec-color);
}
.vs-btn.slidebtn1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.vs-btn.slidebtn1 i {
  margin-left: 0;
}
.vs-btn.slidebtn1.next {
  left: auto;
  right: -37px;
}
@media (max-width: 1399px) {
  .vs-btn.slidebtn1.next {
    right: 0;
  }
}
.vs-btn.slidebtn1.prev {
  left: -37px;
}
@media (max-width: 1399px) {
  .vs-btn.slidebtn1.prev {
    left: 0;
  }
}
.vs-btn:hover {
  color: var(--white-color);
  background-color: var(--title-color);
}
.vs-btn:hover:before {
  transform: rotateX(0);
}
.vs-btn:hover:after {
  transform: rotateY(0);
}

.icon-btn {
  display: inline-block;
  width: var(--btn-size, 67px);
  height: var(--btn-size, 67px);
  line-height: var(--btn-size, 67px);
  font-size: var(--btn-font-size, 20px);
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  text-align: center;
  border-radius: 50%;
  padding: 0;
}
.icon-btn.style2 {
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
}
.icon-btn.style2:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.icon-btn.style4, .icon-btn.style3 {
  --btn-size: 55px;
  background-color: #f2f6ff;
  color: var(--theme-color);
  border-radius: 5px;
}
.icon-btn.style4 {
  background-color: var(--white-color);
}
.icon-btn.style5 {
  background-color: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);
}
.icon-btn.style5:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: none;
}
.icon-btn.style6 {
  background-color: var(--theme-color);
  color: var(--white-color);
  --btn-size: 55px;
  --btn-font-size: 17px;
}
.icon-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: transparent;
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  border: none;
  background-color: transparent;
  padding: 0;
  --icon-size: 80px;
}
.play-btn > i {
  display: inline-block;
  width: var(--icon-size, 124px);
  height: var(--icon-size, 124px);
  line-height: var(--icon-size, 124px);
  font-size: var(--icon-font-size, 1.5em);
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  z-index: 1;
  transition: all ease 0.4s;
}
.play-btn:after, .play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white-color);
  z-index: -1;
  border-radius: 50%;
  transition: all ease 0.4s;
  animation: 5s ripple2 linear infinite;
}
.play-btn:after {
  animation-delay: 2s;
}
.play-btn.style3 i, .play-btn.style2 i {
  --icon-size: 60px;
  border-radius: 5px;
  --icon-font-size: 18px;
}
.play-btn.style2:after, .play-btn.style2:before {
  border-radius: 5px;
}
.play-btn.style3 i {
  background-color: rgba(14, 89, 242, 0.7);
}
.play-btn.style3:after, .play-btn.style3::before {
  display: none;
}
.play-btn.style4 {
  --icon-size: 120px;
  --icon-font-size: 30px;
}
.play-btn.style4::before, .play-btn.style4::after {
  background-color: var(--white-color);
}
.play-btn.style4 i {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.play-btn.style5 i {
  border-radius: 0;
  --icon-size: 120px;
  --icon-font-size: 30px;
}
.play-btn.style5:after, .play-btn.style5:before {
  border: 2px solid var(--theme-color);
  background-color: transparent;
  border-radius: 0;
}
.play-btn.style6 {
  --icon-size: 60px;
  --icon-font-size: 18px;
}
.play-btn:hover:after, .play-btn:hover::before,
.play-btn:hover i {
  background-color: var(--sec-color);
  color: var(--white-color);
}

.link-btn {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
}
.link-btn i {
  margin-left: 7px;
  font-size: 0.9rem;
}
.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--theme-color);
  transition: all ease 0.4s;
}
.link-btn:hover {
  color: var(--theme-color);
}
.link-btn:hover::before {
  width: 100%;
}

.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 50%;
  /* Small devices */
}
.scroll-btn i {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  transition: all ease 0.8s;
}
.scroll-btn:before {
  content: "";
  position: absolute;
  left: var(--extra-shape, -6px);
  top: var(--extra-shape, -6px);
  right: var(--extra-shape, -6px);
  bottom: var(--extra-shape, -6px);
  background-color: transparent;
  border-radius: inherit;
  z-index: 1;
  border: 2px dashed var(--theme-color);
  animation: spin 13s infinite linear;
}
.scroll-btn:focus i, .scroll-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}
.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .scroll-btn {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;
  }
  .scroll-btn.show {
    bottom: 15px;
  }
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 96;
}
.scrollToTop.show {
  bottom: 60px;
  opacity: 1;
  visibility: visible;
}

/* Large devices */
@media (max-width: 1199px) {
  .play-btn.style4 {
    --icon-size: 80px;
    --icon-font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .play-btn {
    --icon-size: 60px;
  }
  .scrollToTop {
    right: 20px;
  }
  .scrollToTop.show {
    bottom: 20px;
  }
}
/*------------------- 3.3. Titles -------------------*/
.sec-title,
.sec-title2 {
  line-height: 1.2;
  margin: -0.25em 0 20px 0;
}

.sec-title2 {
  color: var(--white-color);
}

.sec-subtitle,
.sec-subtitle2,
.sec-subtitle3,
.sec-subtitle4 {
  text-transform: capitalize;
  display: block;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin: -0.25em 0 20px 0;
}
.sec-subtitle::before,
.sec-subtitle2::before,
.sec-subtitle3::before,
.sec-subtitle4::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 18px 18px 0 0;
  border-color: var(--title-color) transparent transparent transparent;
  transform: rotate(0deg);
  display: inline-block;
  margin-right: 8px;
}
.sec-subtitle > i,
.sec-subtitle2 > i,
.sec-subtitle3 > i,
.sec-subtitle4 > i {
  margin-right: 15px;
}

.sec-subtitle2::after {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 18px 18px 0;
  border-color: transparent var(--title-color) transparent transparent;
  transform: rotate(0deg);
  display: inline-block;
  margin-left: 8px;
}

.sec-subtitle3 {
  color: var(--title-color);
}

.sec-subtitle4 {
  color: var(--white-color);
}
@media (max-width: 991px) {
  .sec-subtitle4 {
    font-size: 18px;
  }
}
.sec-subtitle4::before {
  border-color: var(--white-color) transparent transparent transparent;
}
.sec-subtitle4.style2::after {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 18px 18px 0;
  border-color: transparent var(--white-color) transparent transparent;
  transform: rotate(0deg);
  display: inline-block;
  margin-left: 8px;
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 40px);
}

.sec-btns {
  padding: 13px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: calc(var(--section-title-space) - 40px);
}
.sec-btns i.fa-arrow-left {
  margin-left: 0;
  margin-right: 10px;
}
.sec-btns.style1 .vs-btn i {
  margin-right: 0;
  margin-left: 0;
}
.sec-btns.style2 .vs-btn {
  background-color: var(--sec-color);
}
.sec-btns.style2 .vs-btn::before, .sec-btns.style2 .vs-btn::after {
  background-color: var(--theme-color);
}
.sec-btns.style2 .vs-btn i {
  margin-right: 0;
  margin-left: 0;
}

.sec-btns2 {
  margin-bottom: calc(var(--section-title-space) - 5px);
}

.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;
}
.sec-line-wrap .sec-title2 {
  margin: -0.3em 0 -0.4em 0;
}

.sec-line {
  background-color: var(--theme-color);
  flex: 1;
  height: 2px;
}

.border-title {
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 0;
}
.border-title-border {
  width: 48px;
  height: 2px;
  display: inline-block;
  background-color: var(--theme-color);
  margin-bottom: 25px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .sec-title4 {
    font-size: 42px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .sec-title4 {
    font-size: 36px;
    line-height: 1.4;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }
  .sec-line-wrap {
    margin: 0 0 60px 0;
  }
  .sec-subtitle2 {
    margin-bottom: 15px;
  }
  .title-area {
    margin-bottom: 40px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }
  .sec-subtitle {
    font-size: 16px;
  }
  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }
  .sec-title4 {
    font-size: 30px;
    line-height: 1.4;
  }
}
/*------------------- 3.4. Common -------------------*/
.image-scale-hover {
  overflow: hidden;
}
.image-scale-hover img {
  transition: all ease 0.4s;
  transform: scale(1.001);
}
.image-scale-hover:hover img {
  transform: scale(1.2);
}

.alert-dismissible .btn-close {
  font-size: 18px;
  line-height: 1;
}

.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}

.media-body {
  flex: 1;
}

.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.35em 0.55em;
  border-radius: 50%;
}

.hr-style1 {
  background-color: var(--white-color);
  margin: 20px 0 30px 0;
}

.new-label {
  font-size: 12px;
  background-color: var(--error-color);
  color: var(--white-color);
  line-height: 1;
  padding: 1px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  top: -1px;
}

.big-name {
  font-size: 450px;
  color: var(--white-color);
  -webkit-text-stroke: 2px #eef0ff;
  line-height: 1;
  letter-spacing: -0.08em;
  font-weight: 400;
  position: relative;
  z-index: 31;
  text-align: right;
  margin: -234px 30px -59px 0;
}

.sec-big-text2,
.sec-big-text {
  font-size: 160px;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1;
  display: block;
  color: #f1f3f9;
  position: absolute;
  left: 0;
  right: 0;
  top: -37px;
  z-index: -1;
}

.sec-big-text2 {
  top: auto;
  left: auto;
  right: 120px;
  bottom: -26px;
}

.home-five-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f6f7fc;
  width: 100%;
  max-width: 1255px;
  z-index: -3;
}

.home-five-shape2 {
  position: absolute;
  left: -428px;
  bottom: 250px;
  width: 1024px;
  height: 1024px;
  border: 50px solid var(--theme-color);
  border-radius: 50%;
  z-index: -2;
}

.home-five-shape3 {
  position: absolute;
  right: 60px;
  bottom: 325px;
}

.home-five-wrap1 {
  position: relative;
  border-bottom: 7px solid var(--theme-color);
  z-index: 1;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .ls-wrapper > .ls-layer.d-hd-none,
  .ls-wrapper > a > .ls-layer.d-hd-none,
  .d-hd-none {
    display: none !important;
  }
}
@media (min-width: 1500px) {
  .d-xxxl-block {
    display: block !important;
  }
}
/* Extra large devices */
@media (max-width: 1500px) {
  .big-name {
    font-size: 320px;
    margin: -168px 30px -59px 0;
  }
  .home-five-shape3 {
    right: 30px;
    bottom: 50px;
    max-width: 200px;
  }
  .home-five-shape2 {
    left: -18%;
    bottom: 34%;
    width: 500px;
    height: 500px;
  }
  .home-five-shape1 {
    max-width: 100%;
    right: 60px;
    width: auto;
  }
  .sec-big-text2,
  .sec-big-text {
    font-size: 130px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .big-name {
    font-size: 245px;
    margin: -128px 40px -42px 0;
  }
  .home-five-shape1 {
    right: 0;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .big-name {
    font-size: 190px;
    margin: -100px 40px -42px 0;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .big-name {
    font-size: 146px;
    margin: -78px 40px -25px 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .big-name {
    font-size: 75px;
    margin: -20px 0 -12px 0;
    letter-spacing: 0;
    text-align: center;
  }
}
.smoke-bg {
  background-color: var(--smoke-color);
}

.shape-mockup-wrap {
  position: relative;
  overflow: hidden;
}

.shape-mockup {
  display: inline-block;
  position: absolute;
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  font-family: var(--icon-font);
}

.font-title {
  font-family: var(--title-font);
}

.font-body {
  font-family: var(--body-font);
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.bg-gray {
  background-color: #e5e5e5;
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit, .footer-info-list .info a {
  color: inherit;
}
.text-inherit:hover, .footer-info-list .info a:hover {
  color: var(--theme-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

.text-light2 {
  color: #919398;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-overlay] {
  position: relative;
}
[data-overlay] [class^=col-],
[data-overlay] [class*=col-] {
  z-index: 1;
}

[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay=theme]:before {
  background-color: var(--theme-color);
}

[data-overlay=title]:before {
  background-color: var(--title-color);
}

[data-overlay=white]:before {
  background-color: var(--white-color);
}

[data-overlay=black]:before {
  background-color: var(--black-color);
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.wow-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadein {
  --animation-name: fadein-custom;
}

.slideinup {
  --animation-name: slideinup;
}

.slideindown {
  --animation-name: slideindown;
}

.slideinleft {
  --animation-name: slideinleft;
}

.slideinright {
  --animation-name: slideinright;
}

.animated, .hero-btns {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}

.ripple-animation {
  animation-duration: var(--ripple-ani-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadein-custom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_nav_menu > ul,
.widget_meta > ul,
.widget_pages > ul,
.widget_archive > ul,
.widget_categories > ul {
  margin: 0 0 -38px 0;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  background-color: transparent;
  color: var(--body-color);
  margin: 0 0 18px 0;
  padding: 0 0 17px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px solid #dadfec;
}
.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--theme-color);
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}
.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
  border-bottom: none;
}
.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span {
  text-align: center;
  position: absolute;
  right: 0;
  top: -4.5px;
  font-size: 16px;
  transition: all ease 0.4s;
}
.widget_nav_menu li:hover > span,
.widget_meta li:hover > span,
.widget_pages li:hover > span,
.widget_archive li:hover > span,
.widget_categories li:hover > span {
  color: var(--theme-color);
}
.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.wp-block-archives a:not(:hover) {
  color: inherit;
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

/* Small devices */
@media (max-width: 767px) {
  .widget_nav_menu a,
  .widget_meta a,
  .widget_pages a,
  .widget_archive a,
  .widget_categories a {
    font-size: 14px;
  }
}
.wp-block-categories {
  padding-left: 0;
}
.wp-block-categories li a {
  background-color: var(--white-color);
  padding: 5px 5px 5px 30px;
  margin: 0 0 10px 0;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
}
.wp-block-categories li a span {
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.wp-block-categories li a span::before {
  content: "\f061";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--icon-font);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.wp-block-categories li a:hover span {
  color: var(--theme-color);
}
.wp-block-categories li a:hover span::before {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  color: var(--white-color);
}

.widget {
  padding: var(--widget-padding-y, 30px) var(--widget-padding-x, 30px);
  background-color: transparent;
  position: relative;
  background-color: var(--smoke-color);
  margin-bottom: 30px;
  border-radius: 20px;
}
.widget select,
.widget input {
  height: 55px;
  border: none;
  background-color: #fff;
  padding-left: 20px;
  font-weight: 400;
  color: var(--white-color);
}
.widget select::placeholder,
.widget input::placeholder {
  color: var(--white-color);
  font-size: 16px;
}
.widget .wp-block--submit__input {
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid var(--white-color);
  color: var(--white-color);
  font-size: 16px;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
}
.widget .wp-block--submit__input::placeholder {
  color: var(--white-color);
  font-size: 14px;
}
.widget .wp-block--submit__input:focus::placeholder {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.widget .search-form {
  position: relative;
  display: flex;
  background-color: var(--white-color);
  align-items: center;
  padding: 5px;
  border-radius: 30px;
  overflow: hidden;
}
.widget .search-form input {
  flex: 1;
  color: var(--title-color);
  height: 50px;
}
.widget .search-form input::placeholder {
  color: var(--title-color);
}
.widget .search-form button {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  height: 50px;
  width: 50px;
  display: inline-flex;
  align-items: center;
  font-size: 17px;
  justify-content: center;
  border-radius: 50%;
}
.widget .search-form button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.wp-block-tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  align-items: center;
}
.wp-block-tag-cloud__title {
  font-weight: 700;
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-right: 0;
  margin-bottom: 0;
  color: var(--black-color);
  text-transform: capitalize;
  border-radius: 0px;
  font-family: var(--title-font);
}
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: transparent;
  color: var(--theme-color);
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}
.tagcloud a {
  background-color: var(--white-color);
  color: var(--title-color);
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.recent-post:last-child {
  margin-bottom: 0;
}
.recent-post .media-img {
  margin-right: 15px;
  width: 100px;
  overflow: hidden;
  border-radius: 10px;
}
.recent-post .media-img img {
  width: 100%;
  border-radius: 10px;
  transition: all ease 0.4s;
}
.recent-post .post-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 8px 0;
}
.recent-post .post-title > a {
  display: block;
}
.recent-post .recent-post-meta a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-color);
  font-family: var(--title-font);
}
.recent-post .recent-post-meta a i {
  margin-right: 5px;
  color: var(--theme-color);
}
.recent-post:hover .media-img img {
  transform: scale(1.1);
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;
}
.wp-block-calendar span[class*=wp-calendar-nav],
.calendar_wrap span[class*=wp-calendar-nav] {
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  z-index: 1;
}
.wp-block-calendar span[class*=wp-calendar-nav] a,
.calendar_wrap span[class*=wp-calendar-nav] a {
  color: inherit;
}
.wp-block-calendar span.wp-calendar-nav-next,
.calendar_wrap span.wp-calendar-nav-next {
  left: auto;
  right: 20px;
}
.wp-block-calendar caption,
.calendar_wrap caption {
  caption-side: top;
  text-align: center;
  color: var(--title-color);
  background-color: #f1dbbe;
}
.wp-block-calendar th,
.calendar_wrap th {
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-right: 1px solid #fff;
  color: #01133c;
  font-weight: 500;
}
.wp-block-calendar th a,
.calendar_wrap th a {
  color: inherit;
}
.wp-block-calendar td,
.calendar_wrap td {
  font-size: 14px;
  padding: 5px;
  color: #01133c;
  border: 1px solid #ededed;
  text-align: center;
  background-color: transparent;
  transition: all ease 0.4s;
}
.wp-block-calendar #today,
.calendar_wrap #today {
  color: var(--theme-color);
  background-color: var(--white-color);
  border-color: #ededed;
}
.wp-block-calendar thead,
.calendar_wrap thead {
  background-color: #f6f6f6;
}
.wp-block-calendar .wp-calendar-table,
.calendar_wrap .wp-calendar-table {
  margin-bottom: 0;
}
.wp-block-calendar .wp-calendar-nav .pad,
.calendar_wrap .wp-calendar-nav .pad {
  display: none;
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  border-radius: 5px;
}
.sidebar-gallery .gallery-thumb img {
  width: 100%;
  transform: scale(1);
  transition: all ease 0.4s;
}
.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.2);
}

.widget_shopping_cart {
  text-align: left;
}
.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget_shopping_cart .mini_cart_item {
  position: relative;
  border-bottom: 1px solid var(--border-color);
  padding: 0 0 32px 110px;
  margin: 0 0 27px 0;
  min-height: 90px;
}
.widget_shopping_cart .remove {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--title-color);
  line-height: 1;
  font-size: 18px;
}
.widget_shopping_cart .remove:hover {
  color: var(--theme-color);
}
.widget_shopping_cart .img {
  position: absolute;
  left: 0;
  top: 3px;
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 1px solid var(--border-color);
}
.widget_shopping_cart .product-title {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 400;
  margin-bottom: 0;
  display: inline-block;
}
.widget_shopping_cart .amount {
  display: block;
  font-weight: 600;
  color: var(--title-color);
  font-size: 16px;
}
.widget_shopping_cart .quantity {
  display: inline-flex;
  margin-top: 12px;
}
.widget_shopping_cart .qut-btn {
  border: 1px solid var(--border-color);
  background-color: transparent;
  display: inline-block;
  background-color: transparent;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 12px;
  z-index: 1;
  position: relative;
}
.widget_shopping_cart .qut-btn:hover {
  background-color: var(--title-color);
  border-color: transparent;
  color: var(--white-color);
}
.widget_shopping_cart .qty-input {
  border: 1px solid var(--border-color);
  text-align: center;
  width: max-content;
  min-width: 40px;
  font-size: 12px;
  padding: 0;
  height: 25px;
  margin: 0 -1px;
}
.widget_shopping_cart .subtotal {
  float: right;
  text-align: right;
  font-size: 12px;
  margin-top: 19px;
}
.widget_shopping_cart .subtotal > span:not(.amount) {
  color: var(--body-color);
  font-weight: 300;
}
.widget_shopping_cart .subtotal .amount {
  font-size: 12px;
  display: inline-block;
}
.widget_shopping_cart .total {
  color: var(--title-color);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 25px;
  font-size: 18px;
}
.widget_shopping_cart .total .amount {
  font-size: inherit;
  display: inline-block;
  color: var(--theme-color);
}
.widget_shopping_cart .vs-btn {
  border: 1px solid var(--title-color);
  color: var(--title-color);
  margin-right: 10px;
  text-transform: capitalize;
  height: 50px;
  line-height: 47px;
  padding: 0 35px;
  background-color: var(--title-color);
  color: var(--white-color);
  font-weight: 400;
}
.widget_shopping_cart .vs-btn:hover {
  background-color: transparent;
  color: var(--title-color);
}
.widget_shopping_cart .buttons {
  margin: 0;
}

.sidebar-area {
  margin-bottom: -10px;
  position: sticky;
  top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .sidebar-area {
    margin-bottom: 0;
  }
}
.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}
.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}
.sidebar-area .newsletter-form .newsletter-text {
  max-width: 70%;
  margin: -0.42em 0 15px 0;
}
.sidebar-area .newsletter-form .form-control {
  color: var(--body-color);
  border-radius: 50px;
  height: 60px;
  padding: 0 30px 0 30px;
}
.sidebar-area .newsletter-form .form-control::placeholder {
  color: var(--body-color);
}
.sidebar-area .newsletter-form .vs-btn {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  margin-top: 20px;
}
.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}
.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}
.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}
.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-area .widget_title {
  font-size: 24px;
  margin-bottom: 30px;
}
.sidebar-area .widget_title img {
  display: block;
  padding-top: 10px;
}
.sidebar-area .wp-block-details {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.sidebar-area .wp-block-details > li {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
}
.sidebar-area .wp-block-details > li:last-child {
  margin-bottom: 30px;
}
.sidebar-area .wp-block-details .item-card {
  padding: 0;
  gap: 15px;
}
.sidebar-area .wp-block-details .item-card .item-icon {
  font-size: 14px;
  min-width: 50px;
  height: 50px;
}
.sidebar-area .wp-block-details .item-card .item-title {
  font-size: 20px;
  color: var(--title-color);
}
.sidebar-area .wp-block-details .item-card .item-text {
  font-size: 14px;
  font-weight: 700;
  color: var(--body-color);
}

.recent-product {
  display: flex;
}
.recent-product .product-title {
  font-size: 16px;
}

.widget-workhours ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-workhours li {
  background-color: var(--white-color);
  padding: 14.5px 20px;
  margin: 0 0 15px 0;
  font-family: var(--title-font);
  font-weight: 500;
}
.widget-workhours li:last-child {
  margin-bottom: 0;
}
.widget-workhours li i {
  margin-right: 10px;
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;
}
.quote-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 26, 62, 0.7);
}
.quote-box__title {
  color: var(--white-color);
  position: relative;
  z-index: 1;
  max-width: 200px;
  margin: -0.3em auto 25px auto;
}

/* Large devices */
@media (max-width: 1199px) {
  .widget {
    --widget-padding-y: 25px;
    --widget-padding-x: 25px;
  }
  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .recent-post .post-title {
    font-size: 14px;
    line-height: 22px;
  }
  .recent-post .recent-post-meta a {
    font-size: 12px;
  }
  .widget-workhours li {
    padding: 14.5px 20px;
    font-size: 14px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .contact-widget .icon {
    --icon-size: 30px;
    --icon-font-size: 12px;
    top: 5px;
  }
  .contact-widget .contact {
    padding: 0 0 0 45px;
    margin: 0 0 20px 0;
    border-bottom: none;
  }
  .sidebar-gallery {
    gap: 10px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .widget {
    padding: 30px 20px;
  }
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.sidebar-gallery .gallery-thumb img {
  transition: all ease 0.4s;
  width: 100%;
  transform: scale(1);
}

.sidebar-gallery .gallery-thumb:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}

.sidebar-gallery .gallery-thumb .gal-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  width: var(--icon-size, 35px);
  height: var(--icon-size, 35px);
  margin: calc(var(--icon-size, 35px) / -2) 0 0 calc(var(--icon-size, 35px) / -2);
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sidebar-gallery .gallery-thumb .gal-btn:hover {
  transform: scale(1);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.sidebar-gallery .gallery-thumb:hover:before {
  opacity: 0.8;
  visibility: visible;
}

.sidebar-gallery .gallery-thumb:hover .gal-btn {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.1s;
}

.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.12);
}

.widget--bg1 {
  background-color: var(--blackSand-color);
}

.vs-widget-about__text {
  color: var(--white-color);
}

.sidebar-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sidebar-info .info-logo {
  min-width: 130px;
  height: 130px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--theme-color);
  border-radius: 10px;
  background-color: var(--white-color);
  padding: 5px;
}
@media (max-width: 767px) {
  .sidebar-info .info-logo {
    min-width: 50px;
    height: 50px;
  }
}
.sidebar-info .sec-title {
  font-size: 24px;
  margin: -0.25em 0 8px 0;
}
.sidebar-info .info-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.sidebar-info .info-list ul li {
  display: flex;
  align-items: center;
}
.sidebar-info .info-list ul li i {
  color: var(--theme-color);
  min-width: 25px;
}
.sidebar-info .info-list ul li a {
  color: var(--body-color);
  font-size: 14px;
}

.sidebar-brand {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.sidebar-brand .brand-logo {
  background-color: var(--white-color);
  height: 170px;
  border: 2px solid var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 5px;
}
@media (max-width: 767px) {
  .sidebar-brand .brand-logo {
    height: 130px;
  }
}

.footer-widget {
  margin-bottom: 30px;
}
.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}
.footer-widget .widget_title {
  margin: -9px 0 30px 0;
  color: var(--white-color);
  font-size: 24px;
}
.footer-widget .widget_title img {
  display: block;
  padding-top: 12px;
}
.footer-widget.widget_meta, .footer-widget.widget_pages, .footer-widget.widget_archive, .footer-widget.widget_categories, .footer-widget.widget_nav_menu {
  margin-bottom: 40px;
}
.footer-widget.widget_meta a, .footer-widget.widget_pages a, .footer-widget.widget_archive a, .footer-widget.widget_categories a, .footer-widget.widget_nav_menu a {
  width: max-content;
  display: block;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  max-width: 100%;
  padding: 0;
}
.footer-widget.widget_meta a:hover, .footer-widget.widget_pages a:hover, .footer-widget.widget_archive a:hover, .footer-widget.widget_categories a:hover, .footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--theme-color);
}
.footer-widget.widget_meta a:before, .footer-widget.widget_pages a:before, .footer-widget.widget_archive a:before, .footer-widget.widget_categories a:before, .footer-widget.widget_nav_menu a:before {
  display: none;
}
.footer-widget.widget_meta li > span, .footer-widget.widget_pages li > span, .footer-widget.widget_archive li > span, .footer-widget.widget_categories li > span, .footer-widget.widget_nav_menu li > span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}
.footer-widget.widget_meta li:last-child a, .footer-widget.widget_pages li:last-child a, .footer-widget.widget_archive li:last-child a, .footer-widget.widget_categories li:last-child a, .footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}
.footer-widget.widget_nav_menu .menu a {
  font-size: 16px;
  color: var(--white-color);
  padding-left: 15px;
  position: relative;
}
.footer-widget.widget_nav_menu .menu a span {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.footer-widget.widget_nav_menu .menu a::before, .footer-widget.widget_nav_menu .menu a::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.footer-widget.widget_nav_menu .menu a::before {
  content: "\f105";
  opacity: 1;
  visibility: visible;
}
.footer-widget.widget_nav_menu .menu a::after {
  opacity: 0;
  visibility: hidden;
  content: "\f101";
  display: none;
}
.footer-widget.widget_nav_menu .menu a:hover {
  color: var(--white-color);
  transform: translateX(15px);
}
.footer-widget.widget_nav_menu .menu a:hover span {
  width: 100%;
  left: 0;
}
.footer-widget.widget_nav_menu .menu a:hover::before, .footer-widget.widget_nav_menu .menu a:hover::after {
  color: var(--white-color);
}
.footer-widget.widget_nav_menu .menu a:hover::after {
  opacity: 1;
  visibility: visible;
}
.footer-widget .footer-links > ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.footer-widget .footer-links a {
  margin-bottom: 21px;
}

.footer-logo {
  margin-bottom: 15px;
}

.footer-text {
  margin: 0em 0 20px 0;
  max-width: 300px;
  color: var(--white-color);
}

.footer-bottom {
  padding: 1.5rem 0;
  background: var(--white-color);
  border-radius: 0;
  margin-left: 0;
  padding-left: 0;
}

.footer-divider {
  height: 50px;
  width: 2px;
  background: var(--title-color);
  margin: auto 42px;
}
@media (max-width: 1399px) {
  .footer-divider {
    margin: auto 0;
  }
}

.footer-info {
  display: flex;
  align-items: center;
  background-color: var(--theme-color);
  border-radius: 10px;
  padding: 5px 30px 5px 5px;
}
.footer-info_group {
  display: flex;
  justify-content: center;
  border-right: 1px solid #152b54;
  padding: 60px 0 60px 0;
}
.footer-info_group:last-child {
  border-right: none;
}
.footer-info_group.style2 {
  border: none;
}
.footer-info_group.style2 .footer-info_icon {
  background-color: transparent;
  border: 1px solid #35496f;
}
.footer-info_icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: var(--sec-color);
  color: var(--white-color);
  font-size: 18px;
  margin: 0 11px 0 0;
  display: inline-block;
  border-radius: 10px;
}
.footer-info_label {
  color: var(--white-color);
  font-size: 16px;
  font-family: var(--title-font);
  text-transform: capitalize;
  display: block;
  margin: 0 0 0px 0;
}
.footer-info_link {
  color: var(--white-color);
  font-weight: 700;
  font-size: 16px;
}
.footer-info_link a {
  color: inherit;
  position: relative;
}
.footer-info_link a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.footer-info_link a:hover {
  color: var(--white-color);
}
.footer-info_link a:hover::after {
  width: 100%;
  left: 0;
}
.footer-info.style2 {
  padding: 0;
  background-color: transparent;
  margin-bottom: 20px;
}
.footer-info.style2:last-child {
  margin-bottom: 0;
}
.footer-info.style2 .footer-info_label, .footer-info.style2 .footer-info_link {
  color: var(--white-color);
}
.footer-info.style2 .footer-info_icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  margin-right: 20px;
}
.footer-info.style2 .footer-info_link a:hover {
  color: var(--white-color);
}

.footer-number {
  border-top: 1px solid #14284f;
  padding: 43px 0 0 0;
  margin: 34px 0 0 0;
  position: relative;
}
.footer-number:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -60px;
  width: 9999px;
  height: 1px;
  border: inherit;
}
.footer-number .info {
  color: var(--body-color);
  margin: 0;
  line-height: 1;
  display: inline-block;
}
.footer-number .info:hover {
  color: var(--theme-color);
}
.footer-number .title {
  margin: 0 0 7px 0;
}

.footer-map {
  max-width: 400px;
  width: 100%;
}
.footer-map frame {
  max-width: 100%;
}

.footer-social .social-title {
  font-family: var(--title-font);
  color: var(--title-color);
  font-size: 20px;
  display: inline-block;
  margin-right: 20px;
  font-weight: 700;
}
.footer-social a {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  margin: 0 5px 0 0;
  color: var(--white-color);
  background-color: var(--theme-color);
  text-align: center;
  border-radius: 10px;
}
.footer-social a:last-child {
  margin-right: 0;
}
.footer-social a:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.footer-social.style2 a {
  background-color: var(--white-color);
  outline: 2px solid #e7e8ec;
  outline-offset: -2px;
  color: var(--body-color);
  box-shadow: 8px 13.856px 30px 0px rgba(132, 137, 154, 0.1);
}
.footer-social.style2 a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  outline-color: transparent;
}

.footer-newsletter {
  display: flex;
}
.footer-newsletter input {
  background-color: transparent;
  border-radius: 5px 0 0 5px;
  border: 1px solid #14284f;
  height: 55px;
  color: var(--white-color);
  flex: 1;
}
.footer-newsletter input::-moz-placeholder {
  color: var(--white-color);
}
.footer-newsletter input::-webkit-input-placeholder {
  color: var(--white-color);
}
.footer-newsletter input:-ms-input-placeholder {
  color: var(--white-color);
}
.footer-newsletter input::placeholder {
  color: var(--white-color);
}
.footer-newsletter .vs-btn {
  border-radius: 0 5px 5px 0;
}
.footer-newsletter .vs-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.footer-newsletter .vs-btn:after, .footer-newsletter .vs-btn::before {
  display: none;
}

.footer-schedule table {
  border: none;
  margin: -0.3em 0 0 0;
}
.footer-schedule th,
.footer-schedule td {
  padding: 0 0 10px 0;
  color: var(--body-color);
  border: none;
  font-weight: 400;
}

.footer-number1 {
  font-size: 30px;
  font-weight: 700;
  color: var(--body-color);
  font-family: var(--title-font);
  margin-bottom: 25px;
  display: block;
  width: max-content;
}

.footer-mail1,
.footer-address1 {
  padding-left: 25px;
  position: relative;
}
.footer-mail1 > i:first-child,
.footer-address1 > i:first-child {
  position: absolute;
  left: 0;
  top: 4px;
}

.footer-address1 {
  max-width: 250px;
}

.footer-mail1 {
  color: var(--body-color);
  text-decoration: underline;
}
.footer-mail1 i:first-child {
  top: 2px;
}

.footer-info-list {
  margin: 35px 0 22px 0;
}
.footer-info-list .info {
  position: relative;
  margin-bottom: 21px;
  padding-left: 30px;
  max-width: 250px;
}
.footer-info-list .info i:first-child {
  position: absolute;
  left: 0;
  top: 4px;
  color: var(--theme-color);
}
.newsletter-form2 .form_text {
  margin: -0.3em 0 22px 0;
  display: block;
  max-width: 300px;
}
.newsletter-form2 input {
  background-color: #ebecf1;
  margin-bottom: 15px;
  border-radius: 7px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .menu-all-pages-container.footer-links {
    width: 330px;
    max-width: 100%;
  }
  .footer-number:before {
    display: none;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .footer-widget {
    margin-bottom: 40px;
  }
  .footer-widget .widget_title {
    font-size: 26px;
  }
  .footer-number {
    padding: 27px 0 0 0;
    margin: 17px 0 0 0;
  }
  .footer-info_group {
    padding: 0;
    border: none;
  }
}
@media (min-width: 991px) and (max-width: 1399px) {
  .footer-newsletter {
    display: block;
  }
  .footer-newsletter input {
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .footer-newsletter .vs-btn {
    border-radius: 5px;
  }
}
/*------------------- 4.2. Header  -------------------*/
.vs-header {
  position: relative;
  z-index: 41;
  background-color: transparent;
}

.header-inner {
  margin-top: -30px;
  position: relative;
  background: var(--white-color);
  padding: 20px 50px 0 50px;
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .header-inner {
    padding: 20px 20px 0 20px;
  }
}
@media (max-width: 991px) {
  .header-inner {
    margin-top: -44px;
    padding: 5px 15px;
  }
}
.header-inner.style2 {
  margin-top: 0;
  padding: 25px 0;
  border-radius: 0;
}
@media (max-width: 991px) {
  .header-inner.style2 {
    padding: 0 0;
  }
}

.vs-navbar {
  background-color: var(--sec-color);
  padding: 0 50px;
  border-radius: 10px;
  margin-bottom: -35px;
}
@media (max-width: 1199px) {
  .vs-navbar {
    padding: 0 20px;
  }
}
@media (max-width: 991px) {
  .vs-navbar {
    margin-bottom: 0;
  }
}
.vs-navbar.will-sticky .sticky-active {
  background-color: var(--title-color);
}
.vs-navbar.style3 {
  padding: 0 40px 0 0;
  background-color: var(--white-color);
  margin: -55px 0;
}
@media (max-width: 1399px) {
  .vs-navbar.style3 {
    padding: 0 15px 0 0;
  }
}
.vs-navbar.style3 .header-logo {
  padding: 25px 40px;
  background-color: var(--theme-color);
  border-radius: 8px 0px 0px 8px;
  margin-left: -1px;
}
@media (max-width: 767px) {
  .vs-navbar.style3 .header-logo {
    padding: 10px 20px;
  }
}
.vs-navbar.style3.will-sticky .sticky-active {
  background-color: var(--white-color);
  padding: 0 15px 0 0;
}
.vs-navbar.style3.will-sticky .sticky-active .header-logo {
  border-radius: 0;
}
@media (max-width: 991px) {
  .vs-navbar.style3.will-sticky .sticky-active .header-logo {
    padding: 10px 15px;
  }
}

.will-sticky .sticky-active {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  transition: all ease 0.8s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  padding: 0 27px;
}
.will-sticky .sticky-active .custom-container {
  max-width: calc(var(--main-container) + var(--container-gutters));
  margin: 0 auto;
}
.will-sticky .sticky-active.active {
  top: 0;
}

.main-menu a,
.menu-style2 a,
.menu-style3 a {
  display: block;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  color: var(--sec-color);
}
.main-menu a:hover,
.menu-style2 a:hover,
.menu-style3 a:hover {
  color: var(--sec-color);
}
.main-menu > ul > li > a .new-label,
.menu-style2 > ul > li > a .new-label,
.menu-style3 > ul > li > a .new-label {
  position: absolute;
  top: -10px;
  right: -27px;
  font-size: 11px;
  border-radius: 3px;
}
.main-menu > ul > li > a .has-new-lable,
.menu-style2 > ul > li > a .has-new-lable,
.menu-style3 > ul > li > a .has-new-lable {
  position: relative;
}
.main-menu ul,
.menu-style2 ul,
.menu-style3 ul {
  margin: 0;
  padding: 0;
}
.main-menu ul li,
.menu-style2 ul li,
.menu-style3 ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}
.main-menu ul li.menu-item-has-children > a:after,
.menu-style2 ul li.menu-item-has-children > a:after,
.menu-style3 ul li.menu-item-has-children > a:after {
  content: "\f078";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 5px;
  font-size: 0.8rem;
}
.main-menu ul li:last-child,
.menu-style2 ul li:last-child,
.menu-style3 ul li:last-child {
  margin-right: 0;
}
.main-menu ul li:first-child,
.menu-style2 ul li:first-child,
.menu-style3 ul li:first-child {
  margin-left: 0;
}
.main-menu ul li:hover > ul.sub-menu,
.main-menu ul li:hover ul.mega-menu,
.menu-style2 ul li:hover > ul.sub-menu,
.menu-style2 ul li:hover ul.mega-menu,
.menu-style3 ul li:hover > ul.sub-menu,
.menu-style3 ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}
.main-menu ul.sub-menu,
.main-menu ul.mega-menu,
.menu-style2 ul.sub-menu,
.menu-style2 ul.mega-menu,
.menu-style3 ul.sub-menu,
.menu-style3 ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  min-width: 190px;
  width: max-content;
  padding: 7px;
  left: -14px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border-bottom: 3px solid var(--theme-color);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  transform-origin: top center;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
}
.main-menu ul.sub-menu a,
.main-menu ul.mega-menu a,
.menu-style2 ul.sub-menu a,
.menu-style2 ul.mega-menu a,
.menu-style3 ul.sub-menu a,
.menu-style3 ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
}
.main-menu ul.sub-menu,
.menu-style2 ul.sub-menu,
.menu-style3 ul.sub-menu {
  padding: 18px 20px;
  left: -27px;
}
.main-menu ul.sub-menu li,
.menu-style2 ul.sub-menu li,
.menu-style3 ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 5px 0px;
}
.main-menu ul.sub-menu li.menu-item-has-children > a:after,
.menu-style2 ul.sub-menu li.menu-item-has-children > a:after,
.menu-style3 ul.sub-menu li.menu-item-has-children > a:after {
  content: "\f105";
  float: right;
  top: 3px;
}
.main-menu ul.sub-menu li a,
.menu-style2 ul.sub-menu li a,
.menu-style3 ul.sub-menu li a {
  position: relative;
  padding-left: 0px;
}
.main-menu ul.sub-menu li a:before,
.menu-style2 ul.sub-menu li a:before,
.menu-style3 ul.sub-menu li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  font-weight: 400;
  margin: 0 10px 0 0;
}
.main-menu ul.sub-menu li ul.sub-menu,
.menu-style2 ul.sub-menu li ul.sub-menu,
.menu-style3 ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}
.main-menu ul.sub-menu li ul.sub-menu li ul,
.menu-style2 ul.sub-menu li ul.sub-menu li ul,
.menu-style3 ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}
.main-menu .mega-menu-wrap,
.menu-style2 .mega-menu-wrap,
.menu-style3 .mega-menu-wrap {
  position: static;
}
.main-menu ul.mega-menu,
.menu-style2 ul.mega-menu,
.menu-style3 ul.mega-menu {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  transform: translateX(-50%);
}
.main-menu ul.mega-menu .main-menu ul.mega-menu > li > ul > li > a,
.menu-style2 ul.mega-menu .main-menu ul.mega-menu > li > ul > li > a,
.menu-style3 ul.mega-menu .main-menu ul.mega-menu > li > ul > li > a {
  position: relative;
}
.main-menu ul.mega-menu li,
.menu-style2 ul.mega-menu li,
.menu-style3 ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}
.main-menu ul.mega-menu li li,
.menu-style2 ul.mega-menu li li,
.menu-style3 ul.mega-menu li li {
  padding: 4px 0;
}
.main-menu ul.mega-menu li a,
.menu-style2 ul.mega-menu li a,
.menu-style3 ul.mega-menu li a {
  display: inline-block;
}
.main-menu ul.mega-menu > li > a,
.menu-style2 ul.mega-menu > li > a,
.menu-style3 ul.mega-menu > li > a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after, .main-menu ul.mega-menu > li > a::before,
.menu-style2 ul.mega-menu > li > a::after,
.menu-style2 ul.mega-menu > li > a::before,
.menu-style3 ul.mega-menu > li > a::after,
.menu-style3 ul.mega-menu > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after,
.menu-style2 ul.mega-menu > li > a::after,
.menu-style3 ul.mega-menu > li > a::after {
  width: calc(100% - 20px);
  left: 20px;
}
.main-menu ul.mega-menu > li > a:hover,
.menu-style2 ul.mega-menu > li > a:hover,
.menu-style3 ul.mega-menu > li > a:hover {
  padding-left: 0;
}
.main-menu ul.mega-menu > li > ul > li > a:before,
.menu-style2 ul.mega-menu > li > ul > li > a:before,
.menu-style3 ul.mega-menu > li > ul > li > a:before {
  content: "\f105";
  font-family: var(--icon-font);
  font-weight: 400;
  margin: 0 10px 0 0;
}

.menu-style1 > ul > li > a,
.menu-style2 > ul > li > a,
.menu-style3 > ul > li > a {
  padding: 26px 0;
  padding-right: 20px;
  margin-right: 20px;
}
@media (max-width: 1199px) {
  .menu-style1 > ul > li > a,
  .menu-style2 > ul > li > a,
  .menu-style3 > ul > li > a {
    padding-right: 13px;
    margin-right: 13px;
  }
}
.menu-style1 > ul > li > a::before,
.menu-style2 > ul > li > a::before,
.menu-style3 > ul > li > a::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  background-color: var(--border-color);
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.menu-style1 > ul > li:last-child a,
.menu-style2 > ul > li:last-child a,
.menu-style3 > ul > li:last-child a {
  padding-right: 0;
  margin-right: 0;
}
.menu-style1 > ul > li:last-child a::before,
.menu-style2 > ul > li:last-child a::before,
.menu-style3 > ul > li:last-child a::before {
  display: none;
}

.menu-style1 a:hover {
  color: var(--theme-color);
}
.menu-style1 ul.mega-menu {
  border-radius: 20px;
}

.menu-style2 ul.mega-menu {
  border-radius: 10px;
}
.menu-style2 a {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.menu-style2 a:hover {
  color: var(--theme-color);
}
.menu-style2 a::after {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.menu-style2 > ul > li > a {
  padding: 21px 0;
  padding-right: 20px;
  margin-right: 20px;
}
.menu-style2 > ul > li:hover > a {
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.menu-style2 > ul > li:hover > a::after {
  transition: all 0.3s ease-in-out;
  transform: scaleY(-1);
}
.menu-style2 ul.sub-menu li a,
.menu-style2 ul.mega-menu li a {
  color: var(--sec-color);
}
.menu-style2 ul.sub-menu li a:hover,
.menu-style2 ul.mega-menu li a:hover {
  color: var(--theme-color);
}

.menu-style3 {
  text-align: center;
}
.menu-style3 ul.mega-menu {
  border-radius: 10px;
}
.menu-style3 a:hover {
  color: var(--theme-color);
}
.menu-style3 > ul > li > a {
  padding: 41px 0;
  padding-right: 20px;
  margin-right: 20px;
}
@media (max-width: 1399px) {
  .menu-style3 > ul > li > a {
    padding-right: 10px;
    margin-right: 10px;
  }
}

.header-text {
  margin: 0;
  color: var(--white-color);
}
.header-text i {
  margin-right: 10px;
}
.header-text.style2 {
  display: inline-flex;
  padding-right: 30px;
  margin-right: 26px;
  position: relative;
}
@media (max-width: 1399px) {
  .header-text.style2 {
    padding-right: 15px;
    margin-right: 15px;
  }
}
.header-text.style2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 20px;
  background-color: var(--white-color);
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.header-text.style2:last-child {
  padding-right: 0;
  margin-right: 0;
}
.header-text.style2:last-child::after {
  display: none;
}
.header-text.style2 span {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  color: var(--title-color);
  border-radius: 50%;
  margin-right: 10px;
}
.header-text.style2 i {
  margin-right: 0;
}
.header-text.style2 a {
  color: var(--white-color);
  position: relative;
}
.header-text.style2 a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.header-text.style2 a:hover {
  color: var(--white-color);
}
.header-text.style2 a:hover::after {
  width: 100%;
  left: 0;
}

.header-info {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 30px;
  padding-left: 30px;
  border-left: 2px solid var(--border-color);
}
.header-info:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}
.header-info_icon {
  color: var(--white-color);
  font-size: 24px;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.header-info_label {
  font-size: 16px;
  display: block;
  margin-bottom: 0;
  font-weight: 700;
  color: var(--sec-color);
}
.header-info_link {
  color: var(--title-color);
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.header-info_link a {
  color: inherit;
}
.header-info_link a:hover {
  color: var(--theme-color);
}
.header-info:hover .header-info_icon {
  background-color: var(--sec-color);
  color: var(--white-color);
}

.header-search {
  display: flex;
  width: 205px;
  max-width: 100%;
  margin-right: 30px;
}
.header-search input {
  flex: 1;
  border: none;
  padding: 0 20px 0 20px;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  transition: all ease 0.4s;
  font-size: 16px;
  background-color: transparent;
  color: var(--title-color);
}
.header-search input::placeholder {
  color: var(--title-color);
}
.header-search input:focus {
  background-color: transparent;
}
.header-search button {
  border: none;
  background-color: transparent;
  padding: 0;
}
.header-search button:hover {
  background-color: transparent;
}
.header-search input:focus {
  padding: 0 20px 0 20px;
  background-color: transparent;
}
.header-search.v2, .header-search.v3 {
  justify-content: flex-end;
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}
.header-search.v2::after, .header-search.v3::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 25px;
  background-color: var(--border-color);
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.header-search.v2 button, .header-search.v3 button {
  background-color: transparent;
}
.header-search.v2 input, .header-search.v3 input {
  background-color: transparent;
  max-width: 170px;
  color: var(--white-color);
}
.header-search.v2 input::placeholder, .header-search.v3 input::placeholder {
  color: var(--white-color);
}
.header-search.v3 input {
  color: var(--title-color);
}
.header-search.v3 input::placeholder {
  color: var(--title-color);
}

.header-links {
  display: flex;
  align-items: center;
}
.header-links a {
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-left: 30px;
  position: relative;
  color: var(--white-color);
}
.header-links a:first-child {
  margin-left: 0;
}
.header-links a::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 20px;
  background-color: var(--white-color);
  left: 0;
}
.header-links a:hover {
  color: var(--theme-color);
}
.header-links a:hover i {
  color: var(--theme-color);
}
.header-links i {
  color: var(--white-color);
  margin: 0 10px 0 0;
  transition: all 0.3s ease-in-out;
}

.header-social {
  font-size: 14px;
}
.header-social a {
  display: inline-block;
  color: var(--white-color);
  margin-left: 16px;
}
@media (max-width: 767px) {
  .header-social a:first-child {
    margin-left: 0;
  }
}
.header-social a:hover {
  color: var(--theme-color);
}

.logo-style1 {
  background: linear-gradient(278.46deg, #00bcfa 0.91%, #0e59f2 71.89%, #0e59f2 100%);
  padding: 30px 35px 30px 35px;
  border-radius: 5px 0px 0px 5px;
  line-height: 0;
  margin: -1px 0;
}

.header-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header-btns.v3 {
  gap: 20px;
}

.header-box {
  border: 10px solid var(--theme-color);
  background-color: var(--white-color);
  padding: 20px;
}
.header-box__icon {
  border-radius: 5px;
  margin: 0 15px 0 0;
  font-size: 18px;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
}
.header-box__title {
  display: inline-block;
  color: var(--title-color);
  font-weight: 600;
  font-family: var(--title-font);
}
.header-box__number {
  display: block;
  width: fit-content;
  font-family: var(--title-font);
  font-size: 22px;
  font-weight: 700;
  color: var(--theme-color);
  margin: 15px 0 0 0;
}

.header-dropdown > .dropdown-toggle {
  color: var(--title-color);
}
.header-dropdown > .dropdown-toggle i {
  margin: 0 10px 0 0;
}
.header-dropdown > .dropdown-toggle:after {
  content: "\f107";
  font-family: var(--icon-font);
  border: none;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.header-dropdown > .dropdown-toggle:hover {
  color: var(--theme-color);
}
.header-dropdown ul.dropdown-menu {
  padding: 10px 15px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
  margin: 0;
  margin-left: -15px !important;
  margin-top: 15px !important;
  width: max-content;
  min-width: auto;
  position: relative;
  z-index: 1;
  border: none;
}
.header-dropdown ul.dropdown-menu li {
  margin-bottom: 10px;
}
.header-dropdown ul.dropdown-menu li:last-child {
  margin-bottom: 0;
}
.header-dropdown ul.dropdown-menu a {
  display: block;
  margin: 0 0;
  width: max-content;
  padding: 2px 10px;
  color: var(--title-color);
  font-weight: 400;
  font-size: 14px;
}
.header-dropdown ul.dropdown-menu a:hover {
  color: var(--theme-color);
}
.header-dropdown ul.dropdown-menu a:first-child {
  margin-top: 0;
  padding-top: 0;
}
.header-dropdown ul.dropdown-menu a:last-of-type, .header-dropdown ul.dropdown-menu a:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.header-dropdown ul.dropdown-menu:before {
  content: "";
  position: absolute;
  left: 47px;
  top: -7px;
  width: 14px;
  height: 14px;
  background-color: var(--white-color);
  z-index: -1;
  transform: rotate(45deg);
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
}
.header-dropdown.style-white > .dropdown-toggle {
  color: var(--white-color);
}
.header-dropdown.style-white > .dropdown-toggle:hover {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.7);
}

.login-tab {
  display: flex;
  border-radius: 9999px;
  position: relative;
  z-index: 1;
}
.login-tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  border: 2px solid var(--theme-color);
  z-index: -1;
}
.login-tab a {
  display: inline-block;
  color: var(--theme-color);
  background-color: transparent;
  font-family: var(--title-font);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding: 18.5px 37px;
  line-height: 1;
  border-radius: inherit;
}
.login-tab a:hover, .login-tab a.active {
  color: var(--white-color);
}
.login-tab .indicator {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  background-color: var(--theme-color);
  z-index: -1;
  transition: all ease 0.4s;
  border-radius: inherit;
}

.header-top {
  background-color: var(--sec-color);
  padding: 17px 0 46px;
  min-height: 90px;
}
@media (max-width: 991px) {
  .header-top {
    padding: 17px 0 55px;
  }
}
.header-top.v2 {
  padding: 17px 0;
  min-height: auto;
  background-color: var(--theme-color);
}
.header-top.v2 .header-links a:hover {
  color: var(--sec-color);
}
.header-top.v2 .header-links a:hover i {
  color: var(--sec-color);
}
.header-top.v2 .header-social a:hover {
  color: var(--sec-color);
}
.header-top.v3 {
  min-height: 115px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 991px) {
  .header-right {
    justify-content: center;
    flex-wrap: wrap;
  }
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .logo-style1 {
    margin-bottom: -1px;
  }
}
/* Extra large devices */
@media (max-width: 1500px) {
  .header-layout6 {
    position: relative;
  }
  .header-layout6 .shape3,
  .header-layout6 .shape2,
  .header-layout6 .shape1 {
    display: none;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .header-layout3 .main-menu > ul > li {
    margin: 0 13px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .header-search {
    width: 200px;
  }
  .main-menu > ul > li {
    margin: 0 0px;
  }
  .header-layout2 {
    margin: 0;
  }
  .header-layout2 .header-shape {
    height: 100%;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .header-links li {
    padding-right: 15px;
    margin-right: 10px;
  }
  .header-logo {
    padding: 15px 0;
  }
  .logo-style1 {
    padding: 20px 20px 20px 20px;
  }
  .header-layout1 .vs-menu-toggle {
    margin: 10px 0;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .header-info:nth-child(2) {
    padding-left: 10px;
  }
  .header-info_label {
    font-size: 12px;
  }
  .header-info_link {
    font-size: 14px;
  }
  .header-info_icon {
    font-size: 20px;
    margin-right: 10px;
  }
}
/*------------------- 4.3. Footer  -------------------*/
.widget-area {
  padding-top: 100px;
  padding-bottom: 70px;
}
@media (max-width: 991px) {
  .widget-area {
    padding: 80px 0 40px 0;
  }
}

.copyright-wrap {
  text-align: center;
  padding: 29px 0;
  background-color: #0b2048;
}

.copyright-text {
  margin: 0;
  color: var(--white-color);
}
.copyright-text a {
  position: relative;
  color: var(--white-color);
}
.copyright-text a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: 0px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.copyright-text a:hover {
  color: var(--white-color);
}
.copyright-text a:hover::after {
  width: 100%;
  left: 0;
}

.footer-layout1,
.footer-layout2 {
  background-color: var(--black-color);
}
.footer-layout1 .copyright-wrap,
.footer-layout2 .copyright-wrap {
  text-align: center;
  padding: 33px 0;
  background-color: transparent;
}
.footer-layout1 .footer-menu ul,
.footer-layout2 .footer-menu ul {
  margin-bottom: 0;
  padding-left: 0;
}
.footer-layout1 .footer-menu .menu-item,
.footer-layout2 .footer-menu .menu-item {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-left: 15px;
}
.footer-layout1 .footer-menu .menu-item::before,
.footer-layout2 .footer-menu .menu-item::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: var(--white-color);
}
.footer-layout1 .footer-menu .menu-item:first-child,
.footer-layout2 .footer-menu .menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.footer-layout1 .footer-menu .menu-item:first-child::before,
.footer-layout2 .footer-menu .menu-item:first-child::before {
  display: none;
}
.footer-layout1 .footer-menu .menu-item:last-child,
.footer-layout2 .footer-menu .menu-item:last-child {
  margin-right: 0;
}
.footer-layout1 .footer-menu .menu-item a,
.footer-layout2 .footer-menu .menu-item a {
  color: var(--white-color);
  line-height: 1;
  position: relative;
}
.footer-layout1 .footer-menu .menu-item a::after,
.footer-layout2 .footer-menu .menu-item a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.footer-layout1 .footer-menu .menu-item a:hover,
.footer-layout2 .footer-menu .menu-item a:hover {
  color: var(--white-color);
}
.footer-layout1 .footer-menu .menu-item a:hover::after,
.footer-layout2 .footer-menu .menu-item a:hover::after {
  width: 100%;
  left: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-layout2 .footer-top,
  .footer-layout1 .footer-top {
    padding: 50px 0;
  }
  .footer-layout2 .footer-top .row,
  .footer-layout1 .footer-top .row {
    --bs-gutter-y: 40px;
  }
  .footer-layout5 .widget_title {
    margin-bottom: 30px;
  }
  .footer-layout4 .shape1 {
    max-width: 230px;
  }
}
/*------------------- 4.4. Breadcumb  -------------------*/
.breadcumb-menu {
  max-width: 100%;
  margin: -0.35em 0 -0.35em 0;
  padding: 0;
  list-style-type: none;
}
.breadcumb-menu li {
  display: inline-block;
  list-style: none;
  position: relative;
}
.breadcumb-menu li:after {
  content: "|";
  position: relative;
  margin: 0 17px 0 20px;
}
@media (max-width: 767px) {
  .breadcumb-menu li:after {
    margin: 0 8px 0 10px;
  }
}
.breadcumb-menu li:last-child:after {
  display: none;
}
.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  word-break: break-word;
  font-weight: 700;
  font-size: 24px;
  font-family: var(--title-font);
  color: var(--white-color);
}
@media (max-width: 991px) {
  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 18px;
  }
}
.breadcumb-menu a {
  position: relative;
}
.breadcumb-menu a::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--white-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.breadcumb-menu a:hover::before {
  width: 100%;
  left: 0;
}

.breadcumb-title {
  color: var(--white-color);
  margin: -0.3em 0 -0.35em 0;
  max-width: 60%;
  width: 100%;
}
@media (max-width: 1199px) {
  .breadcumb-title {
    max-width: 50%;
  }
}

.breadcumb-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px 40px;
  flex-wrap: wrap;
}

.breadcumb-wrapper {
  padding: 223px 0;
  background-color: var(--title-color);
  position: relative;
  overflow: hidden;
  /* Medium devices */
}
.breadcumb-wrapper .shape {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}
@media (max-width: 991px) {
  .breadcumb-wrapper .shape {
    display: none;
  }
}
.breadcumb-wrapper .overlay {
  background-color: var(--title-color);
  opacity: 0.6;
}
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-title {
    max-width: 100%;
  }
  .breadcumb-menu {
    margin-top: 15px;
  }
}
.offer-counter {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  padding-left: 0;
  margin-bottom: 0;
  gap: 10px;
  flex-wrap: wrap;
}
.offer-counter li {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(116, 9, 246, 0.07);
  border-radius: 10px;
  min-width: 90px;
}
.offer-counter .count-name {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-color);
  padding: 6px 0;
  border-top: 2px solid var(--smoke-color);
  line-height: 1;
}
.offer-counter .count-number {
  font-size: 30px;
  font-weight: 700;
  padding: 29px 0;
}

/*------------------- 4.5. Pagination  -------------------*/
.vs-pagination {
  margin-bottom: 30px;
}
.vs-pagination ul {
  margin: 0;
  padding: 0;
}
.vs-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}
.vs-pagination li:last-child {
  margin-right: 0;
}
.vs-pagination li:first-child {
  margin-left: 0;
}
.vs-pagination span,
.vs-pagination a {
  display: inline-block;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: center;
  position: relative;
  border: none;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-weight: 400;
  font-size: 20px;
  z-index: 1;
  background-color: var(--white-color);
  border-radius: 5px;
}
.vs-pagination span.active, .vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  background-color: var(--sec-color);
  box-shadow: none;
}

.post-pagination {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding: 30px 0;
  margin: 30px 0 65px;
}

.post-pagi-box {
  display: flex;
  align-items: center;
}
.post-pagi-box > a {
  color: var(--title-color);
  font-weight: 600;
  font-size: 16px;
  font-family: var(--title-font);
}
.post-pagi-box > a:hover {
  color: var(--theme-color);
}
.post-pagi-box img {
  width: 80px;
  margin-right: 25px;
  border-radius: 10px;
}
.post-pagi-box.next {
  flex-direction: row-reverse;
}
.post-pagi-box.next img {
  margin-right: 0;
  margin-left: 25px;
}

.pagi-icon {
  color: var(--theme-color);
  font-size: 40px;
}

/* Small devices */
@media (max-width: 767px) {
  .vs-pagination span,
  .vs-pagination a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding: 0 10px;
  }
  .post-pagi-box > a {
    font-size: 12px;
  }
  .post-pagi-box img {
    width: 40px;
    margin-right: 15px;
  }
  .post-pagi-box.next img {
    margin-right: 0;
    margin-left: 15px;
  }
}
.vs-pagination {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.vs-pagination ul {
  margin: 0;
  list-style: none;
  background-color: var(--smoke-color);
  padding: 8px;
  border-radius: 30px;
}

.vs-pagination span,
.vs-pagination a {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  border: none;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  width: 44px;
  height: 44px;
  line-height: 30px;
  z-index: 1;
  text-transform: capitalize;
  background-color: var(--white-color);
  color: var(--title-color);
  justify-content: center;
  border-radius: 50%;
}

.vs-pagination span:before,
.vs-pagination a:before {
  content: "";
  position: absolute;
  left: 3px;
  right: 3px;
  bottom: 3px;
  top: 3px;
  background-color: var(--vs-secondary-color);
  border-radius: inherit;
  transition: all ease 0.4s;
  transform: scale(0.6);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.vs-pagination span.active,
.vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  border-color: rgba(0, 0, 0, 0);
}

.vs-pagination span.active:before,
.vs-pagination span:hover:before,
.vs-pagination a.active:before,
.vs-pagination a:hover:before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.vs-pagination li {
  display: inline-block;
  margin: 0 2.5px;
  list-style-type: none;
}

.vs-pagination .pagi-btn {
  width: auto;
  height: auto;
  line-height: 1;
  padding: 22px 30px;
  border-radius: 30px;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-transform: capitalize;
  display: inline-flex;
  gap: 5px;
  font-size: 16px;
  font-weight: 700;
}
.vs-pagination .pagi-btn:hover {
  background-color: var(--sec-color);
}

.vs-pagination .pagi-btn:before {
  background-color: var(--sec-color);
}

@media (max-width: 767px) {
  .vs-pagination {
    gap: 5px;
  }
  .vs-pagination ul {
    padding: 3px 3px;
  }
  .vs-pagination span,
  .vs-pagination a {
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .vs-pagination li {
    margin: 0 0;
  }
  .vs-pagination .pagi-btn {
    padding: 11px 15px;
    font-size: 12px;
  }
}
/*------------------- 4.6. Blog  -------------------*/
blockquote {
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  background-color: var(--quote-bg, #eff1f5);
  border-left: 4px solid var(--theme-color);
  padding: 43px 50px 42px 35px;
  margin: 35px 0;
}
blockquote p {
  font-family: inherit;
  color: inherit;
  z-index: 3;
  width: 100%;
  margin-bottom: 0 !important;
  line-height: 1.5;
  position: relative;
}
blockquote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  right: 40px;
  bottom: 30px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
  color: var(--theme-color);
  opacity: 0.3;
}
blockquote p {
  margin-bottom: 0;
}
blockquote p a {
  color: inherit;
}
blockquote cite {
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  line-height: 1;
  margin-top: 20px;
  font-style: normal;
}
blockquote cite:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 30px;
  height: 2px;
  border-top: 2px solid var(--theme-color);
}

blockquote.vs-quote {
  text-align: left;
  padding: 50px 60px;
  border-left: 0px solid var(--theme-color);
  border-radius: 30px;
}
blockquote.vs-quote::after {
  position: absolute;
  content: "";
  height: 160px;
  width: 4px;
  background-color: var(--theme-color);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
blockquote.vs-quote:before {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: var(--theme-color);
  font-size: 110px;
  display: inline-block;
  opacity: 0.2;
}
blockquote.vs-quote cite {
  padding: 0;
  margin-top: 15px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
blockquote.vs-quote cite::before {
  position: static;
}
blockquote.vs-quote .quote-author {
  display: block;
  font-size: 14px;
  color: #74787c;
  font-family: var(--title-font);
  display: block;
  font-weight: 400;
}

.blog-meta span,
.blog-meta a {
  display: inline-block;
  margin-right: 16px;
  font-size: 16px;
  color: #8b919c;
}
.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
}
.blog-meta span i,
.blog-meta a i {
  margin-right: 10px;
  color: var(--theme-color);
}
.blog-meta a:hover {
  color: var(--theme-color);
}

.blog-category {
  margin-bottom: -10px;
}
.blog-category a {
  display: inline-block;
  color: var(--white-color);
  padding: 4.5px 24.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  background-color: var(--theme-color);
}
.blog-category a:hover {
  background-color: var(--white-color);
  color: var(--body-color);
  border-color: var(--theme-color);
}

.blog-title a {
  color: inherit;
}
.blog-title a:hover {
  color: var(--theme-color);
}

.vs-blog {
  margin-bottom: 30px;
}

.blog-inner-title {
  font-size: 30px;
  margin-top: -0.2em;
  margin-bottom: 8px;
}

.blog-single {
  position: relative;
  margin-bottom: 0px;
  background: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  padding: 0.1px 0 0.1px 0;
}
.blog-single .blog-meta {
  margin: -0.2em 0 10px 0;
}
.blog-single .blog-title {
  font-size: 30px;
  margin-bottom: 17px;
}
.blog-single .link-btn {
  width: fit-content;
  display: block;
  margin-top: 26px;
}
.blog-single .blog-date {
  color: var(--body-color);
}
.blog-single .blog-date i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}
.blog-single .share-links-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0 15px 0 0;
  display: inline-block;
}
.blog-single .share-links {
  margin: 16px 0 0 0;
}
.blog-single .share-links .row {
  align-items: center;
  --bs-gutter-y: 15px;
}
.blog-single .share-links .tagcloud {
  display: inline-block;
}
.blog-single .share-links .tagcloud a {
  padding: 0;
  border-radius: 0;
  color: var(--body-color);
}
.blog-single .share-links .tagcloud a:not(:last-child):after {
  content: ",";
}
.blog-single .social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-block;
}
.blog-single .social-links li {
  display: inline-block;
  margin-right: 13px;
}
.blog-single .social-links li:last-child {
  margin-right: 0;
}
.blog-single .social-links a {
  line-height: 1;
  font-size: 16px;
  color: var(--body-color);
  text-align: center;
  display: block;
}
.blog-single .social-links a:hover {
  color: var(--theme-color);
}
.blog-single .blog-content {
  margin: var(--blog-space-y, 40px) var(--blog-space-x, 40px) var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  padding: 0;
  position: relative;
  border-radius: 0 0 5px 5px;
}
.blog-single .blog-audio {
  line-height: 1;
}
.blog-single .blog-audio,
.blog-single .blog-img {
  position: relative;
  background-color: var(--smoke-color);
}
.blog-single .blog-audio img,
.blog-single .blog-audio > *,
.blog-single .blog-img img,
.blog-single .blog-img > * {
  border-radius: 5px 5px 0 0;
}
.blog-single .blog-img .slick-arrow {
  --pos-x: 30px;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 5px;
}
.blog-single .blog-img .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.blog-single .blog-img .play-btn {
  --icon-size: 80px;
  --icon-font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}
.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.blog-author {
  background-color: var(--smoke-color);
  padding: 30px;
  display: flex;
  align-items: center;
  margin: var(--blog-space-y, 60px) 0;
}
.blog-author .media-img {
  width: 189px;
  margin: 0 30px 0 0;
}
.blog-author .author-name {
  font-size: 24px;
  font-weight: 700;
  margin: -0.3em 0 0px 0;
}
.blog-author .author-degi {
  color: var(--theme-color);
}
.blog-author .author-text {
  margin: 0 0 -0.1em 0;
}

.blog-details .blog-single {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.blog-details .blog-img {
  margin: 0 0 40px 0;
}
.blog-details .blog-img img {
  border-radius: 0;
}
.blog-details .blog-content {
  margin: 0;
}

.vs-blog,
.blog-style1,
.blog-style2,
.blog-style3,
.blog-style4 {
  background-color: var(--white-color);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  overflow: hidden;
}
.vs-blog .blog-img,
.blog-style1 .blog-img,
.blog-style2 .blog-img,
.blog-style3 .blog-img,
.blog-style4 .blog-img {
  position: relative;
  height: 280px;
  width: 100%;
  overflow: hidden;
}
.vs-blog .blog-img__item,
.blog-style1 .blog-img__item,
.blog-style2 .blog-img__item,
.blog-style3 .blog-img__item,
.blog-style4 .blog-img__item {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: cover;
}
.vs-blog .blog-img__item:nth-child(1),
.blog-style1 .blog-img__item:nth-child(1),
.blog-style2 .blog-img__item:nth-child(1),
.blog-style3 .blog-img__item:nth-child(1),
.blog-style4 .blog-img__item:nth-child(1) {
  -webkit-transform: translatex(50%) scalex(2);
  transform: translatex(50%) scalex(2);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.vs-blog .blog-img a,
.blog-style1 .blog-img a,
.blog-style2 .blog-img a,
.blog-style3 .blog-img a,
.blog-style4 .blog-img a {
  display: inline-block;
}
.vs-blog:hover .blog-img__item:nth-child(1),
.blog-style1:hover .blog-img__item:nth-child(1),
.blog-style2:hover .blog-img__item:nth-child(1),
.blog-style3:hover .blog-img__item:nth-child(1),
.blog-style4:hover .blog-img__item:nth-child(1) {
  -webkit-transform: translatex(0) scalex(1);
  transform: translatex(0) scalex(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}
.vs-blog:hover .blog-img__item:nth-child(2),
.blog-style1:hover .blog-img__item:nth-child(2),
.blog-style2:hover .blog-img__item:nth-child(2),
.blog-style3:hover .blog-img__item:nth-child(2),
.blog-style4:hover .blog-img__item:nth-child(2) {
  -webkit-transform: translatex(-50%) scalex(2);
  transform: translatex(-50%) scalex(2);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.vs-blog .blog-content,
.blog-style1 .blog-content,
.blog-style2 .blog-content,
.blog-style3 .blog-content,
.blog-style4 .blog-content {
  padding: 0px 30px 30px 30px;
}
@media (max-width: 1399px) {
  .vs-blog .blog-content,
  .blog-style1 .blog-content,
  .blog-style2 .blog-content,
  .blog-style3 .blog-content,
  .blog-style4 .blog-content {
    padding: 0px 15px 15px 15px;
  }
}
.vs-blog .blog-metas,
.blog-style1 .blog-metas,
.blog-style2 .blog-metas,
.blog-style3 .blog-metas,
.blog-style4 .blog-metas {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 12px;
}
@media (max-width: 1199px) {
  .vs-blog .blog-metas,
  .blog-style1 .blog-metas,
  .blog-style2 .blog-metas,
  .blog-style3 .blog-metas,
  .blog-style4 .blog-metas {
    gap: 15px;
  }
}
.vs-blog .blog-metas ul,
.blog-style1 .blog-metas ul,
.blog-style2 .blog-metas ul,
.blog-style3 .blog-metas ul,
.blog-style4 .blog-metas ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  row-gap: 0;
}
.vs-blog .blog-metas ul li,
.blog-style1 .blog-metas ul li,
.blog-style2 .blog-metas ul li,
.blog-style3 .blog-metas ul li,
.blog-style4 .blog-metas ul li {
  display: inline-flex;
  align-items: center;
}
.vs-blog .blog-meta,
.blog-style1 .blog-meta,
.blog-style2 .blog-meta,
.blog-style3 .blog-meta,
.blog-style4 .blog-meta {
  color: var(--body-color);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}
.vs-blog .blog-meta:hover,
.blog-style1 .blog-meta:hover,
.blog-style2 .blog-meta:hover,
.blog-style3 .blog-meta:hover,
.blog-style4 .blog-meta:hover {
  color: var(--theme-color);
}
.vs-blog .blog-date,
.blog-style1 .blog-date,
.blog-style2 .blog-date,
.blog-style3 .blog-date,
.blog-style4 .blog-date {
  background-color: var(--smoke-color);
  display: inline-block;
  text-align: center;
  padding: 7px;
  border-radius: 0px 0px 10px 10px;
  font-size: 20px;
  font-weight: 700;
  color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-date span,
.blog-style1 .blog-date span,
.blog-style2 .blog-date span,
.blog-style3 .blog-date span,
.blog-style4 .blog-date span {
  display: block;
  font-weight: 400;
  font-size: 14px;
}
.vs-blog .blog-title,
.blog-style1 .blog-title,
.blog-style2 .blog-title,
.blog-style3 .blog-title,
.blog-style4 .blog-title {
  padding-right: 50px;
}
@media (max-width: 1399px) {
  .vs-blog .blog-title,
  .blog-style1 .blog-title,
  .blog-style2 .blog-title,
  .blog-style3 .blog-title,
  .blog-style4 .blog-title {
    padding-right: 0;
  }
}
.vs-blog .blog-title a,
.blog-style1 .blog-title a,
.blog-style2 .blog-title a,
.blog-style3 .blog-title a,
.blog-style4 .blog-title a {
  display: block;
}
.vs-blog .blog-link,
.blog-style1 .blog-link,
.blog-style2 .blog-link,
.blog-style3 .blog-link,
.blog-style4 .blog-link {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  color: var(--theme-color);
  padding: 8px 25px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-link::before,
.blog-style1 .blog-link::before,
.blog-style2 .blog-link::before,
.blog-style3 .blog-link::before,
.blog-style4 .blog-link::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  left: 0;
  background-color: var(--smoke-color);
  z-index: -1;
  top: 50%;
  transform: translateY(-50%) scale(1);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-link:hover,
.blog-style1 .blog-link:hover,
.blog-style2 .blog-link:hover,
.blog-style3 .blog-link:hover,
.blog-style4 .blog-link:hover {
  transition: all 0.3s ease-in-out;
  color: var(--white-color);
  border-radius: 10px;
}
.vs-blog .blog-link:hover::before,
.blog-style1 .blog-link:hover::before,
.blog-style2 .blog-link:hover::before,
.blog-style3 .blog-link:hover::before,
.blog-style4 .blog-link:hover::before {
  background-color: var(--theme-color);
  transform: translateY(-50%) scale(1.5);
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.vs-blog:hover .blog-date,
.blog-style1:hover .blog-date,
.blog-style2:hover .blog-date,
.blog-style3:hover .blog-date,
.blog-style4:hover .blog-date {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.vs-blog .blog-metas {
  gap: 60px;
}
@media (max-width: 767px) {
  .vs-blog .blog-metas {
    gap: 10px;
  }
}
.vs-blog .blog-metas ul {
  gap: 120px;
}
@media (max-width: 767px) {
  .vs-blog .blog-metas ul {
    gap: 10px;
  }
}
.vs-blog .blog-metas .blog-meta {
  font-size: 20px;
}
@media (max-width: 767px) {
  .vs-blog .blog-metas .blog-meta {
    font-size: 16px;
  }
}
.vs-blog .blog-img {
  height: 580px;
}
@media (max-width: 1199px) {
  .vs-blog .blog-img {
    height: 440px;
  }
}
@media (max-width: 767px) {
  .vs-blog .blog-img {
    height: 280px;
  }
}
.vs-blog .blog-title {
  font-size: 40px;
}
@media (max-width: 1199px) {
  .vs-blog .blog-title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .vs-blog .blog-title {
    font-size: 20px;
  }
}
.vs-blog.blog-single {
  box-shadow: none;
}
.vs-blog.blog-single .blog-title {
  padding-right: 0;
  margin: -0.4em 0 15px 0;
}
.vs-blog.blog-single .blog-content {
  margin: 0;
  padding: 0;
}
.vs-blog.blog-single .blog-img {
  margin-bottom: 50px;
}
.vs-blog.blog-single .blog-img .blog-date {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 1;
  border-radius: 20px;
  background-color: var(--theme-color);
  min-width: 80px;
  height: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
}
.vs-blog.blog-single .blog-img__img {
  border-radius: 30px;
}
.vs-blog.blog-single .blog-meta {
  margin-bottom: 20px;
  gap: 0;
}
.vs-blog.blog-single .blog-meta a {
  color: var(--body-color);
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}
.vs-blog.blog-single .blog-meta a::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  background-color: var(--title-color);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.vs-blog.blog-single .blog-meta a:last-child {
  padding-right: 0;
  margin-right: 0;
}
.vs-blog.blog-single .blog-meta a:last-child::after {
  display: none;
}
.vs-blog.blog-single .blog-inner-img {
  border-radius: 20px;
}

.vs-blog.blog-style1 .blog-metas, .vs-blog.blog-style2 .blog-metas, .vs-blog.blog-style3 .blog-metas, .vs-blog.blog-style4 .blog-metas {
  gap: 30px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style1 .blog-metas, .vs-blog.blog-style2 .blog-metas, .vs-blog.blog-style3 .blog-metas, .vs-blog.blog-style4 .blog-metas {
    gap: 10px;
  }
}
@media (max-width: 1199px) {
  .vs-blog.blog-style1 .blog-metas, .vs-blog.blog-style2 .blog-metas, .vs-blog.blog-style3 .blog-metas, .vs-blog.blog-style4 .blog-metas {
    gap: 5px;
  }
}
.vs-blog.blog-style1 .blog-metas ul, .vs-blog.blog-style2 .blog-metas ul, .vs-blog.blog-style3 .blog-metas ul, .vs-blog.blog-style4 .blog-metas ul {
  gap: 30px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style1 .blog-metas ul, .vs-blog.blog-style2 .blog-metas ul, .vs-blog.blog-style3 .blog-metas ul, .vs-blog.blog-style4 .blog-metas ul {
    gap: 10px;
  }
}
@media (max-width: 1199px) {
  .vs-blog.blog-style1 .blog-metas ul, .vs-blog.blog-style2 .blog-metas ul, .vs-blog.blog-style3 .blog-metas ul, .vs-blog.blog-style4 .blog-metas ul {
    gap: 5px;
  }
}
.vs-blog.blog-style1 .blog-meta, .vs-blog.blog-style2 .blog-meta, .vs-blog.blog-style3 .blog-meta, .vs-blog.blog-style4 .blog-meta {
  font-size: 16px;
}
@media (max-width: 1199px) {
  .vs-blog.blog-style1 .blog-meta, .vs-blog.blog-style2 .blog-meta, .vs-blog.blog-style3 .blog-meta, .vs-blog.blog-style4 .blog-meta {
    font-size: 14px;
    gap: 5px;
  }
}
.vs-blog.blog-style1 .blog-title, .vs-blog.blog-style2 .blog-title, .vs-blog.blog-style3 .blog-title, .vs-blog.blog-style4 .blog-title {
  font-size: 24px;
}
@media (max-width: 1199px) {
  .vs-blog.blog-style1 .blog-title, .vs-blog.blog-style2 .blog-title, .vs-blog.blog-style3 .blog-title, .vs-blog.blog-style4 .blog-title {
    font-size: 20px;
  }
}
.vs-blog.blog-style1 .blog-img, .vs-blog.blog-style2 .blog-img {
  height: 280px;
}
.vs-blog.blog-style1 .blog-img__item:nth-child(2) {
  width: 100%;
}
.vs-blog.blog-style2 .blog-content {
  padding: 25px 30px 30px 30px;
}
.vs-blog.blog-style2 .blog-date {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 10px 0px 0px 0px;
  padding: 7px 20px;
}
.vs-blog.blog-style2 .blog-metas {
  padding: 0 0;
}
.vs-blog.blog-style3, .vs-blog.blog-style4 {
  border-radius: 30px;
}
.vs-blog.blog-style3 .blog-img, .vs-blog.blog-style4 .blog-img {
  height: 423px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style3 .blog-img, .vs-blog.blog-style4 .blog-img {
    height: 356px;
  }
}
.vs-blog.blog-style3 .blog-content, .vs-blog.blog-style4 .blog-content {
  padding: 35px 40px 40px 40px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style3 .blog-content, .vs-blog.blog-style4 .blog-content {
    padding: 41px 30px;
  }
}
@media (max-width: 767px) {
  .vs-blog.blog-style3 .blog-content, .vs-blog.blog-style4 .blog-content {
    padding: 20px 15px;
  }
}
.vs-blog.blog-style3 .blog-title, .vs-blog.blog-style4 .blog-title {
  padding-right: 0;
  font-size: 30px;
  margin-bottom: 7px;
}
.vs-blog.blog-style3 .blog-date, .vs-blog.blog-style4 .blog-date {
  position: absolute;
  right: 30px;
  bottom: 30px;
  border-radius: 0;
  padding: 10px;
  background-color: var(--theme-color);
  font-size: 14px;
  color: var(--white-color);
  border-radius: 6px;
}
.vs-blog.blog-style3 .blog-date span, .vs-blog.blog-style4 .blog-date span {
  font-size: 30px;
  display: block;
  font-weight: 700;
}
.vs-blog.blog-style3 .vs-btn, .vs-blog.blog-style4 .vs-btn {
  padding: 14px 30px;
}
.vs-blog.blog-style3 .blog-meta i, .vs-blog.blog-style4 .blog-meta i {
  color: var(--theme-color);
}
.vs-blog.blog-style3 .blog-meta:hover, .vs-blog.blog-style4 .blog-meta:hover {
  color: var(--theme-color);
}
.vs-blog.blog-style3 .blog-divider, .vs-blog.blog-style4 .blog-divider {
  margin: 24px 0 30px;
  background-color: var(--border-color);
}
.vs-blog.blog-style3 {
  min-height: 810px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style3 {
    min-height: 750px;
  }
}
@media (max-width: 1199px) {
  .vs-blog.blog-style3 {
    min-height: 727px;
  }
}
.vs-blog.blog-style3 .blog-img {
  height: 423px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style3 .blog-img {
    height: 356px;
  }
}
@media (max-width: 1199px) {
  .vs-blog.blog-style3 .blog-img {
    height: 300px;
  }
}
@media (max-width: 991px) {
  .vs-blog.blog-style3 .blog-img {
    height: 423px;
  }
}
@media (max-width: 767px) {
  .vs-blog.blog-style3 .blog-img {
    height: 356px;
  }
}
@media (max-width: 991px) {
  .vs-blog.blog-style3 .blog-img__item:nth-child(2) {
    width: 100%;
  }
}
.vs-blog.blog-style4 {
  display: flex;
  border-radius: 20px;
  padding: 30px;
  gap: 30px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style4 {
    padding: 20px;
    gap: 15px;
  }
}
@media (max-width: 767px) {
  .vs-blog.blog-style4 {
    padding: 15px;
    gap: 10px;
  }
}
.vs-blog.blog-style4 .blog-metas {
  margin-bottom: 9px;
}
.vs-blog.blog-style4 .blog-img {
  height: 190px;
  max-width: 220px;
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .vs-blog.blog-style4 .blog-img {
    height: 130px;
    max-width: 130px;
    min-width: 130px;
  }
}
@media (max-width: 991px) {
  .vs-blog.blog-style4 .blog-img {
    height: 220px;
    max-width: 220px;
    min-width: 220px;
  }
}
@media (max-width: 767px) {
  .vs-blog.blog-style4 .blog-img {
    height: 100px;
    max-width: 100px;
    min-width: 100px;
  }
}
.vs-blog.blog-style4 .blog-img__item {
  width: 100%;
}
.vs-blog.blog-style4 .blog-content {
  padding: 0;
}
.vs-blog.blog-style4 .blog-title {
  font-size: 24px;
  line-height: 34px;
}
@media (max-width: 1399px) {
  .vs-blog.blog-style4 .blog-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .vs-blog.blog-style4 .blog-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .vs-blog.blog-style4 .blog-title {
    font-size: 16px;
    line-height: 26px;
  }
}
.vs-blog.blog-style4 .blog-divider {
  margin: 17px 0 25px;
}
@media (max-width: 991px) {
  .vs-blog.blog-style4 .blog-divider {
    margin: 12px 0 17px;
  }
}

@media (min-width: 1199px) {
  .blog-style4-slider {
    max-width: 775px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  blockquote {
    font-size: 20px;
    padding: 23px 30px 26px 30px;
  }
  blockquote cite {
    font-size: 16px;
  }
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 30px;
  }
  .blog-single .blog-title {
    font-size: 24px;
  }
  .blog-meta span,
  .blog-meta a {
    font-size: 14px;
  }
  .blog-style3 .blog-content {
    padding: 25px 25px 30px 25px;
  }
  .blog-style4 {
    padding: 10px;
  }
  .blog-style4 .blog-content {
    padding: 0 15px 20px 15px;
  }
  .blog-style4 .blog-title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  blockquote.vs-quote {
    padding: 40px 15px;
  }
  blockquote.vs-quote p {
    line-height: 1.8;
  }
  blockquote.vs-quote cite {
    padding: 0;
    margin-top: 10px;
  }
  .blog-inner-title {
    font-size: 26px;
  }
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }
  .blog-single .blog-title {
    font-size: 20px;
  }
  .blog-single .blog-img .play-btn {
    --icon-size: 60px;
    --icon-font-size: 20px;
  }
  .blog-author {
    display: block;
    padding: 30px 15px;
  }
  .blog-author .media-img {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .blog-author .media-img img {
    width: 100%;
  }
  .blog-author .author-name {
    font-size: 18px;
  }
  .blog-author .author-degi {
    margin-bottom: 10px;
  }
}
/*------------------- 4.7. Comments  -------------------*/
.comment-respond {
  margin: var(--blog-space-y, 60px) 0 30px 0;
  position: relative;
}
.comment-respond .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}
.comment-respond .custom-checkbox.notice {
  margin-top: 10px;
  margin-bottom: 25px;
}
.comment-respond .row {
  --bs-gutter-x: 20px;
}
.comment-respond .form-control {
  background-color: var(--white-color);
  border: none;
  height: 60px;
  border-radius: 30px;
}
.comment-respond .form-group > i {
  top: 20.5px;
  color: var(--body-color);
}
.comment-respond input[type=checkbox] ~ label:before {
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  border-radius: 3px;
}
.comment-respond input[type=checkbox]:checked ~ label:before {
  background-color: var(--theme-color);
  border-color: transparent;
}
.comment-respond .blog-inner-title {
  margin-bottom: 20px;
}
.comment-respond .form-text {
  margin-bottom: 25px;
}
.comment-respond .form-inner {
  background-color: var(--smoke-color);
  padding: 30px;
  border-radius: 30px;
}

.question-form .comment-respond,
.review-form .comment-respond {
  margin: 0 0 20px 0;
}
.question-form .form-control,
.review-form .form-control {
  background-color: transparent;
  border: 1px solid #e4e4e4;
}
.question-form .rating-select,
.review-form .rating-select {
  display: flex;
  align-items: center;
}
.question-form .rating-select label,
.review-form .rating-select label {
  font-weight: 500;
  color: var(--title-color);
}

.review-form {
  display: none;
}

.vs-comments-wrap {
  margin: var(--blog-space-y, 60px) 0 var(--blog-space-y, 60px) 0;
}
.vs-comments-wrap .description p:last-child {
  margin-bottom: 0;
}
.vs-comments-wrap .comment-respond {
  margin: 30px 0;
}
.vs-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.vs-comments-wrap blockquote {
  background-color: #eaf8f9;
}
.vs-comments-wrap li {
  margin: 0;
}
.vs-comments-wrap .vs-post-comment {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  display: flex;
  position: relative;
  padding: 0 0 30px 0;
  margin: 30px 0 30px 0;
}
.vs-comments-wrap ul.comment-list {
  list-style: none;
  margin: -10px 0 0 0;
  padding: 0;
}
.vs-comments-wrap ul.comment-list ul ul,
.vs-comments-wrap ul.comment-list ul ol,
.vs-comments-wrap ul.comment-list ol ul,
.vs-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}
.vs-comments-wrap .comment-avater {
  width: 110px;
  height: 110px;
  margin-right: 25px;
  overflow: hidden;
  background-color: var(--white-color);
}
.vs-comments-wrap .comment-avater img {
  width: 100%;
  border-radius: 10px;
}
.vs-comments-wrap .comment-content {
  flex: 1;
  position: relative;
}
.vs-comments-wrap .commented-on {
  font-size: 14px;
  display: block;
  margin-bottom: 3px;
  margin-top: -0.25em;
  font-weight: 400;
  color: var(--body-color);
  font-family: var(--title-font);
}
.vs-comments-wrap .commented-on i {
  margin-right: 7px;
  font-size: 0.9rem;
}
.vs-comments-wrap .name {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 600;
}
.vs-comments-wrap .comment-top {
  display: flex;
  justify-content: space-between;
}
.vs-comments-wrap .text:last-of-type {
  margin-bottom: 0;
}
.vs-comments-wrap .children {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 70px;
}
.vs-comments-wrap .reply_and_edit {
  position: absolute;
  right: 0;
  top: 15px;
  line-height: 1;
}
.vs-comments-wrap .replay-btn {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  font-family: var(--title-font);
  color: var(--theme-color);
}
.vs-comments-wrap .replay-btn i {
  margin-right: 7px;
  font-size: 0.8em;
}
.vs-comments-wrap .replay-btn:hover {
  color: var(--title-color);
}
.vs-comments-wrap .star-rating {
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  top: 5px;
  right: 0;
  width: 80px;
}

.vs-comments-wrap.vs-comment-form {
  margin: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-comments-wrap .vs-post-comment {
    display: block;
  }
  .vs-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }
  .vs-comments-wrap .comment-top {
    display: block;
  }
  .vs-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .vs-comments-wrap .children {
    margin-left: 40px;
  }
  .vs-comments-wrap .reply_and_edit {
    position: relative;
    top: 0;
    margin: 15px 0 0 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .vs-comments-wrap .children {
    margin-left: 20px;
  }
  .vs-comments-wrap .name {
    font-size: 18px;
  }
  .comment-respond .form-inner {
    padding: 30px 15px;
  }
}
/*------------------- 4.8. Hero Area  -------------------*/
.hero-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 991px) {
  .hero-btns {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .hero-btns {
    gap: 5px;
  }
}

.hero-inner {
  position: relative;
  z-index: 1;
  min-height: 900px;
  padding-top: 260px;
  /* Large devices */
  /* Medium devices */
}
@media (max-width: 1199px) {
  .hero-inner {
    min-height: 760px;
    padding-top: 160px;
  }
}
@media (max-width: 991px) {
  .hero-inner {
    min-height: 680px;
  }
}
@media (max-width: 767px) {
  .hero-inner {
    padding-top: 190px;
  }
}
.hero-inner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 101px;
  background-color: var(--white-color);
  bottom: -1px;
  z-index: 1;
}
.hero-inner .overlay {
  background-color: var(--title-color);
  opacity: 0.9;
  z-index: -1;
}
.hero-inner.style2, .hero-inner.style3 {
  min-height: auto;
  padding-top: 175px;
  padding-bottom: 205px;
}
.hero-inner.style2 .hero-content, .hero-inner.style3 .hero-content {
  position: relative;
  z-index: 1;
  padding-top: 0;
}
.hero-inner.style2::before, .hero-inner.style3::before {
  display: none;
}
.hero-inner.style2 .overlay, .hero-inner.style3 .overlay {
  opacity: 0.6;
}
.hero-inner.style2 .hero-shape1, .hero-inner.style3 .hero-shape1 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.hero-inner.style3 {
  padding-top: 200px;
  padding-bottom: 270px;
}
@media (max-width: 1199px) {
  .hero-inner.style3 {
    padding-top: 160px;
    padding-bottom: 220px;
  }
}
.hero-inner.style3 .hero-content {
  padding: 80px 88px;
}
@media (max-width: 991px) {
  .hero-inner.style3 .hero-content {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .hero-inner.style3 .hero-content {
    padding: 15px;
  }
}

.hero-content {
  padding-top: 30px;
}
.hero-content.style2 .hero-text {
  margin: 0 auto 40px auto;
}
.hero-content.style3 {
  position: relative;
  z-index: 1;
}
.hero-content.style3 .hero-text {
  margin: 0 auto 40px auto;
  max-width: 90%;
}
@media (max-width: 767px) {
  .hero-content.style3 .hero-text {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .hero-content.style3 .hero-title {
    font-size: 30px;
  }
}
.hero-content.style3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--black-color);
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0.9;
  border-radius: 50px;
}
@media (max-width: 991px) {
  .hero-content.style3::before {
    border-radius: 30px;
  }
}
@media (max-width: 767px) {
  .hero-content.style3::before {
    border-radius: 10px;
  }
}
.hero-content.style3 .vs-btn::before, .hero-content.style3 .vs-btn::after {
  background-color: var(--white-color);
}
.hero-content.style3 .vs-btn:hover {
  color: var(--theme-color);
}
.hero-content.style3 .vs-btn.style3::before, .hero-content.style3 .vs-btn.style3::after {
  background-color: var(--theme-color);
}
.hero-content.style3 .vs-btn.style3:hover {
  color: var(--white-color);
}

.hero-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -2;
}

.hero-info {
  display: flex;
  align-items: center;
}
.hero-info .media-icon {
  margin-right: 25px;
}
.hero-info .media-label {
  font-size: 22px;
  font-family: var(--title-font);
  color: #7190ff;
  display: block;
  margin-bottom: 20px;
}
.hero-info .media-link {
  font-size: 36px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
}
.hero-info .media-link a {
  color: inherit;
}
.hero-info .media-link a:hover {
  color: var(--theme-color);
}

.vs-carousel.style1 {
  margin-top: -130px;
}

.hero-subtitle {
  display: inline-block;
  font-size: 20px;
  color: var(--white-color);
  padding: 10px 30px;
  border: 2px solid var(--white-color);
  border-radius: 6px;
  margin-bottom: 13px;
}
@media (max-width: 767px) {
  .hero-subtitle {
    font-size: 18px;
    padding: 10px 20px;
  }
}

.hero-title,
.hero-text {
  color: var(--white-color);
}

.hero-title {
  margin-bottom: 35px;
  line-height: 74px;
}
@media (max-width: 1199px) {
  .hero-title {
    margin-bottom: 25px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .hero-title {
    margin-bottom: 20px;
    line-height: 50px;
  }
}

.hero-text {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
  max-width: 93%;
}

@media (max-width: 991px) {
  .hero-content {
    margin-bottom: 30px;
    text-align: center;
  }
}

/*------------------- 4.9. Error  -------------------*/
.error-number {
  font-size: 274px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 3px;
  margin-top: -0.1em;
}

.error-title {
  font-size: 36px;
  margin-bottom: 8px;
}

.error-text {
  max-width: 600px;
  margin: 0 auto 32px auto;
}

/* Medium devices */
@media (max-width: 991px) {
  .error-number {
    font-size: 200px;
  }
  .error-title {
    font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .error-number {
    font-size: 110px;
  }
}
/*------------------- 4.10. Feature  -------------------*/
.feature-layout1 {
  background-color: var(--theme-color);
  position: relative;
  z-index: 1;
  padding: 120px 0 240px;
}
@media (max-width: 991px) {
  .feature-layout1 {
    padding: 80px 0 200px;
  }
}
.feature-layout1 .img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  mix-blend-mode: color-dodge;
}
.feature-layout1 .overlay {
  background-color: var(--black-color);
  opacity: 0.45;
  z-index: -1;
}
.feature-style1, .feature-style2 {
  background-color: var(--white-color);
  padding: 40px 30px 33px;
  border-radius: 10px;
  text-align: center;
}
.feature-style1 .feature-text, .feature-style2 .feature-text {
  margin-bottom: 0;
}
.feature-style1 .feature-icon, .feature-style2 .feature-icon {
  display: inline-flex;
  min-width: 80px;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
  position: relative;
  z-index: 1;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
}
.feature-style1 .feature-icon img, .feature-style2 .feature-icon img {
  filter: none;
  transition: all 0.3s ease-in-out;
}
.feature-style1 .feature-icon::before, .feature-style1 .feature-icon::after, .feature-style2 .feature-icon::before, .feature-style2 .feature-icon::after {
  position: absolute;
  content: "";
  z-index: -1;
}
.feature-style1 .feature-icon::before, .feature-style2 .feature-icon::before {
  display: none;
}
.feature-style1 .feature-icon::after, .feature-style2 .feature-icon::after {
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  background-color: var(--theme-color);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.3s ease-in-out;
}
.feature-style1:hover .feature-icon img, .feature-style2:hover .feature-icon img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(48deg) brightness(180%) contrast(100%);
  transition: all 0.3s ease-in-out;
}
.feature-style1:hover .feature-icon::before, .feature-style2:hover .feature-icon::before {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.5);
  transition: all 0.3s ease-in-out;
  background-color: var(--theme-color);
}
.feature-style1:hover .feature-icon::after, .feature-style2:hover .feature-icon::after {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}
.feature-style2 {
  border: 2px solid var(--sec-color);
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1;
  border-radius: 20px;
}
.feature-style2 .feature-icon {
  background-color: var(--theme-color);
}
.feature-style2 .feature-icon img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(48deg) brightness(180%) contrast(100%);
}
.feature-style2::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.feature-style2 .feature-title,
.feature-style2 .feature-text {
  transition: all 0.3s ease-in-out;
}
.feature-style2:hover {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
}
.feature-style2:hover::before {
  opacity: 1;
  visibility: visible;
}
.feature-style2:hover .feature-icon::after {
  transform: translate(-50%, -50%) scale(1);
  transform-origin: center;
  background-color: var(--white-color);
}
.feature-style2:hover .feature-icon img {
  filter: none;
}
.feature-style2:hover .feature-title,
.feature-style2:hover .feature-text {
  color: var(--white-color);
}

/*------------------- 4.11. About  -------------------*/
.img-box1 {
  position: relative;
}
.img-box1 .card-style1,
.img-box1 .card-style2 {
  position: absolute;
  right: 0;
}
.img-box1 .img-1 img {
  border-radius: 120px 20px 20px 20px;
}
.img-box1 .img-2 {
  z-index: 1;
  margin: -360px 0 0 auto;
  max-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  border-radius: 20px 20px 120px 20px;
  overflow: hidden;
  padding: 20px 0 0 21px;
  background: var(--white-color);
}
.img-box1 .img-2 img {
  border-radius: 20px 20px 120px 20px;
}

.img-3 img {
  border-radius: 20px;
}

.card-style1,
.card-style2 {
  max-width: 195px;
  background: var(--theme-color);
  border-radius: 20px;
  text-align: center;
  padding: 30px 20px;
}
@media (max-width: 767px) {
  .card-style1,
  .card-style2 {
    max-width: 150px;
    padding: 10px 10px;
  }
}
.card-style1 .number,
.card-style2 .number {
  width: 120px;
  height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border-radius: 50%;
  font-size: 64px;
  color: var(--white-color);
  font-weight: 700;
  margin-bottom: 10px;
}
.card-style1 .number span,
.card-style2 .number span {
  display: inline-block;
  line-height: 1;
}
.card-style1 .title,
.card-style2 .title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--white-color);
}

.card-style1 .number {
  color: var(--title-color);
}

.card-style2 {
  position: absolute;
  border-radius: 50%;
  min-width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  gap: 5px;
  padding: 24px;
}
@media (max-width: 991px) {
  .card-style2 {
    min-width: 150px;
    height: 150px;
    max-width: 150px;
  }
}
.card-style2 .number {
  width: auto;
  height: auto;
  background-color: transparent;
  margin-bottom: 0;
  font-size: 48px;
}
@media (max-width: 991px) {
  .card-style2 .number {
    font-size: 36px;
  }
}
.card-style2 .title {
  line-height: 30px;
  font-size: 20px;
}
@media (max-width: 991px) {
  .card-style2 .title {
    font-size: 16px;
    line-height: 23px;
  }
}

.exp-media {
  display: flex;
  margin: 0 0 15px 0;
}
.exp-media__total {
  display: inline-block;
  text-align: center;
  background-color: var(--white-color);
  color: var(--theme-color);
  font-family: var(--title-font);
  font-weight: 700;
  font-size: 36px;
  width: 80px;
  height: 100px;
  line-height: 100px;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(91, 118, 175, 0.15);
  margin: 0 20px 0 0;
}
.exp-media__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: var(--theme-color);
  margin: -0.1em 0 10px 0;
}

.list-style1,
.list-style3 {
  margin-bottom: 37px;
}
.list-style1 ul,
.list-style3 ul {
  padding: 25px 0 0 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 0;
}
.list-style1 li,
.list-style3 li {
  font-size: 16px;
  position: relative;
  color: var(--title-color);
  color: var(--black-color);
  margin: 0 0 12px 0;
  padding: 0 0 0 33px;
}
.list-style1 li i,
.list-style3 li i {
  font-weight: 600;
}
.list-style1 li:last-child,
.list-style3 li:last-child {
  margin-bottom: 0;
}
.list-style1 li > i:first-child,
.list-style3 li > i:first-child {
  position: absolute;
  left: 0;
  color: var(--theme-color);
  top: 6px;
}

.quote-media {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  border-top: 1px solid #e9ebf3;
  margin-top: 40px;
  padding-top: 40px;
}
.quote-media .media-icon {
  margin-right: 40px;
  position: relative;
}
.quote-media .media-icon::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 20px;
  width: 67px;
  height: 67px;
  background-color: var(--theme-color);
  opacity: 0.05;
  border-radius: 50%;
  z-index: -1;
}
.quote-media .media-title {
  font-weight: 400;
  font-size: 18px;
}

.award-box {
  background-color: #f4f7fc;
  position: relative;
  padding: 60px 60px 57px 60px;
  display: flex;
  border-radius: 5px;
}
.award-box > * {
  position: relative;
  z-index: 2;
}
.award-box__shape {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  background-color: var(--theme-color);
  box-shadow: 0px 4px 20px rgba(14, 89, 242, 0.33);
  border-radius: 5px;
  z-index: 1;
}
.award-box__icon {
  position: relative;
  color: var(--white-color);
  font-size: 42px;
  line-height: 1;
  margin: 0 20px 0 0;
}
.award-box__number {
  line-height: 1;
  margin: 0 0 8px 0;
  font-size: 40px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--white-color);
}
.award-box__text {
  font-weight: 700;
  font-size: 16px;
  font-family: var(--title-font);
  text-transform: uppercase;
  margin: 0;
  color: var(--white-color);
  line-height: 1;
}

.about-media {
  display: flex;
}
.about-media__icon {
  margin: 0 20px 0 0;
}

.call-media {
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 10px;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  width: fit-content;
  max-width: 100%;
  margin: 0 0 40px 0;
  background-color: var(--white-color);
}
.call-media__icon {
  background-color: var(--smoke-color);
  box-shadow: 0px 7px 37px rgba(96, 125, 185, 0.11);
  border-radius: 5px;
  width: 95px;
  height: 95px;
  line-height: 95px;
  border-radius: 5px;
  text-align: center;
  margin: 0 20px 0 0;
}
.call-media__label {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin: 0 0 12px 0;
}
.call-media__info {
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 700;
  line-height: 1;
  display: block;
  margin: 0;
}
.call-media__info a {
  color: inherit;
}
.call-media__info a:hover {
  color: var(--theme-color);
}

.media-order {
  display: flex;
  margin: 0 0 5px 0;
}
.media-order__number {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--title-font);
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  display: inline-block;
  margin: 0 30px 0 0;
  border-radius: 5px;
}
.media-order__title {
  margin: -0.25em 0 10px 0;
}

.progress-box {
  margin: 0 0 25px 0;
}
.progress-box__number, .progress-box__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--title-font);
  color: var(--title-color);
  display: inline-block;
  margin: 0 0 17px 0;
}
.progress-box__number {
  float: right;
  margin: 5px 0 0 0;
}
.progress-box__progress {
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border: 1px solid #d5e0f5;
  height: 16px;
  padding: 4px;
  border-radius: 5px;
  background-color: var(--white-color);
}
.progress-box__bar {
  height: 100%;
  background-color: var(--theme-color);
  border-radius: inherit;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
}

.about-wrap1 {
  border-top: 1px solid #e8ebf6;
  position: relative;
}

.about-shape1 {
  font-size: 150px;
  font-weight: 400;
  font-family: var(--title-font);
  line-height: 1;
  display: inline-block;
  writing-mode: tb-rl;
  text-transform: uppercase;
  transform: rotate(180deg);
  position: absolute;
  right: 95px;
  bottom: 120px;
  color: rgba(14, 89, 242, 0.07);
}

.contact-media {
  display: flex;
  align-items: center;
}
.contact-media__icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  text-align: center;
  font-size: 26px;
  border-radius: 7px;
  position: relative;
  z-index: 1;
}
.contact-media__label {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin: 0 0 9px 0;
}
.contact-media__info {
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 700;
  display: block;
  margin: 0 0 -0.25em 0;
  font-size: 15px;
  line-height: 24px;
}
.contact-media__info a {
  color: inherit;
}
.contact-media .media-body {
  background-color: var(--white-color);
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px 10px 50px;
  margin-left: -30px;
  border-radius: 5px;
}

.about-call {
  display: flex;
  align-items: center;
  border-right: 2px solid var(--theme-color);
  padding: 0 40px 0 0;
  margin: 0 40px 0 0;
}
.about-call__icon {
  background-color: var(--white-color);
  color: var(--theme-color);
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-right: 20px;
  font-size: 20px;
  border-radius: 50%;
}
.about-call__label {
  font-size: 14px;
  color: var(--black-color);
  display: block;
}
.about-call__number {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--theme-color);
}

.about-box1 .about-subtitle {
  font-weight: 500;
  line-height: 1;
  display: block;
  font-family: var(--title-font);
  color: var(--theme-color);
  display: flex;
  align-items: center;
  max-width: 280px;
  margin: 0 0 33px 0;
}
.about-box1 .about-subtitle:after {
  content: "";
  position: relative;
  right: 0;
  height: 1px;
  width: 100%;
  flex: 1;
  background-color: var(--theme-color);
  display: inline-block;
  margin: 0 0 0 10px;
}
.about-box1 .about-text {
  margin: 0 0 33px 0;
}
.about-box1 .about-title {
  margin-top: -0.3em;
}

.skill-about {
  margin-bottom: 22px;
}
.skill-about .skill-percentage {
  font-size: 60px;
  font-weight: 700;
  color: var(--title-color);
  line-height: 1;
  margin-bottom: 6px;
}
.skill-about .skill-title {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 27px;
}
.skill-about .progress {
  border-radius: 0;
  height: 10px;
  background-color: #363940;
  margin-bottom: 20px;
}
.skill-about .progress .progress-bar {
  background-color: var(--theme-color);
}
.skill-about .skill-text {
  margin-bottom: 0;
}

.img-box3 {
  position: relative;
  display: flex;
  gap: 30px;
}
.img-box3 .img1 img,
.img-box3 .img2 img {
  border-radius: 154px;
}
.img-box3 .img2 {
  margin-top: 153px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .about-shape1 {
    font-size: 80px;
    right: 50px;
    bottom: 70px;
  }
  .img-box2 .img-1 {
    margin-left: 0;
  }
  .img-box2 .img-2 {
    position: relative;
    width: fit-content;
    margin: -100px 30px 0 auto;
    padding: 10px;
    border-radius: 10px;
  }
  .img-box9 {
    margin-right: 0;
  }
  .about-box2 {
    max-width: 500px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .img-box8 .play-btn {
    left: 0;
    top: 0;
    position: absolute;
  }
  .img-box8 .play-btn:after, .img-box8 .play-btn:before {
    display: none;
  }
  .img-box8 .img-1 img {
    width: 100%;
  }
  .about-shape3 {
    max-width: 29%;
  }
  .play-btn.style5 i {
    --icon-size: 70px;
    --icon-font-size: 22px;
  }
  .about-box3 {
    max-width: 100%;
    padding-left: 0;
  }
  .list-style4 ul {
    padding: 30px 40px 36px 32px;
  }
  .list-style4 ul li {
    font-size: 16px;
  }
  .about-shape2 {
    max-width: 100%;
    max-height: 100%;
  }
  .about-wrap2 {
    padding-bottom: var(--section-space);
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .img-box3 {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
  .img-box9 .img-2:before {
    display: none;
  }
  .img-box4 .img-1 {
    margin: 0;
  }
  .img-box4 .img-2 {
    margin: -100px 0 0 auto;
    max-width: 70%;
  }
  .about-box3 .sec-title4 {
    margin-bottom: 30px;
  }
  .list-style2 li {
    padding: 14px 20px;
    font-size: 14px;
  }
  .list-style4 ul {
    background-color: transparent;
    padding: 0;
  }
  .list-style4 li {
    color: var(--title-color);
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .about-box2 {
    padding: 40px 0 var(--section-space-mobile, 80px) 0;
  }
  .about-wrap2 {
    padding-bottom: var(--section-space-mobile);
  }
  .img-box1 .img-1 img {
    width: 100%;
  }
  .img-box5 {
    width: 100%;
  }
  .img-box5 .img-1 img {
    width: 100%;
  }
  .img-box5 .img-bottom {
    justify-content: center;
  }
  .skill-about .skill-percentage {
    font-size: 48px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .img-box10 {
    padding-right: 0;
  }
  .img-box2 .img-2,
  .img-box1 .img-2 {
    margin: 20px 0 0 0;
    width: 100%;
  }
  .img-box2 .img-2 img,
  .img-box1 .img-2 img {
    width: 100%;
  }
  .img-box5 {
    padding: 0;
  }
  .img-box5 .shape1 {
    display: none;
  }
  .img-box9 {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .img-box9 img {
    width: 100%;
  }
  .img-box9 .img-1 {
    display: block;
    margin: 0 0 30px 0;
  }
  .img-box9 .img-3 {
    display: block;
    position: relative;
    left: 0;
    bottom: 0;
    margin: 30px 0 0 0;
  }
  .list-style2 ul {
    display: block;
  }
  .list-style2 ul li:not(:last-child) {
    margin: 0 0 20px 0;
  }
  .list-style5 ul {
    display: block;
  }
  .list-style5 ul li:not(:last-child) {
    margin-bottom: 20px;
  }
  .quote-media .media-icon {
    margin-right: 20px;
  }
  .media-order {
    display: block;
  }
  .media-order__number {
    margin: 0 0 20px 0;
  }
  .img-box3 img {
    width: 100%;
  }
  .img-box3 .img-2 {
    margin: 30px 0 0 0;
  }
  .call-media__icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: 0 10px 0 0;
  }
  .call-media__icon img {
    max-width: 25px;
  }
  .call-media__label {
    font-size: 12px;
  }
  .award-box {
    padding: 30px;
  }
  .award-box__shape {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .img-box3 .award-box {
    position: relative;
    bottom: 0;
    right: 0;
    margin: 30px 0 0 0;
    width: 100%;
    box-shadow: none;
  }
  .exp-media {
    margin-top: 30px;
  }
  .list-style1 ul {
    display: block;
    margin: 0 0 30px 0;
    padding: 30px 0 0 0;
  }
  .list-style4 ul li {
    font-size: 14px;
    padding-left: 35px;
    margin-bottom: 15px;
  }
  .list-style4 ul li i:first-child {
    width: 25px;
    height: 25px;
    line-height: 26px;
    font-size: 12px;
    top: -1px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .about-call {
    padding: 0;
    border-right: none;
    margin-top: 20px;
  }
}
.img-box2 {
  position: relative;
}
.img-box2 .img-1 img {
  border-radius: 200px 20px 20px 20px;
}
.img-box2 .img-2 img {
  border-radius: 20px 150px 20px 20px;
}
.img-box2 .img-3 {
  text-align: right;
}
.img-box2 .img-3 img {
  border-radius: 20px 20px 20px 150px;
}
.img-box2 .img-4 img {
  border-radius: 20px 20px 200px 20px;
}
.img-box2 .img-1,
.img-box2 .img-2,
.img-box2 .img-3,
.img-box2 .img-4 {
  margin-bottom: 30px;
}
.img-box2 .img-1,
.img-box2 .img-3 {
  min-height: 305px;
}

.list-style1.style2 ul {
  padding-top: 0;
}
.list-style1.style2 ul li i {
  font-weight: 600;
}

/*------------------- 4.12. Service  -------------------*/
.service-layout2 {
  padding: 218px 0 120px;
}
.service-style1 {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.service-style1 .overlay {
  background-color: var(--title-color);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(1.5);
}
.service-style1 .service-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white-color);
  margin: 30px;
  text-align: center;
  padding: 21px 15px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1399px) {
  .service-style1 .service-content {
    margin: 15px;
  }
}
.service-style1 .service-name {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}
.service-style1 .service-name a {
  color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.service-style1 .service-price {
  display: block;
  transition: all 0.3s ease-in-out;
}
.service-style1 .service-price span {
  font-weight: 700;
}
@media (max-width: 991px) {
  .service-style1 .service-img img {
    width: 100%;
  }
}
.service-style1 .service-plus {
  border-radius: 50%;
  background-color: var(--theme-color);
  color: var(--white-color);
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;
  left: 50%;
  transform: translateX(-50%) scale(0);
  top: 96px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.service-style1 .service-plus i {
  transition: all 0.3s ease-in-out;
  transform: rotate(360deg);
}
.service-style1 .service-plus:hover {
  background-color: var(--sec-color);
  color: var(--white-color);
}
.service-style1 .service-plus:hover i {
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
}
.service-style1:hover .overlay {
  opacity: 0.4;
  visibility: visible;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
.service-style1:hover .service-content {
  background-color: var(--theme-color);
}
.service-style1:hover .service-name a, .service-style1:hover .service-price {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.service-style1:hover .service-plus {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1);
}
.service-style1:hover .service-plus i {
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
}
.service-style2 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: var(--white-color);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 0px;
  overflow: hidden;
  margin-bottom: 30px;
  margin-top: 30px;
  background-position: center center;
  transition: all 0.3s ease-in-out;
  padding: 0px 0 30px;
}
.service-style2 .overlay {
  background-color: var(--white-color);
  z-index: -1;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.service-style2 .service-img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  overflow: hidden;
  border-radius: 50%;
  margin: 30px auto;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.service-style2 .vs-btn {
  display: inline-flex;
  align-items: center;
  padding: 12px 25px;
}
.service-style2 .service-name {
  margin-bottom: 0;
}
.service-style2 .service-name a {
  color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.service-style2 .service-price {
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.service-style2 .service-price span {
  font-weight: 700;
}
.service-style2 .service-content {
  margin-bottom: 20px;
}
.service-style2:hover {
  box-shadow: none;
}
.service-style2:hover .overlay {
  background-color: var(--black-color);
  opacity: 0.6;
}
.service-style2:hover .service-img {
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}
.service-style2:hover .service-name a {
  color: var(--white-color);
}
.service-style2:hover .service-price {
  color: var(--white-color);
}

.list-style3 {
  margin-bottom: 30px;
  max-width: 80%;
}
@media (max-width: 1199px) {
  .list-style3 {
    max-width: 100%;
  }
}
.list-style3 ul {
  display: block;
  columns: 2;
  padding-top: 0;
}
@media (max-width: 991px) {
  .list-style3 ul {
    columns: 1;
  }
}

.service-details-img {
  border-radius: 20px;
}

.service-details-img2 {
  border-radius: 30px;
}

/*------------------- 4.13. Counter  -------------------*/
.counter-media {
  display: flex;
  align-items: center;
}
.counter-media__icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  margin: 0 20px 0 0;
  background-color: var(--white-color);
  border-radius: 10px;
}
.counter-media__number {
  line-height: 1;
  margin: -0.04em 0 7px 0;
  display: block;
  font-size: 40px;
  color: var(--white-color);
  font-weight: 700;
}
.counter-media__title {
  font-family: var(--title-font);
  font-weight: 700;
  margin: 0;
  line-height: 1;
  text-transform: capitalize;
  color: var(--white-color);
  font-size: 20px;
}

.counter-area1 {
  position: relative;
}
.counter-area1::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  background-color: var(--smoke-color);
  bottom: 0;
  right: 0;
  left: 0;
}

.counter-wrap1,
.counter-wrap2 {
  background-color: var(--theme-color);
  border-radius: 20px;
  padding: 80px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .counter-wrap1,
  .counter-wrap2 {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .counter-wrap1,
  .counter-wrap2 {
    padding: 40px 15px;
  }
}
.counter-wrap1 .overlay,
.counter-wrap2 .overlay {
  background-color: var(--title-color);
  opacity: 0.1;
  z-index: -1;
  border-radius: 20px;
}
.counter-wrap1 .shape-1,
.counter-wrap1 .shape-2,
.counter-wrap2 .shape-1,
.counter-wrap2 .shape-2 {
  position: absolute;
  top: 0;
  z-index: -2;
  mix-blend-mode: soft-light;
  opacity: 0.6;
}
.counter-wrap1 .shape-1,
.counter-wrap2 .shape-1 {
  left: 0;
}
.counter-wrap1 .shape-2,
.counter-wrap2 .shape-2 {
  left: auto;
  right: 0;
}

.counter-wrap2 {
  border-radius: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .counter-media {
    display: block;
    text-align: center;
  }
  .counter-media__icon {
    margin: 0 0 20px 0;
  }
  .counter-style2 .media-label {
    font-size: 46px;
  }
  .counter-style2 .media-icon {
    font-size: 30px;
  }
  .counter-style2 .media-link {
    font-size: 16px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .counter-style2 {
    display: block;
    text-align: center;
  }
  .counter-style2 .media-icon {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .counter-media__icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .counter-media__icon img {
    max-width: 40px;
  }
  .counter-media__title {
    font-size: 12px;
  }
  .counter-media__number {
    font-size: 30px;
  }
}
/*------------------- 4.14. Team  -------------------*/
.team-style1 {
  background-color: var(--white-color);
  padding: 40px 30px;
  border-radius: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.03);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIwIiBoZWlnaHQ9IjQ3MCIgdmlld0JveD0iMCAwIDQyMCA0NzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDBDMjAuNzg0MSAxMzAuNjMzIDI4My44NzEgMjkwLjcyIDQyMCAyNjBWNDUwQzQyMCA0NjEuMDQ2IDQxMS4wNDYgNDcwIDQwMCA0NzBIMjBDOC45NTQzIDQ3MCAwIDQ2MS4wNDYgMCA0NTBWMFoiIGZpbGw9IiNGNkY2RjYiLz4KPC9zdmc+Cg==");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-img {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid var(--white-color);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
}
.team-style1 .team-name {
  margin-bottom: 4px;
  font-size: 24px;
}
.team-style1 .team-content {
  padding-bottom: 23px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-social ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.team-style1 .team-social > ul > li {
  position: relative;
}
.team-style1 .team-social > ul > li > a {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border-radius: 10px;
  color: var(--white-color);
}
.team-style1 .team-social > ul > li > ul {
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--theme-color);
  border-radius: 10px;
  margin-bottom: 10px;
  color: var(--title-color);
  padding: 20px 0;
  transform: scaleY(0);
  transform-origin: bottom;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-social > ul > li > ul > li {
  line-height: 1;
  display: inline-flex;
}
.team-style1 .team-social > ul > li > ul > li > a {
  color: var(--white-color);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
}
.team-style1 .team-social > ul > li > ul > li > a:hover {
  color: var(--sec-color);
}
.team-style1 .team-social > ul > li > ul > li:nth-child(1) a {
  transition-delay: 0.09s;
}
.team-style1 .team-social > ul > li > ul > li:nth-child(2) a {
  transition-delay: 0.1s;
}
.team-style1 .team-social > ul > li > ul > li:nth-child(3) a {
  transition-delay: 0.2s;
}
.team-style1 .team-social > ul > li > ul > li:nth-child(4) a {
  transition-delay: 0.3s;
}
.team-style1 .team-social > ul > li:hover > ul {
  transform: scaleY(1);
  opacity: 1;
  transform-origin: bottom;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-social > ul > li:hover > ul > li {
  transform: translateY(0px);
}
.team-style1 .team-social > ul > li:hover > ul > li > a {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}
.team-style1 .team-bottom {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  flex-wrap: wrap;
  gap: 10px;
}
.team-style1 .team-bottom li a {
  display: inline-flex;
  align-items: center;
  color: var(--body-color);
  gap: 10px;
}
.team-style1 .team-bottom li a:hover {
  color: var(--theme-color);
}
.team-style1 .team-bottom li a span {
  min-width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  font-size: 14px;
}
.team-style1:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  background-color: var(--smoke-color);
}
.team-style1:hover .team-content {
  border-bottom: 1px solid var(--theme-color);
}

.team-style2 {
  position: relative;
  background-color: var(--smoke-color);
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}
.team-style2 .team-img img {
  width: 100%;
}
.team-style2 .team-social2 {
  position: absolute;
  background-color: var(--theme-color);
  border-radius: 10px;
  top: -69px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.team-style2 .team-social2 ul {
  margin-bottom: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 11px 30px;
}
.team-style2 .team-social2 ul li a {
  color: var(--white-color);
  font-size: 14px;
}
.team-style2 .team-social2 ul li a:hover {
  color: var(--title-color);
}
.team-style2 .team-content {
  text-align: center;
  padding: 23px 15px;
  position: relative;
}
.team-style2 .team-name {
  margin-bottom: 0;
  font-size: 20px;
}
.team-style2 .team-name a {
  color: var(--title-color);
}
.team-style2 .team-degi {
  color: var(--theme-color);
  font-size: 14px;
}
.team-style2:hover .team-social2 {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.slide-btns {
  margin-bottom: calc(var(--section-title-space) - 40px);
  display: flex;
  align-items: center;
  gap: 20px;
}
.slide-btns .vs-btn {
  width: 80px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}
.slide-btns .vs-btn::before, .slide-btns .vs-btn::after {
  background-color: var(--theme-color);
}
.slide-btns .vs-btn i {
  margin-left: 0;
}
.slide-btns .vs-btn:hover {
  color: var(--white-color);
}

/*------------------- 4.15. Process  -------------------*/
.process-style1 {
  text-align: center;
  margin: 0 0 5px 0;
  position: relative;
}
.process-style1 .process-arrow {
  position: absolute;
  right: -53px;
  top: 0;
}
.process-style1:nth-child(even) {
  margin-top: 60px;
}
.process-style1:nth-child(even) .process-arrow {
  transform: rotateX(190deg);
}
.process-style1:last-child .process-arrow {
  display: none;
}
.process-style1 .process-icon {
  position: relative;
  text-align: center;
  width: var(--icon-size, 130px);
  height: var(--icon-size, 130px);
  line-height: var(--icon-size, 130px);
  box-shadow: 0px 0px 77px 10px rgba(170, 170, 170, 0.13);
  background-color: var(--white-color);
  border-radius: 50%;
  margin: 0 auto 30px auto;
}
.process-style1 .process-number {
  text-align: center;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 26px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  position: relative;
  border: 5px solid var(--white-color);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  font-family: var(--title-font);
}
.process-style1 .process-title {
  margin: 0 0 10px 0;
}

.process-style2 {
  text-align: center;
  max-width: 290px;
  position: relative;
  margin: 0 0 5px 0;
}
.process-style2 .process-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-color: var(--white-color);
  margin: 22.5px 0 25px 0;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
}
.process-style2 .process-number {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
  margin: -22.5px -22.5px 0 0;
  border-radius: 50%;
}
.process-style2 .process-arrow {
  position: absolute;
  right: -55%;
  top: 10%;
}
.process-style2:last-child .process-arrow {
  display: none;
}

@media (min-width: 1500px) {
  .process-wrap1 {
    margin-left: 120px;
    margin-right: 120px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .process-style1, .process-style1:nth-child(even) {
    margin-top: 0;
  }
  .process-style1 .process-arrow {
    right: -45px;
    top: 25px;
    max-width: 80px;
  }
  .process-style2 .process-arrow {
    right: -23%;
    top: 15%;
    max-width: 90px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .process-style1 .process-arrow {
    display: none;
  }
  .process-style1 .process-icon {
    --icon-size: 100px;
    margin: 0 auto 20px auto;
  }
  .process-style1 .process-icon img {
    max-width: 40px;
  }
  .process-style2 {
    max-width: 100%;
  }
  .process-style2 .process-arrow {
    display: none;
  }
}
/*------------------- 4.16. Accordion  -------------------*/
.accordion-style1 {
  margin: 0 0 30px 0;
}
.accordion-style1 .accordion-item {
  margin: 0 0 20px 0;
  padding: 0 25px 0 25px;
  background-color: var(--white-color);
  box-shadow: 0px 13px 17px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}
.accordion-style1 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 14.5px 0;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: left;
}
.accordion-style1 .accordion-button:after {
  content: "\f061";
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--title-color);
  border: none;
  width: auto;
  height: auto;
  background-image: none;
}
.accordion-style1 .accordion-button:focus {
  box-shadow: none;
}
.accordion-style1 .accordion-button:not(.collapsed):after {
  transform: rotate(0);
}
.accordion-style1 .accordion-body {
  border-top: 1px solid #EBEBEB;
  padding: 17px 0 20px 0;
}
.accordion-style1 .accordion-body p:last-child {
  margin-bottom: 0;
}
.accordion-style1 .accordion-collapse {
  border: none;
}
.accordion-style1.layout2 .accordion-item {
  padding: 0;
  box-shadow: none;
}
.accordion-style1.layout2 .accordion-button {
  padding: 14.5px 25px;
  font-size: 16px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 0;
}
.accordion-style1.layout2 .accordion-button:after {
  color: var(--white-color);
}
.accordion-style1.layout2 .accordion-button.collapsed {
  background-color: var(--smoke-color);
  color: var(--title-color);
}
.accordion-style1.layout2 .accordion-button.collapsed:after {
  color: var(--theme-color);
}
.accordion-style1.layout2 .accordion-body {
  border: none;
  background-color: var(--smoke-color);
  padding: 25px 25px 23px 25px;
}

.accordion-style2 .accordion-item {
  margin: 0 0 20px 0;
  padding: 0 35px 6.5px 35px;
  background-color: var(--white-color);
  box-shadow: 7.5px 12.99px 30px 0px rgba(148, 171, 255, 0.05);
  border-radius: 30px;
}
.accordion-style2 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 24.5px 0 18px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: left;
}
.accordion-style2 .accordion-button:after {
  content: "\f35a";
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--title-color);
  border: none;
  width: auto;
  height: auto;
  background-image: none;
  transform: rotate(0);
}
.accordion-style2 .accordion-button:focus {
  box-shadow: none;
}
.accordion-style2 .accordion-button:not(.collapsed):after {
  transform: rotate(90deg);
}
.accordion-style2 .accordion-button:hover {
  color: var(--theme-color);
}
.accordion-style2 .accordion-body {
  padding: 0 0 20px 0;
}
.accordion-style2 .accordion-body p {
  font-size: 14px;
}
.accordion-style2 .accordion-body p:last-child {
  margin-bottom: 0;
}
.accordion-style2 .accordion-collapse {
  border: none;
}

.faq-wrap1 {
  padding-top: 240px;
  padding-bottom: 90px;
  position: relative;
}

.faq-shape1 {
  position: absolute;
  right: 0;
  top: 120px;
  bottom: 0;
  width: 100%;
  max-width: 1120px;
  border-radius: 5px 0 0 5px;
  z-index: -1;
}

.faq-shape2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 570px;
  z-index: -2;
  border-radius: 0 5px 5px 0;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .faq-shape1 {
    max-width: 60%;
  }
  .faq-shape2 {
    max-width: 49%;
  }
}
/* Extra large devices */
@media (max-width: 1500px) {
  .faq-wrap1 {
    padding-top: var(--section-space, 120px);
    padding-bottom: calc(var(--section-space, 120px) - 30px);
  }
  .faq-shape2 {
    max-height: initial;
    bottom: 0;
    display: none;
  }
  .faq-shape1 {
    max-width: 70%;
    top: 0;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .faq-wrap1 {
    padding-top: var(--section-space, 120px);
  }
  .faq-shape2 {
    max-height: initial;
    bottom: 0;
    display: none;
  }
  .faq-shape1 {
    max-width: 70%;
    top: 0;
  }
  .accordion-style1 .accordion-button {
    font-size: 16px;
  }
  .accordion-style1 .accordion-body {
    font-size: 14px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .faq-wrap1 {
    padding-top: var(--section-space-mobile, 80px);
    padding-bottom: calc(var(--section-space-mobile, 80px) - 30px);
  }
  .faq-shape1 {
    max-width: 100%;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .accordion-style1 .accordion-item {
    padding: 0 20px 0px 20px;
  }
  .accordion-style1.layout2 .accordion-button {
    padding: 14.5px 20px;
    font-size: 16px;
  }
  .accordion-style1.layout2 .accordion-body {
    padding: 25px 20px 23px 20px;
  }
  .accordion-style2 .accordion-item {
    padding: 0 30px 6.5px 30px;
  }
}
/*------------------- 4.17. Simple Sections  -------------------*/
.brand-slide-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.brand-slide-nav button {
  position: relative;
  border: none;
  text-transform: uppercase;
  background-color: var(--theme-color);
  color: var(--white-color);
  width: 50%;
  padding: 12px 20px;
}
.brand-slide-nav button .fa-long-arrow-left {
  margin-right: 10px;
}
.brand-slide-nav button .fa-long-arrow-right {
  margin-left: 10px;
}
.brand-slide-nav button:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 20px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: -10px;
}
.brand-slide-nav button:last-child:after {
  display: none;
}
.brand-slide-nav button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-brand1 img {
  transition: all ease 0.4s;
  filter: grayscale(100%) invert(0.8);
}
.vs-brand1 img:hover, .vs-brand1.slick-current img {
  filter: grayscale(0) invert(0);
}

.brand-wrap1 {
  border-radius: 20px;
  padding: 80px 105px;
  background-color: var(--white-color);
  text-align: center;
  background-size: auto;
  background-position: center center;
}

.brand-wrap2,
.brand-wrap3 {
  background-color: var(--smoke-color);
  text-align: center;
  padding: 80px 100px;
  border-radius: 20px;
  margin-top: -110px;
}
@media (max-width: 991px) {
  .brand-wrap2,
  .brand-wrap3 {
    padding: 50px;
  }
}

.brand-wrap3 {
  z-index: 1;
}
.brand-wrap3 .vs-btn.slidebtn1 {
  z-index: 2;
}

/* Small devices */
@media (max-width: 767px) {
  .brand-wrap1 {
    padding: 60px 25px;
  }
}
.form-style4.form-newsletter {
  position: relative;
  margin-bottom: 30px;
}
.form-style4.form-newsletter .vs-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.inner-layout .overlay {
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(15px);
  z-index: -1;
}

/*------------------- 4.18. Testimonial  -------------------*/
.test-wrap1 {
  position: relative;
  padding: 70px 0;
  overflow: hidden;
  padding-left: 50%;
  margin-right: -50%;
}
@media (max-width: 1919px) {
  .test-wrap1 {
    padding-left: 40%;
    margin-right: -40%;
  }
}
@media (max-width: 1199px) {
  .test-wrap1 {
    padding-left: 10%;
    margin-right: -10%;
  }
}
@media (max-width: 991px) {
  .test-wrap1 {
    padding: 45px 0;
    padding-left: 15px;
    margin-right: 0;
    padding-right: 15px;
    padding-bottom: 80px;
  }
}
.test-wrap1 .testi-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.test-wrap1 .testi-thumb img {
  height: 100%;
  border-radius: 30px;
}
.test-wrap1 .vs-carousel {
  padding: 30px 0px;
}
.test-wrap1 .vs-btn.slidebtn1 {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  transform: translateY(0);
  position: static;
  border-radius: 30px;
  background-color: var(--white-color);
  border: 1px solid var(--theme-color);
  color: var(--title-color);
  font-size: 24px;
  padding: 17px 29px;
}
.test-wrap1 .vs-btn.slidebtn1::before, .test-wrap1 .vs-btn.slidebtn1::after {
  background-color: var(--theme-color);
}
.test-wrap1 .vs-btn.slidebtn1:hover {
  border: 1px solid var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}
.test-wrap1 .testi-slide-btns {
  position: absolute;
  bottom: 0;
  right: auto;
  left: 46%;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1499px) {
  .test-wrap1 .testi-slide-btns {
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
@media (max-width: 991px) {
  .test-wrap1 .testi-slide-btns {
    bottom: 20px;
  }
}

.testi-style1,
.testi-style3 {
  background-color: var(--white-color);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.09);
  margin-bottom: 0px;
  position: relative;
}
@media (max-width: 991px) {
  .testi-style1,
  .testi-style3 {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .testi-style1,
  .testi-style3 {
    padding: 15px;
  }
}
.testi-style1 .testi-icon,
.testi-style3 .testi-icon {
  position: absolute;
  right: 40px;
  top: 25.46px;
}
@media (max-width: 767px) {
  .testi-style1 .testi-icon,
  .testi-style3 .testi-icon {
    display: none;
  }
}
.testi-style1 .testi-author,
.testi-style3 .testi-author {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
@media (max-width: 991px) {
  .testi-style1 .testi-author,
  .testi-style3 .testi-author {
    display: inline-flex;
    justify-content: center;
  }
}
.testi-style1 .author-img,
.testi-style3 .author-img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}
.testi-style1 .author-img img,
.testi-style3 .author-img img {
  width: 100%;
}
.testi-style1 .testi-name,
.testi-style3 .testi-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.testi-style1 .testi-degi,
.testi-style3 .testi-degi {
  color: var(--theme-color);
  font-size: 14px;
}
.testi-style1 .testi-rating,
.testi-style3 .testi-rating {
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  margin-bottom: 18px;
}
.testi-style1 .testi-rating i,
.testi-style3 .testi-rating i {
  color: #feb519;
  margin-left: 5px;
  font-size: 14px;
}
.testi-style1 .testi-rating i:first-child,
.testi-style3 .testi-rating i:first-child {
  margin-left: 0px;
}
.testi-style1 .testi-text,
.testi-style3 .testi-text {
  margin-bottom: 0;
}

.testi-style1 {
  box-shadow: none;
}

.testi-style3 {
  box-shadow: none;
  margin-bottom: 30px;
}

.testi-author3 {
  display: flex;
  align-items: center;
  text-align: left;
  background: var(--white-color);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  padding: 30px 35px 30px 30px;
  position: relative;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1199px) {
  .testi-author3 {
    padding: 15px;
  }
}
.testi-author3::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 60px;
  background-color: var(--border-color);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}
.testi-author3 .author-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.testi-author3 .author-img img {
  width: 100%;
}
.testi-author3 .testi-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.testi-author3 .testi-degi {
  color: var(--theme-color);
  font-size: 14px;
}

.testi-slider-tab {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .testi-slider-tab {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .testi-slider-tab {
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .testi-slider-tab {
    flex-wrap: wrap;
  }
}
.testi-slider-tab .slick-slide.slick-current.slick-active .testi-author3 {
  border: 1px solid var(--theme-color);
}
.testi-slider-tab .slick-slide.slick-current.slick-active .testi-author3::before {
  background-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}

.testi-thumb3 {
  position: absolute;
  width: 47%;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
  top: 0;
  bottom: 0;
}
@media (max-width: 991px) {
  .testi-thumb3 {
    position: relative;
    width: 100%;
    height: 500px;
    border-radius: 0;
    top: 0;
    bottom: 0;
    margin-top: -80px;
    margin-bottom: 50px;
  }
}
.testi-thumb3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #272e39 0%, rgba(39, 46, 57, 0.35) 100%);
  opacity: 0.6;
  border-radius: 0px 0px 0px 0px;
}

.testi-slider-btn3 {
  margin-right: 20px;
}
.testi-slider-btn3:last-child {
  margin-right: 0;
}
.testi-slider-btn3 i {
  margin: 0;
}

.test-wrap2 {
  padding: 0 15px;
}

/*------------------- 4.19. Popup Side Menu  -------------------*/
.sideMenuToggler {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.sideMenuToggler svg path {
  transition: all 0.3s ease-in-out;
}
.sideMenuToggler:hover svg path {
  transition: all 0.3s ease-in-out;
  fill: var(--theme-color);
}
.sideMenuToggler.v2:hover svg path {
  transition: all 0.3s ease-in-out;
  fill: var(--theme-color);
}
.sideMenuToggler.v3 {
  min-width: 60px;
  min-height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border-radius: 50%;
}
.sideMenuToggler.v3:hover {
  background-color: var(--sec-color);
}
.sideMenuToggler.v3:hover svg path {
  transition: all 0.3s ease-in-out;
  fill: var(--white-color);
}

.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}
.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: none;
  color: var(--white-color);
  background-color: var(--theme-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  transform: rotate(0);
  transition: all ease 0.4s;
}
.sidemenu-wrapper .closeButton:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  transform: rotate(90deg);
}
.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 35px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  transition-delay: 1s;
  transition: right ease 1s;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  margin: 0 0 40px 0;
  background-color: transparent;
}
.sidemenu-wrapper .widget_title {
  border-bottom: 1px solid var(--border-color);
  padding: 0 0 20px 0;
  margin: 0 0 25px 0;
}
.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all ease 0.8s;
}
.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.sidemenu-wrapper .footer-social a:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

/*------------------- 4.20. Price Plan  -------------------*/
.price-block--style1 {
  margin-bottom: 30px;
}
.price-block--style1 .price-block__header {
  border-radius: 20px;
  background-color: var(--theme-color);
  text-align: center;
  position: relative;
  min-height: 227px;
  padding-top: 40px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.price-block--style1 .price-block__header .overlay {
  background-color: var(--sec-color);
  opacity: 0.75;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__header .shape1,
.price-block--style1 .price-block__header .shape2 {
  position: absolute;
  top: 109px;
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__header .shape1 {
  left: 0;
}
.price-block--style1 .price-block__header .shape2 {
  right: 0;
}
.price-block--style1 .price-block__title {
  font-size: 30px;
  margin-bottom: 0px;
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__price {
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__price > sup {
  font-size: 20px;
  top: -22px;
  padding: 0 2.5px;
}
.price-block--style1 .price-block__body {
  border-radius: 20px;
  background-color: var(--white-color);
  margin: 0 40px;
  padding: 40px 35px;
  margin-top: -52px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  z-index: 1;
  position: relative;
  text-align: center;
}
@media (max-width: 1199px) {
  .price-block--style1 .price-block__body {
    margin: 0 15px;
    padding: 15px;
  }
}
.price-block--style1 .price-block__time {
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  border: 1px solid var(--border-color);
  padding: 0 20px;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__time > i {
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.price-block--style1 .price-block__list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  padding: 25px 0 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.price-block--style1 .price-block__list > li {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  gap: 10px;
  text-align: left;
  font-weight: 500;
  color: var(--body-color);
}
.price-block--style1 .price-block__list > li > i {
  color: var(--body-color);
}
.price-block--style1 .price-block__list > li.active {
  color: var(--title-color);
}
.price-block--style1 .price-block__list > li.active > i {
  color: var(--theme-color);
}
.price-block--style1 .vs-btn {
  clip-path: none;
  width: 100%;
  justify-content: center;
}
.price-block--style1 .vs-btn::before, .price-block--style1 .vs-btn::after {
  background-color: var(--sec-color);
}
.price-block--style1:hover .price-block__header {
  background-color: var(--sec-color);
  transition: all 0.3s ease-in-out;
}
.price-block--style1:hover .price-block__header .overlay {
  background-color: var(--theme-color);
  opacity: 0.85;
  transition: all 0.3s ease-in-out;
}
.price-block--style1:hover .price-block__time {
  background-color: var(--sec-color);
  border-color: var(--sec-color);
  color: var(--white-color);
}
.price-block--style1:hover .price-block__time > i {
  color: var(--white-color);
}

/*------------------- 4.21. Forms Style  -------------------*/
.form-style1,
.form-style2,
.form-style3 {
  background-color: var(--theme-color);
  --bs-gutter-x: 20px;
  padding: 60px 40px;
  text-align: center;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .form-style1,
  .form-style2,
  .form-style3 {
    padding: 30px 15px;
  }
}
.form-style1 .title,
.form-style2 .title,
.form-style3 .title {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 11px;
  color: var(--white-color);
}
@media (max-width: 767px) {
  .form-style1 .title,
  .form-style2 .title,
  .form-style3 .title {
    font-size: 40px;
  }
}
.form-style1 .subtitle,
.form-style2 .subtitle,
.form-style3 .subtitle {
  font-size: 20px;
  font-weight: 700;
  color: var(--white-color);
  margin-bottom: 33px;
  display: block;
}
.form-style1 .form-group:last-child,
.form-style2 .form-group:last-child,
.form-style3 .form-group:last-child {
  margin-bottom: 0;
}
.form-style1 .form-title,
.form-style2 .form-title,
.form-style3 .form-title {
  color: var(--white-color);
  text-align: center;
  margin: 0 0 27px 0;
  padding: 0 0 17px 0;
  position: relative;
}
.form-style1 .form-title:before,
.form-style2 .form-title:before,
.form-style3 .form-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #0844bc;
  background-color: var(--white-color);
  opacity: 0.3;
}
.form-style1 .vs-btn,
.form-style2 .vs-btn,
.form-style3 .vs-btn {
  margin-top: 25px;
}
.form-style1 textarea,
.form-style1 input,
.form-style1 .form-control,
.form-style2 textarea,
.form-style2 input,
.form-style2 .form-control,
.form-style3 textarea,
.form-style3 input,
.form-style3 .form-control {
  height: 60px;
  background-color: var(--smoke-color);
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  border-radius: 30px;
  border: 0;
}
.form-style1 textarea::placeholder,
.form-style1 input::placeholder,
.form-style1 .form-control::placeholder,
.form-style2 textarea::placeholder,
.form-style2 input::placeholder,
.form-style2 .form-control::placeholder,
.form-style3 textarea::placeholder,
.form-style3 input::placeholder,
.form-style3 .form-control::placeholder {
  color: var(--body-color);
}
.form-style1 textarea,
.form-style2 textarea,
.form-style3 textarea {
  min-height: 180px;
  resize: none;
}
.form-style1 .shape-1,
.form-style1 .shape-2,
.form-style2 .shape-1,
.form-style2 .shape-2,
.form-style3 .shape-1,
.form-style3 .shape-2 {
  position: absolute;
  z-index: -1;
  opacity: 0.6;
  mix-blend-mode: soft-light;
}
.form-style1 .shape-1,
.form-style2 .shape-1,
.form-style3 .shape-1 {
  top: 0;
  left: 0;
}
.form-style1 .shape-2,
.form-style2 .shape-2,
.form-style3 .shape-2 {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

.form-style2 {
  padding: 60px 60px 40px 60px;
  margin-top: -130px;
  background-color: var(--black-color);
}
.form-style2 .shape-1,
.form-style2 .shape-2 {
  mix-blend-mode: normal;
  opacity: 0.4;
}
.form-style2 .vs-btn {
  background-color: var(--black-color);
}
.form-style2 .vs-btn::after, .form-style2 .vs-btn::before {
  background-color: var(--theme-color);
}
@media (max-width: 767px) {
  .form-style2 {
    padding: 40px 20px 40px 20px;
  }
}

.form-style3 {
  padding: 40px 40px 30px 40px;
  margin-top: -70px;
  margin-bottom: 0;
}
.form-style3 .form-control {
  max-width: 169px;
}
.form-style3 .form-control::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 20px;
}

.form-style4 .form-control {
  border-radius: 30px;
  background-color: var(--smoke-color);
  border: 1px solid transparent;
  height: 60px;
}
.form-style4 .form-control:focus {
  border-color: var(--theme-color);
}

.newsletter-form1,
.newsletter-form2 {
  background-color: var(--theme-color);
  padding: 70px 60px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 1199px) {
  .newsletter-form1,
  .newsletter-form2 {
    padding: 30px 20px;
  }
}
@media (max-width: 991px) {
  .newsletter-form1,
  .newsletter-form2 {
    padding: 20px;
  }
}
.newsletter-form1 .form-group > i,
.newsletter-form2 .form-group > i {
  right: calc(var(--bs-gutter-x) / 2 + 24px);
  top: 25px;
  font-size: 14px;
  color: var(--title-color);
}
.newsletter-form1 .form-control,
.newsletter-form2 .form-control {
  height: 60px;
  border: 0px solid var(--sec-color);
  border-radius: 999px;
  padding: 0 30px 0 30px;
  min-width: 450px;
}
@media (max-width: 991px) {
  .newsletter-form1 .form-control,
  .newsletter-form2 .form-control {
    margin-bottom: 20px;
    min-width: 100%;
  }
}
.newsletter-form1 .shape,
.newsletter-form2 .shape {
  z-index: -1;
}

.newsletter-form2 {
  border-radius: 0;
  padding: 40px 0px;
}
.newsletter-form2 .overlay {
  background-color: var(--title-color);
  opacity: 0.2;
  z-index: -1;
}
.newsletter-form2 .sec-text {
  margin-bottom: 0;
}
.newsletter-form2 .form-control {
  margin-bottom: 0;
  border: 0;
  height: 70px;
  min-width: 646px;
}
@media (max-width: 1199px) {
  .newsletter-form2 .form-control {
    min-width: 500px;
  }
}
@media (max-width: 991px) {
  .newsletter-form2 .form-control {
    min-width: 100%;
  }
}
.newsletter-form2 .vs-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 22px 30px;
}
@media (max-width: 767px) {
  .newsletter-form2 .vs-btn {
    position: relative;
    top: 0;
    right: 0;
    background-color: var(--sec-color);
    color: var(--white-color);
    padding: 22px 30px;
  }
}

.form-style5 .form-group {
  margin-bottom: 20px;
}
.form-style5 .form-group:last-child {
  margin-bottom: 30px;
}
.form-style5 .form-control {
  border-radius: 10px;
  border: none;
  height: 60px;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(15px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--white-color);
}
.form-style5 .form-control::placeholder {
  color: var(--white-color);
}

/*------------------- 4.22. Projects  -------------------*/
.filter-menu1 {
  background-color: transparent;
  position: relative;
  width: max-content;
  margin: 0 auto 50px auto;
  max-width: 100%;
  border-radius: 9999px;
  text-align: center;
  padding: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.filter-menu1 button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  color: var(--title-color);
  display: inline-block;
  position: relative;
  padding: 17px 31px;
  border-radius: 9999px;
  border: 2px solid var(--theme-color);
}
.filter-menu1 button:hover {
  color: var(--theme-color);
}
.filter-menu1 button.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.project-style1 {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  margin: 0 0 30px 0;
}
.project-style1 .project-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
.project-style1 .project-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  background-color: var(--theme-color);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 1;
}
.project-style1 .project-img img {
  transition: all ease 0.4s;
  transform: scale(1);
}
.project-style1 .project-content {
  padding: 25px 30px 30px 30px;
}
.project-style1:hover .vs-btn.style3 {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.project-style1:hover .project-img:before {
  transform: scale(1);
  opacity: 0.5;
  visibility: visible;
}
.project-style1:hover .project-img img {
  transform: scale(1.1);
}

.slick-slide .project-style1 {
  box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
}

.project-style2 {
  position: relative;
  margin: 0 0 30px 0;
}
.project-style2 .project-img {
  overflow: hidden;
  position: relative;
}
.project-style2 .project-img img {
  transition: all ease 0.4s;
  transform: scale(1);
  width: 100%;
}
.project-style2 .project-shape {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}
.project-style2 .icon-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: var(--white-color);
  color: var(--theme-color);
  opacity: 0;
  visibility: hidden;
}
.project-style2 .icon-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.project-style2 .project-title {
  color: var(--white-color);
  margin: 0;
}
.project-style2 .project-label {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--white-color);
  display: block;
  margin: 0 0 5px 0;
}
.project-style2 .project-label,
.project-style2 .project-title {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50px);
  transition: all ease 0.4s;
}
.project-style2 .project-content {
  position: absolute;
  left: 70px;
  bottom: 60px;
  z-index: 3;
}
.project-style2:hover .project-shape {
  opacity: 0.8;
  visibility: visible;
}
.project-style2:hover .project-img img {
  transform: scale(1.1);
}
.project-style2:hover .icon-btn {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
}
.project-style2:hover .project-title,
.project-style2:hover .project-label {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0.1s;
}
.project-style2:hover .icon-btn,
.project-style2:hover .project-label {
  transition-delay: 0.2s;
}

.project-box {
  background-color: var(--smoke-color);
  padding: 45px 40px;
  margin: 0 0 30px 0;
}
.project-box__title {
  margin: -0.3em 0 25px 0;
}
.project-box__icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: var(--white-color);
  text-align: center;
  color: var(--theme-color);
  margin: 0 20px 0 0;
  font-size: 18px;
  border-radius: 50%;
}
.project-box__label {
  font-size: 14px;
  display: block;
  margin: 0 0 10px 0;
  line-height: 1;
}
.project-box__info {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color);
  margin: 0;
}
.project-box__item {
  display: flex;
  align-items: center;
}
.project-box__item:not(:last-child) {
  border-bottom: 1px solid #dadfec;
  margin: 0 0 30px 0;
  padding: 0 0 30px 0;
}

.project-contact {
  background-color: var(--theme-color);
  padding: 32px 40px 20px 40px;
}
.project-contact__title {
  color: var(--white-color);
  margin: 0 0 10px 0;
}
.project-contact__text {
  color: var(--white-color);
  margin: 0 0 22px 0;
}
.project-contact__info {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 0 0 33px;
}
.project-contact__info a {
  color: inherit;
}
.project-contact__info i {
  margin: 0 15px 0 0;
  position: absolute;
  left: 0;
  top: 3.5px;
}

.project-style3 {
  position: relative;
  margin-bottom: 30px;
}
.project-style3 .project-img {
  overflow: hidden;
  border-radius: 10px;
}
.project-style3 .project-img img {
  transform: scale(1);
  transition: all ease 0.4s;
  width: 100%;
}
.project-style3 .project-bottom {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background-color: var(--theme-color);
  border-radius: 10px;
  transition: all ease 0.4s;
  padding: 30px 35px 28px 35px;
  opacity: 0;
  visibility: hidden;
}
.project-style3 .icon-btn,
.project-style3 .project-category,
.project-style3 .project-title {
  transform: translateY(10px);
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}
.project-style3 .project-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-color);
  margin: 0 0 2px 0;
}
.project-style3 .project-category {
  font-size: 16px;
  color: #7c98ff;
  margin: 0;
}
.project-style3 .icon-btn {
  --btn-size: 40px;
  --btn-font-size: 16px;
  transform: translateX(10px);
}
.project-style3:hover .project-bottom {
  opacity: 1;
  visibility: visible;
}
.project-style3:hover .icon-btn,
.project-style3:hover .project-title,
.project-style3:hover .project-category {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0.1s;
}
.project-style3:hover .icon-btn {
  transition-delay: 0.15s;
}
.project-style3:hover .project-category {
  transition-delay: 0.2s;
}
.project-style3:hover .project-img img {
  transform: scale(1.1);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .project-style2 .icon-btn {
    top: 60px;
    right: 60px;
    left: auto;
    transform: none;
    --btn-size: 40px;
    --btn-font-size: 18px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .project-box {
    padding: 35px 25px;
  }
  .project-contact {
    padding: 32px 25px 20px 25px;
  }
  .project-style1 .project-img img {
    width: 100%;
  }
  .project-style1 .project-content {
    padding-left: 25px;
    padding-right: 25px;
  }
  .project-style3 .project-bottom {
    left: 15px;
    right: 15px;
    bottom: 15px;
    padding: 20px 20px 20px 20px;
    gap: 15px;
  }
  .project-style3 .project-title {
    font-size: 18px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .project-style2 .project-shape {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .project-style2 .project-content {
    left: 20px;
    bottom: 20px;
  }
  .project-style2 .icon-btn {
    top: 20px;
    right: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .filter-menu1 {
    padding: 15px 15px;
    border-radius: 10px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .filter-menu1 {
    padding: 0px 0px;
    gap: 6px;
  }
}
@media (max-width: 767px) {
  .filter-menu1 button {
    font-size: 14px;
    padding: 15px 22px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .filter-menu1 button {
    padding: 10px 15px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .project-style1 .project-content {
    padding: 25px 20px 30px 20px;
  }
}
/*------------------- 4.23. Contact  -------------------*/
.contact-tab-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0 0 60px 0;
}
.contact-tab-menu .btn-img {
  width: 120px;
  float: left;
  margin: 0 20px 0 0;
}
.contact-tab-menu .btn-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  margin: 4px 0 0 0;
  transition: all ease 0.4s;
}
.contact-tab-menu .btn-text {
  display: inline-block;
  color: var(--body-color);
  font-size: 14px;
  font-family: var(--title-font);
  margin: 2px 0 0 0;
  transition: all ease 0.4s;
}
.contact-tab-menu button {
  padding: 20px;
  position: relative;
  width: 100%;
  flex: 1;
  text-align: left;
  background-color: transparent;
  border: 1px solid #f0f0f0;
}
.contact-tab-menu button.active, .contact-tab-menu button:hover {
  background-color: var(--theme-color);
  border-color: transparent;
}
.contact-tab-menu button.active .btn-text,
.contact-tab-menu button.active .btn-title, .contact-tab-menu button:hover .btn-text,
.contact-tab-menu button:hover .btn-title {
  color: var(--white-color);
}

.contact-box {
  background-color: var(--smoke-color);
  padding: 60px;
  height: 100%;
  border-radius: 30px;
}
.contact-box__title {
  margin: -0.4em 0 10px 0;
}
.contact-box__text {
  margin: 0 0 37px 0;
}
.contact-box__item {
  display: flex;
  align-items: center;
}
.contact-box__item:not(:last-child) {
  padding: 0 0 30px 0;
  margin: 0 0 30px 0;
  border-bottom: 1px solid #dadfec;
}
.contact-box__icon {
  --icon-size: 90px;
  font-size: 30px;
  line-height: 1;
  margin: 0 20px 0 0;
  min-width: var(--icon-size);
  height: var(--icon-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-color);
  color: var(--white-color);
  border-radius: 20px;
}
@media (max-width: 767px) {
  .contact-box__icon {
    --icon-size: 70px;
  }
}
.contact-box__label {
  font-size: 20px;
  margin: -0.3em 0 5px 0;
}
.contact-box__info {
  max-width: 235px;
  margin: 0 0 -0.5em 0;
}
.contact-box__info a {
  display: inline-block;
  color: inherit;
}
.contact-box__info a:hover {
  color: var(--theme-color);
}
.contact-box__form .form-group {
  line-height: 1;
}
.contact-box__form textarea,
.contact-box__form select,
.contact-box__form input {
  height: 55px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-position: right 21px center;
  border-radius: 30px;
}
.contact-box__form i {
  top: 19px;
  right: calc(var(--bs-gutter-x) / 2 + 24px);
  font-size: 16px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .contact-box {
    padding: 40px;
  }
  .contact-box__title {
    margin: -0.3em 0 10px 0;
  }
  .contact-box__form textarea,
  .contact-box__form select,
  .contact-box__form input {
    font-size: 14px;
  }
  .contact-tab-menu button {
    text-align: center;
  }
  .contact-tab-menu .btn-title {
    display: block;
  }
  .contact-tab-menu .btn-img {
    float: none;
    margin: 0 auto 10px auto;
    display: block;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .contact-tab-menu .btn-title {
    font-size: 16px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .contact-box {
    padding: 40px 20px;
  }
  .contact-box__label {
    font-size: 16px;
  }
  .contact-box__icon {
    font-size: 20px;
  }
}
.auth-style {
  background-color: var(--smoke-color);
  border-radius: 10px;
  padding: 60px 60px 50px 60px;
}
.auth-style .auth-link {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--title-color);
  padding-top: 13px;
}
.auth-style .auth-title {
  margin: 0 0 10px 0;
  text-align: center;
}
.auth-style .auth-social {
  padding-bottom: 40px;
  border-bottom: 2px solid var(--theme-color);
  margin-bottom: 30px;
}
.auth-style .nav-tabs {
  border-bottom: 0;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}
.auth-style .nav-tabs .nav-item {
  flex: 1;
}
.auth-style .nav-tabs .nav-link {
  border: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--title-color);
  background-color: var(--white-color);
  padding: 17px 59px;
  border-radius: 30px;
  width: 100%;
  justify-content: center;
}
.auth-style .nav-tabs .nav-link.active {
  border: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
}

.footer-social.style3 {
  text-align: center;
}
.footer-social.style3 a:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.auth-form .auth-label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
}
.auth-form .form-group .iti {
  width: 100%;
}
.auth-form .form-group .iti__selected-dial-code,
.auth-form .form-group .iti__arrow {
  display: none;
}
.auth-form .form-group .iti--allow-dropdown input.iti__tel-input,
.auth-form .form-group .iti--allow-dropdown input.iti__tel-input[type=text],
.auth-form .form-group .iti--allow-dropdown input.iti__tel-input[type=tel] {
  padding-left: 60px;
}
.auth-form .form-group .iti__selected-country-primary {
  padding: 0 0 0 30px;
}
.auth-form .form-group .iti__selected-country-primary:hover {
  background-color: none;
}
.auth-form .form-group .iti__tel-input,
.auth-form .form-group .form-control,
.auth-form .form-group input {
  border-radius: 30px;
  min-height: 60px;
  border: 0;
  color: var(--body-color);
}
.auth-form .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.auth-form .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
  background-color: transparent;
}
.auth-form.style2 .form-group {
  margin-bottom: 15px;
}
.auth-form.style2 .form-group.last {
  margin-bottom: 30px;
}

/*------------------- 4.24. Event  -------------------*/
.event-style1, .event-style2, .event-style3 {
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}
.event-style1 .event-img, .event-style2 .event-img, .event-style3 .event-img {
  position: relative;
}
.event-style1 .event-tags, .event-style2 .event-tags, .event-style3 .event-tags {
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.event-style1 .event-tags a, .event-style2 .event-tags a, .event-style3 .event-tags a {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
}
.event-style1 .event-date, .event-style2 .event-date, .event-style3 .event-date {
  position: absolute;
  bottom: 30px;
  right: 30px;
  text-align: center;
  background: var(--theme-color);
  padding: 13px 9px;
  border-radius: 10px;
  color: var(--white-color);
}
.event-style1 .event-date span, .event-style2 .event-date span, .event-style3 .event-date span {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 2px;
}
.event-style1 .event-meta, .event-style2 .event-meta, .event-style3 .event-meta {
  margin-bottom: 16px;
}
.event-style1 .event-meta ul, .event-style2 .event-meta ul, .event-style3 .event-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  row-gap: 0;
}
.event-style1 .event-meta span, .event-style2 .event-meta span, .event-style3 .event-meta span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.event-style1 .event-meta span i, .event-style2 .event-meta span i, .event-style3 .event-meta span i {
  font-size: 14px;
  color: var(--theme-color);
}
.event-style1 .event-content, .event-style2 .event-content, .event-style3 .event-content {
  padding: 34px 30px 40px;
  border: 2px solid var(--border-color);
  border-top: 0;
  border-radius: 0 0 10px 10px;
}
@media (max-width: 1399px) {
  .event-style1 .event-content, .event-style2 .event-content, .event-style3 .event-content {
    padding: 35px 25px 35px;
  }
}
@media (max-width: 1199px) {
  .event-style1 .event-content, .event-style2 .event-content, .event-style3 .event-content {
    padding: 30px 20px 30px;
  }
}
.event-style1 .event-title, .event-style2 .event-title, .event-style3 .event-title {
  margin-bottom: 10px;
}
.event-style1 .event-title a, .event-style2 .event-title a, .event-style3 .event-title a {
  display: block;
  color: var(--title-color);
}
.event-style1 .event-title a:hover, .event-style2 .event-title a:hover, .event-style3 .event-title a:hover {
  color: var(--theme-color);
}
.event-style1 .event-text, .event-style2 .event-text, .event-style3 .event-text {
  margin-bottom: 15px;
}
.event-style1 .event-link, .event-style2 .event-link, .event-style3 .event-link {
  display: inline-block;
  font-weight: 700;
  color: var(--title-color);
  padding: 5px 20px;
  border: 2px solid var(--theme-color);
  border-radius: 30px;
}
.event-style1 .event-link:hover, .event-style2 .event-link:hover, .event-style3 .event-link:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.event-style1 .event-footer, .event-style2 .event-footer, .event-style3 .event-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.event-style1 .event-price, .event-style2 .event-price, .event-style3 .event-price {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  gap: 5px;
}
@media (max-width: 1399px) {
  .event-style1 .event-price, .event-style2 .event-price, .event-style3 .event-price {
    font-size: 25px;
  }
}
@media (max-width: 1199px) {
  .event-style1 .event-price, .event-style2 .event-price, .event-style3 .event-price {
    font-size: 16px;
  }
}
.event-style1 .event-price span, .event-style2 .event-price span, .event-style3 .event-price span {
  color: var(--theme-color);
}
.event-style1 .event-text, .event-style2 .event-text, .event-style3 .event-text {
  margin-bottom: 23px;
}
.event-style1 .event-img > img {
  width: 100%;
}
@media (max-width: 991px) {
  .event-style1 .event-title {
    font-size: 18px;
  }
}
.event-style2 {
  position: relative;
  overflow: visible;
  height: 320px;
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  .event-style2 {
    height: auto;
    margin-bottom: 30px;
  }
}
.event-style2 .event-content {
  position: absolute;
  bottom: -70px;
  left: 30px;
  min-width: 420px;
  background-color: var(--white-color);
  border: 2px solid var(--border-color);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  width: 420px;
}
@media (max-width: 767px) {
  .event-style2 .event-content {
    position: static;
    width: 100%;
    min-width: 100%;
  }
}
.event-style2 .event-img {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.event-style2 .event-img .overlay {
  background: linear-gradient(180deg, #272e39 0%, rgba(39, 46, 57, 0) 100%);
  opacity: 0.7;
  border-radius: 10px;
}
.event-style2 .event-img .img {
  border-radius: 10px;
  height: 320px;
  object-fit: cover;
  /* Medium devices */
}
@media (max-width: 991px) {
  .event-style2 .event-img .img {
    width: 100%;
  }
}
.event-style2 .event-img .event-title {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  padding: 30px;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.event-style2 .event-img .event-title a {
  color: var(--white-color);
}
.event-style2 .event-date {
  bottom: auto;
  top: 30px;
}
.event-style3 .event-title {
  font-size: 30px;
}
.event-style3 .event-content {
  padding: 34px 40px 40px;
}
.event-style3 .event-footer {
  gap: 30px;
}

.events-layout2 {
  background-color: var(--smoke-color);
  padding-top: 230px;
  margin-top: -110px;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .events-layout2 {
    padding-top: 190px;
    padding-bottom: 50px;
  }
}

.events-slide2 .slick-slide {
  transition: all 0.3s ease-in-out;
}
.events-slide2 .slick-slide.slick-current.slick-active.slick-center .event-title,
.events-slide2 .slick-slide.slick-current.slick-active .event-title {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.events-slide2 .slick-slide.slick-current.slick-active.slick-center .event-content,
.events-slide2 .slick-slide.slick-current.slick-active .event-content {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.events-slide2 .slick-slide.slick-current.slick-active.slick-center .event-img .event-title,
.events-slide2 .slick-slide.slick-current.slick-active .event-img .event-title {
  opacity: 0;
}
.events-slide2 .slick-slide.slick-current.slick-active.slick-center .event-date,
.events-slide2 .slick-slide.slick-current.slick-active .event-date {
  bottom: auto;
  top: 30px;
  z-index: 9;
  right: 30px;
}

.event-title-count {
  margin-bottom: 0;
  font-weight: 700;
}

.event-tab .nav-tabs {
  border-bottom: 0;
  gap: 20px;
}
.event-tab .nav-tabs .nav-link {
  border: 0;
  padding: 0;
  border-radius: 0;
  font-size: 30px;
  background-color: transparent;
  color: var(--body-color);
}
.event-tab .nav-tabs .nav-link.active {
  color: var(--theme-color);
}

.event-details-img {
  border-radius: 30px;
}

.event-details-img2 {
  border-radius: 20px;
}

.share-links-title {
  font-weight: 700;
  margin-right: 3px;
}

.social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-flex;
}
.social-links li {
  display: inline-block;
  margin-right: 13px;
}
.social-links li:last-child {
  margin-right: 0;
}
.social-links a {
  line-height: 1;
  font-size: 16px;
  color: var(--body-color);
  text-align: center;
  display: block;
}
.social-links a:hover {
  color: var(--theme-color);
}

.item-card {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--smoke-color);
}
.item-card .item-icon {
  min-width: 60px;
  height: 60px;
  background: var(--theme-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  font-size: 27px;
  border-radius: 10px;
}
.item-card .item-title {
  display: block;
  font-weight: 700;
}
.item-card .item-text {
  font-size: 20px;
  font-weight: 700;
  color: var(--title-color);
}

.event-timeline {
  padding: 0 113px;
  margin: 0 0 38px 0;
}
@media (max-width: 991px) {
  .event-timeline {
    padding: 0 0;
  }
}
.event-timeline__bar {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  margin-right: auto;
  margin-left: auto;
}
.event-timeline__bar .timeline-line {
  position: absolute;
  width: 106%;
  height: 4px;
  background-color: var(--sec-color);
  right: 0;
  left: 50%;
  transform: translateX(-50%);
}
.event-timeline__bar .timeline-line::before, .event-timeline__bar .timeline-line::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: var(--theme-color);
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 5px solid var(--smoke-color);
}
.event-timeline__bar .timeline-line::after {
  left: auto;
  right: 0;
}

.timeline-item {
  text-align: center;
  min-width: 138px;
  border: 2px solid var(--sec-color);
  border-radius: 10px;
  padding: 20px 0 12px 0;
  position: relative;
  margin: 39px 0 0 0;
  transition: all 0.3s ease-in-out;
}
.timeline-item::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #7409f6 0%, rgba(116, 9, 246, 0) 100%);
}
.timeline-item i {
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 12px;
  transition: all 0.3s ease-in-out;
}
.timeline-item span {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.timeline-item:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.timeline-item:hover i,
.timeline-item:hover span {
  color: var(--white-color);
}

.timeline-step {
  width: 138px;
  height: 40px;
  position: relative;
  text-align: center;
  margin-bottom: -20px;
  z-index: 1;
}
.timeline-step::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  background-color: var(--theme-color);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 10px solid var(--smoke-color);
}

.ebooking-wrap1 {
  padding: 70px 0 40px;
}

.ebooking-wrap2 {
  margin-bottom: 70px;
}

.order-summmary {
  padding: 30px;
  border-radius: 20px;
  background-color: var(--smoke-color);
}
.order-summmary table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 0;
  background-color: var(--white-color);
}
.order-summmary th,
.order-summmary td {
  border: 0;
  border-bottom: 1px solid var(--border-color);
  padding: 17px;
  text-align: center;
}
.order-summmary tr:first-child,
.order-summmary tr:first-child th {
  background-color: var(--sec-color);
  border-color: var(--sec-color);
  color: var(--white-color);
  text-align: center;
}
.order-summmary tr:last-child,
.order-summmary tr:last-child td {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
}

/*------------------- 4.25. CTA  -------------------*/
@media (min-width: 1500px) {
  .cta-wrap1 {
    margin-left: 110px;
    margin-right: 110px;
  }
}
.cta-wrap2 {
  background-color: var(--theme-color);
  padding: 80px;
}

.cta-title1 {
  margin-bottom: -1rem;
  color: var(--white-color);
}

.cta-style1 {
  background-color: var(--theme-color);
  padding: 94px 0 90px 0;
  text-align: center;
}
.cta-style1 .vs-btn,
.cta-style1 .cta-title,
.cta-style1 .cta-title2 {
  position: relative;
  z-index: 2;
}
.cta-style1 .cta-title {
  color: var(--white-color);
  font-size: 60px;
  line-height: 1;
  margin: 0 0 8px 0;
}
.cta-style1 .cta-title2 {
  color: var(--white-color);
}
.cta-style1.bg-center {
  background-position: center center;
  background-size: auto;
}

.cta-wrap3 {
  padding-bottom: var(--section-space);
}
@media (min-width: 1199px) {
  .cta-wrap3 {
    margin-bottom: -192px;
    padding-bottom: 0;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .cta-wrap2 {
    padding: 60px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .cta-wrap2 {
    padding: 45px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .cta-title1 {
    margin-bottom: -0.7rem;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .cta-title1 {
    margin: -0.5em 0 30px 0;
  }
  .cta-wrap3 {
    padding-bottom: var(--section-space-mobile);
  }
}
/* Small devices */
@media (max-width: 767px) {
  .cta-wrap2 {
    padding: 45px 20px;
  }
}
/*------------------- 4.26. Video  -------------------*/
.video-wrap1, .video-wrap2, .video-wrap3 {
  padding: 120px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -120px;
}
@media (max-width: 1199px) {
  .video-wrap1, .video-wrap2, .video-wrap3 {
    padding: 50px;
  }
}
@media (max-width: 991px) {
  .video-wrap1, .video-wrap2, .video-wrap3 {
    padding: 50px 20px;
    text-align: center;
  }
}
.video-wrap1 .play-btn, .video-wrap2 .play-btn, .video-wrap3 .play-btn {
  margin-bottom: 72px;
}
@media (max-width: 991px) {
  .video-wrap1 .play-btn, .video-wrap2 .play-btn, .video-wrap3 .play-btn {
    margin-bottom: 25px;
  }
}
.video-wrap1 .overlay, .video-wrap2 .overlay, .video-wrap3 .overlay {
  background: linear-gradient(90deg, #272e39 0%, rgba(39, 46, 57, 0.35) 100%);
  opacity: 0.6;
  z-index: -1;
}
.video-wrap2 {
  margin-top: 0;
  min-height: 740px;
  padding: 0;
  border-radius: 0;
  padding-top: 120px;
  overflow: visible;
  /* Medium devices */
}
@media (max-width: 991px) {
  .video-wrap2 {
    min-height: 640px;
    padding-top: 0;
  }
}
.video-wrap2 .overlay {
  background: linear-gradient(90deg, #272e39 0%, rgba(39, 46, 57, 0.35) 100%);
  opacity: 0.6;
}
.video-wrap2 .video-content {
  padding-top: 110px;
}
.video-wrap2 .play-btn.style4 {
  --icon-size: 80px;
  margin-bottom: 60px;
  color: var(--white-color);
  margin-left: 40px;
}
@media (max-width: 991px) {
  .video-wrap2 .play-btn.style4 {
    margin-left: 0;
  }
}
.video-wrap2 .play-btn.style4::before, .video-wrap2 .play-btn.style4::after {
  background-color: var(--white-color);
  color: var(--title-color);
  animation-name: ripple2;
}
.video-wrap2 .play-btn.style4::after {
  animation-duration: 3s;
}
.video-wrap2 .play-btn.style4::before {
  animation-duration: 8s;
}
.video-wrap2 .play-btn.style4 i {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.video-wrap2 .form-style1 {
  margin-bottom: -100px;
}
.video-wrap3 {
  padding: 190px 0 260px;
  border-radius: 0;
  margin-top: 0;
}
@media (max-width: 1199px) {
  .video-wrap3 {
    padding: 110px 0 200px;
  }
}

/*------------------- 4.27. Gallery  -------------------*/
.gallery-style1, .gallery-style2 {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 30px;
}
.gallery-style1 .popup-link, .gallery-style2 .popup-link {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  z-index: 1;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  font-size: 23px;
  color: var(--title-color);
}
.gallery-style1 .popup-link:hover, .gallery-style2 .popup-link:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.gallery-style1 .overlay::before, .gallery-style1 .overlay::after, .gallery-style2 .overlay::before, .gallery-style2 .overlay::after {
  position: absolute;
  content: "";
  left: 0;
}
.gallery-style1 .overlay::after, .gallery-style2 .overlay::after {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #272e39 0%, rgba(39, 46, 57, 0.35) 100%);
  opacity: 0.6;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
.gallery-style1 .overlay::before, .gallery-style2 .overlay::before {
  width: 100%;
  height: 0%;
  background: linear-gradient(0deg, #7409f6 0%, rgba(116, 9, 246, 0) 100%);
  opacity: 0.9;
  visibility: hidden;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
.gallery-style1 .gallery-content, .gallery-style2 .gallery-content {
  position: absolute;
  bottom: 0;
  margin: 30px;
}
@media (max-width: 1399px) {
  .gallery-style1 .gallery-content, .gallery-style2 .gallery-content {
    margin: 15px;
  }
}
.gallery-style1 .gallery-title, .gallery-style2 .gallery-title {
  margin-bottom: 0;
  overflow: hidden;
}
.gallery-style1 .gallery-title a, .gallery-style2 .gallery-title a {
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
  transform: translateY(0px);
  display: inline-block;
}
.gallery-style1 .gallery-date, .gallery-style2 .gallery-date {
  color: var(--white-color);
  margin-bottom: 6px;
  overflow: hidden;
}
.gallery-style1 .gallery-date span, .gallery-style2 .gallery-date span {
  display: inline-flex;
  align-items: center;
  transform: translateY(0px);
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .gallery-style1 .gallery-thumb img, .gallery-style2 .gallery-thumb img {
    width: 100%;
  }
}
.gallery-style1:hover a.popup-link, .gallery-style2:hover a.popup-link {
  top: 50%;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1399px) {
  .gallery-style1:hover a.popup-link, .gallery-style2:hover a.popup-link {
    top: 40%;
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}
.gallery-style1:hover .gallery-title a, .gallery-style2:hover .gallery-title a {
  transform: translateY(80px);
}
.gallery-style1:hover .gallery-date span, .gallery-style2:hover .gallery-date span {
  transform: translateY(22px);
}
.gallery-style1:hover .overlay::after, .gallery-style2:hover .overlay::after {
  opacity: 0;
  visibility: hidden;
}
.gallery-style1:hover .overlay::before, .gallery-style2:hover .overlay::before {
  opacity: 0.9;
  visibility: visible;
  height: 100%;
}
.gallery-style2 .gallery-content {
  position: absolute;
  bottom: 70px;
  margin: 0;
  left: 70px;
  max-width: 60%;
}
@media (max-width: 1399px) {
  .gallery-style2 .gallery-content {
    bottom: 30px;
    left: 35px;
    max-width: 75%;
  }
}
.gallery-style2 .overlay {
  transform: scale(1);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.gallery-style2 .overlay::after, .gallery-style2 .overlay::before {
  transition: all 0.3s ease-in-out;
}
.gallery-style2 .overlay::before {
  display: none;
}
.gallery-style2 .gallery-title a,
.gallery-style2 .gallery-date span {
  transform: translateY(80px);
}
@media (max-width: 1399px) {
  .gallery-style2 .gallery-title {
    font-size: 20px;
  }
}
.gallery-style2:hover .gallery-title a,
.gallery-style2:hover .gallery-date span {
  transform: translateY(0);
}
.gallery-style2:hover .overlay {
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}
.gallery-style2:hover .overlay::after {
  background: linear-gradient(0deg, #7409f6 100%, rgb(116, 9, 246) 100%);
  opacity: 0.8;
  visibility: visible;
}
.gallery-style2 .popup-link {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 30px;
}
.gallery-style2 .popup-link:hover {
  background-color: var(--title-color);
}
.gallery-style2 .gallery-thumb img {
  width: 100%;
}

.filter-gallery {
  display: flex;
  justify-content: center;
  margin-right: -20%;
}
@media (max-width: 1399px) {
  .filter-gallery {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .filter-gallery {
    margin-right: 0;
  }
}
.filter-gallery .gallery-thumb img {
  width: 100%;
}
.filter-gallery .filter-item {
  width: 337.5px;
  height: 380px;
  margin-bottom: 30px;
}
@media (max-width: 1399px) {
  .filter-gallery .filter-item {
    width: 280px;
    height: 316px;
  }
}
@media (max-width: 1199px) {
  .filter-gallery .filter-item {
    width: 315px;
    height: 366px;
  }
}
@media (max-width: 991px) {
  .filter-gallery .filter-item {
    width: 230px;
    height: 255px;
  }
}
@media (max-width: 767px) {
  .filter-gallery .filter-item {
    width: 100%;
    height: 630px;
  }
}
.filter-gallery .filter-item.grid-item--width2 {
  width: 675px;
  height: 380px;
}
@media (max-width: 1399px) {
  .filter-gallery .filter-item.grid-item--width2 {
    width: 560px;
    height: 316px;
  }
}
@media (max-width: 1199px) {
  .filter-gallery .filter-item.grid-item--width2 {
    width: 630px;
    height: 366px;
  }
}
@media (max-width: 991px) {
  .filter-gallery .filter-item.grid-item--width2 {
    width: 460px;
    height: 255px;
  }
}
@media (max-width: 767px) {
  .filter-gallery .filter-item.grid-item--width2 {
    width: 100%;
    height: 305px;
  }
}

@media (max-width: 425px) {
  .filter-gallery .filter-item {
    height: 479.92px;
  }
  .filter-gallery .filter-item.grid-item--width2 {
    height: 229.17px;
  }
}
@media (max-width: 375px) {
  .filter-gallery .filter-item {
    height: 418.23px;
  }
  .filter-gallery .filter-item.grid-item--width2 {
    height: 199.72px;
  }
}
@media (max-width: 320px) {
  .filter-gallery .filter-item {
    height: 350.58px;
  }
  .filter-gallery .filter-item.grid-item--width2 {
    height: 167.31px;
  }
}
/*------------------- 4.28. Newsletter  -------------------*/
.newsletter-layout1 {
  position: relative;
  z-index: 1;
}
.newsletter-layout1 .overlay {
  height: 50%;
  background-color: var(--sec-color);
  bottom: 0;
  top: auto;
}

/*=================================
    05. Spacing
==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}

.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}

/* Medium devices */
@media (max-width: 991px) {
  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }
  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }
  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }
  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
  .space-top-md-none {
    padding-top: 0;
  }
}
/*=================================
    06. Color Plate
==================================*/
/* Color Plate */
.vs-color-plate {
  position: fixed;
  display: block;
  z-index: 91;
  padding: 20px;
  width: 240px;
  background: #fff;
  left: 0;
  text-align: left;
  top: 30%;
  transition: all 0.4s ease;
  box-shadow: -3px 0px 10px -2px rgba(0, 0, 0, 0.1);
  direction: ltr;
  transform: translateX(-100%);
}
.vs-color-plate.open {
  transform: translateX(0);
}
.vs-color-plate .color-plate-icon {
  position: absolute;
  right: -48px;
  width: 48px;
  height: 45px;
  line-height: 45px;
  font-size: 21px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  color: var(--white-color);
  top: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.2117647059);
  background-color: var(--theme-color);
}
.vs-color-plate h4 {
  display: block;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}
.vs-color-plate p {
  font-size: 12px;
  margin-bottom: 15px;
  line-height: 20px;
}
.vs-color-plate .reset-btn {
  border: none;
  padding: 0;
  height: 20px;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--title-color);
  background-color: var(--smoke-color);
  width: 100%;
  height: 40px;
}
.vs-color-plate .reset-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.vs-color-plate input[type=color] {
  border: none;
  padding: 0;
}

.default-color-btn {
  border: none;
  background-color: #000;
  color: var(--white-color);
  font-weight: 400;
  font-family: var(--body-font);
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 0 0 10px 0;
  padding: 5px 0;
}