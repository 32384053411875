.price {
  &-block {
    $self: &;
    &--style1 {
      margin-bottom: 30px;
      #{$self}__header {
        border-radius: 20px;
        background-color: var(--theme-color);
        text-align: center;
        position: relative;
        min-height: 227px;
        padding-top: 40px;
        overflow: hidden;
        @include smooth-transition;
        z-index: 1;
        .overlay {
          background-color: var(--sec-color);
          opacity: 0.75;
          z-index: -1;
          @include smooth-transition;
        }
        .shape1,
        .shape2 {
          position: absolute;
          top: 109px;
          @include smooth-transition;
        }
        .shape1 {
          left: 0;
        }
        .shape2 {
          right: 0;
        }
      }
      #{$self}__title {
        font-size: 30px;
        margin-bottom: 0px;
        color: var(--white-color);
        @include smooth-transition;
      }
      #{$self}__price {
        font-size: 48px;
        line-height: 58px;
        font-weight: 700;
        color: var(--white-color);
        font-family: var(--title-font);
        @include smooth-transition;
        & > sup {
          font-size: 20px;
          top: -22px;
          padding: 0 2.5px;
        }
      }
      #{$self}__body {
        border-radius: 20px;
        background-color: var(--white-color);
        margin: 0 40px;
        padding: 40px 35px;
        margin-top: -52px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        z-index: 1;
        position: relative;
        text-align: center;
        @include respond-below(lg) {
          margin: 0 15px;
          padding: 15px;
        }
      }
      #{$self}__time {
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        min-height: 40px;
        border: 1px solid var(--border-color);
        padding: 0 20px;
        font-weight: 500;
        line-height: 26px;
        @include smooth-transition;
        & > i {
          color: var(--theme-color);
          @include smooth-transition;
        }
      }
      #{$self}__list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding: 25px 0 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        & > li {
          display: inline-flex;
          align-items: center;
          font-size: 15px;
          gap: 10px;
          text-align: left;
          font-weight: 500;
          color: var(--body-color);
          & > i {
            color: var(--body-color);
          }
          &.active {
            color: var(--title-color);
            & > i {
              color: var(--theme-color);
            }
          }
        }
      }
      .vs-btn {
        clip-path: none;
        width: 100%;
        justify-content: center;
        &::before,
        &::after {
          background-color: var(--sec-color);
        }
      }
      &:hover {
        #{$self}__header {
          background-color: var(--sec-color);
          @include smooth-transition;
          .overlay {
            background-color: var(--theme-color);
            opacity: 0.85;
            @include smooth-transition;
          }
        }
        #{$self}__time {
          background-color: var(--sec-color);
          border-color: var(--sec-color);
          color: var(--white-color);
          & > i {
            color: var(--white-color);
          }
        }
      }
    }
  }
}
