.sec-title,
.sec-title2 {
  line-height: 1.2;
  margin: -0.25em 0 20px 0;
}
.sec-title2 {
  color: var(--white-color);
}

.sec-subtitle,
.sec-subtitle2,
.sec-subtitle3,
.sec-subtitle4 {
  text-transform: capitalize;
  display: block;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin: -0.25em 0 20px 0;

  &::before {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 18px 18px 0 0;
    border-color: var(--title-color) transparent transparent transparent;
    transform: rotate(0deg);
    display: inline-block;
    margin-right: 8px;
  }

  > i {
    margin-right: 15px;
  }
}

.sec-subtitle2 {
  &::after {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 18px 18px 0;
    border-color: transparent var(--title-color) transparent transparent;
    transform: rotate(0deg);
    display: inline-block;
    margin-left: 8px;
  }
}

.sec-subtitle3 {
  color: var(--title-color);
}

.sec-subtitle4 {
  color: var(--white-color);
  @include respond-below(md) {
    font-size: 18px;
  }
  &::before {
    border-color: var(--white-color) transparent transparent transparent;
  }
  &.style2 {
    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 18px 18px 0;
      border-color: transparent var(--white-color) transparent transparent;
      transform: rotate(0deg);
      display: inline-block;
      margin-left: 8px;
    }
  }
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 40px);
}

.sec-btns {
  padding: 13px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: calc(var(--section-title-space) - 40px);

  i.fa-arrow-left {
    margin-left: 0;
    margin-right: 10px;
  }
  &.style1 {
    .vs-btn {
      i {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
  &.style2 {
    .vs-btn {
      background-color: var(--sec-color);
      &::before,
      &::after {
        background-color: var(--theme-color);
      }
      i {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.sec-btns2 {
  margin-bottom: calc(var(--section-title-space) - 5px);
}

.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;

  .sec-title2 {
    margin: -0.3em 0 -0.4em 0;
  }
}

.sec-line {
  background-color: $theme-color;
  flex: 1;
  height: 2px;
}

.border-title {
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 0;

  &-border {
    width: 48px;
    height: 2px;
    display: inline-block;
    background-color: $theme-color;
    margin-bottom: 25px;
  }
}

@include ml {
  .sec-title4 {
    font-size: 42px;
  }
}

@include lg {
  .sec-title4 {
    font-size: 36px;
    line-height: 1.4;
  }
}

@include md {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }

  .sec-line-wrap {
    margin: 0 0 60px 0;
  }

  .sec-subtitle2 {
    margin-bottom: 15px;
  }

  .title-area {
    margin-bottom: 40px;
  }
}

@include sm {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }

  .sec-subtitle {
    font-size: 16px;
  }

  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }

  .sec-title4 {
    font-size: 30px;
    line-height: 1.4;
  }
}
