.form-style1,
.form-style2,
.form-style3 {
  background-color: var(--theme-color);
  --bs-gutter-x: 20px;
  padding: 60px 40px;
  text-align: center;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  @include respond-below(sm) {
    padding: 30px 15px;
  }

  .title {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 11px;
    color: var(--white-color);
    @include respond-below(sm) {
      font-size: 40px;
    }
  }
  .subtitle {
    font-size: 20px;
    font-weight: 700;
    color: var(--white-color);
    margin-bottom: 33px;
    display: block;
  }
  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-title {
    color: $white-color;
    text-align: center;
    margin: 0 0 27px 0;
    padding: 0 0 17px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // margin: 0 0 0 -50px;
      height: 1px;
      border-radius: 5px;
      background-color: #0844bc;
      background-color: $white-color;
      opacity: 0.3;
    }
  }

  .vs-btn {
    margin-top: 25px;
  }

  textarea,
  input,
  .form-control {
    height: 60px;
    background-color: var(--smoke-color);
    font-size: 16px;
    font-family: var(--body-font);
    color: var(--body-color);
    border-radius: 30px;
    border: 0;
    &::placeholder {
      color: var(--body-color);
    }
  }

  textarea {
    min-height: 180px;
    resize: none;
  }

  .shape-1,
  .shape-2 {
    position: absolute;
    z-index: -1;
    opacity: 0.6;
    mix-blend-mode: soft-light;
  }
  .shape-1 {
    top: 0;
    left: 0;
  }
  .shape-2 {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
}

.form-style2 {
  padding: 60px 60px 40px 60px;
  margin-top: -130px;
  background-color: var(--black-color);
  .shape-1,
  .shape-2 {
    mix-blend-mode: normal;
    opacity: 0.4;
  }
  .vs-btn {
    background-color: var(--black-color);
    &::after,
    &::before {
      background-color: var(--theme-color);
    }
  }
  @include respond-below(sm) {
    padding: 40px 20px 40px 20px;
  }
}

.form-style3 {
  padding: 40px 40px 30px 40px;
  margin-top: -70px;
  margin-bottom: 0;
  .form-control {
    max-width: 169px;
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 20px;
    }
  }
}

.form-style4 {
  .form-control {
    border-radius: 30px;
    background-color: var(--smoke-color);
    border: 1px solid transparent;
    height: 60px;
    &:focus {
      border-color: var(--theme-color);
    }
  }
}

.newsletter-form1,
.newsletter-form2 {
  background-color: var(--theme-color);
  padding: 70px 60px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include respond-below(lg) {
    padding: 30px 20px;
  }
  @include respond-below(md) {
    padding: 20px;
  }
  .form-group > i {
    right: calc(var(--bs-gutter-x) / 2 + 24px);
    top: 25px;
    font-size: 14px;
    color: var(--title-color);
  }
  .form-control {
    height: 60px;
    border: 0px solid var(--sec-color);
    border-radius: 999px;
    padding: 0 30px 0 30px;
    min-width: 450px;
    @include respond-below(md) {
      margin-bottom: 20px;
      min-width: 100%;
    }
  }
  .shape {
    z-index: -1;
  }
}

.newsletter-form2 {
  border-radius: 0;
  padding: 40px 0px;
  .overlay {
    background-color: var(--title-color);
    opacity: 0.2;
    z-index: -1;
  }
  .sec-text {
    margin-bottom: 0;
  }
  .form-control {
    margin-bottom: 0;
    border: 0;
    height: 70px;
    min-width: 646px;
    @include respond-below(lg) {
      min-width: 500px;
    }
    @include respond-below(md) {
      min-width: 100%;
    }
  }
  .vs-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 22px 30px;
    @include respond-below(sm) {
      position: relative;
      top: 0;
      right: 0;
      background-color: var(--sec-color);
      color: var(--white-color);
      padding: 22px 30px;
    }
  }
}

.form-style5 {
  .form-group {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 30px;
    }
  }
  .form-control {
    border-radius: 10px;
    border: none;
    height: 60px;
    background: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(15px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: var(--white-color);
    &::placeholder {
      color: var(--white-color);
    }
  }
}
